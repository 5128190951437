import React, {  useState } from 'react'
import { Card, Col, Container, Form, Row } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux';
import { setVehicleDetails } from '../../Features/vehicleDetailsSlice'; 
import FormTitle from '../../Template/components/LoginComponents/FormTitle'
import FormField from '../../Template/components/LoginComponents/FormField'
import ContinueBtn from '../../Template/components/LoginComponents/ContinueBtn'
import { useNavigate } from 'react-router-dom'
import RegProgress from '../../Template/components/LoginComponents/RegProgress';
import ScrollToTop from '../../Template/components/ScrollToTop';
import { hideLoading, showLoading } from '../../Features/alertSlice';
import toast from 'react-hot-toast';
import instance from '../../Utils/axios';






function VehicleDetails() {



    const dispatch = useDispatch();
    const navigate = useNavigate();



    const {vehicleDetails} = useSelector((state) => state.vehicleDetails);
    const [vehicleData, setVehicleData] = useState(vehicleDetails)
    const userToken = useSelector((state)=>state.auth.userToken);
    // {
    //     ...vehicleDetails,
    //     regNumber: vehicleDetails?.regNumber || '',
    //     milage: vehicleDetails?.milage || ''


    // });

    console.log({ vehicleDetails, vehicleData })
    const handleInput = (e) => {

        setVehicleData({ ...vehicleData, [e.target.name]: e.target.value.toUpperCase() })

    }

    



    const handleContinue = async(event) => {
        event.preventDefault();
        dispatch(setVehicleDetails(vehicleData))
            try {
                dispatch(showLoading());
                const response = await instance.post('/api/warranty/check',
                   { 
                    reg_num: vehicleData?.regNumber,
                   }
                )
                dispatch(hideLoading());
                console.log(response);

                if (response?.status === 200) {
                    if(userToken){
                        navigate('/purchase/details');
                        
                    }else{
        
                        navigate('/auth/login-method');
                    }
                }           

            } catch (error) {
                dispatch(hideLoading());
                console.log(error);
                toast.error(error?.response?.data?.error?.message || error?.message);
            }
        

            

       
    };


    return (
        <>

            <section>
                <ScrollToTop/>
                <Container className='p-3' >
                    <RegProgress step='step1' />
                    <Row>
                        <Col md={7}>
                            <Card className='border rounded-5 px-4 py-2' data-aos="flip-right" data-aos-delay='300' data-aos-duration='1000'>





                                <Form className='px-md-5 fw-bold' style={{ color: '#004C9A' }} onSubmit={handleContinue}>

                                    <FormTitle
                                        title='Vehicle details'
                                        description="We provide the UK's most comprehensive warranty for all vehicle makes and models. By filling out your vehicle details we can ensure you receive the best warranty to suit you."

                                    />
                                    
                                    <FormField
                                        label='Registration'
                                        type='text'
                                        value={vehicleData?.regNumber}
                                        name='regNumber'
                                        onChange={handleInput}
                                        upperCase={true}
                                        info='Registration No.'
                                        note='Make/Model'
                                        ctaBtnTxt="I Don't Know My Registration"
                                        ctaBtnLink='/purchase/find-registration'

                                    />

                                    <FormField
                                        label='Current Mileage'
                                        info='Current mileage'
                                        value={vehicleData?.milage}
                                        name='milage'
                                        onChange={handleInput}
                                        note='Approximate'
                                        type='number'
                                    />
                                    <ContinueBtn />
                                </Form>



                            </Card>

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default VehicleDetails