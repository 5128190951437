import React from 'react'
import ScrollToTop from '../Template/components/ScrollToTop'
import HowItWorksSec from '../Template/sections/HowItWorksSec'
import PlanPackage from '../Template/components/PlanPackage'
import HomeTitle from '../Template/sections/HomeTitle'
import HomePackages from '../Template/sections/HomePackages'
import HomeWhySection from '../Template/sections/HomeWhySection'
import HomeCustomerSec from '../Template/sections/HomeCustomerSec'
import HomeFeatures from '../Template/sections/HomeFeatures'

function HomeNew() {
  return (
<>
<ScrollToTop/>
<HomeTitle/>
<HomeFeatures/>
<HowItWorksSec/>
<HomeWhySection/>
<HomeCustomerSec/>
<HomePackages/>
</>
  )
}

export default HomeNew