// ProtectedRoute.js
import React from 'react';
import { useRoutes, Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({children}) => {
  const userToken = useSelector((state) => state.auth.userToken);

  return userToken ? (
    <>{children}</>
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;
