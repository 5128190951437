import React from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import VanSupportImg from '../images/VanSupport.png';

function VanSupport() {
  return (
    <section>
    <Container className='py-4'>
        <Row className='px-5'>
            <Col md={5} className='d-flex align-items-center'>
            <div className='px-md-5'>
            <h1 className='display-5 fw-bold my-3' style={{color:'#004C9A'}}>Looking to get a van warranty?</h1>
            <h4 className='fw-normal'>Protect your van and your income. Eliminate unexpected repair costs and keep your van on the road with 24/7 breakdown cover included as standard.</h4>
            <Button  className='rounded-pill border-0 px-4 my-3' style={{backgroundColor:'#008BFF'}}>Start a quote</Button>
            </div>
            </Col>
            <Col>
            
            <Image  fluid className='rounded p-3' src={VanSupportImg} />
            
            </Col>
        </Row>
    </Container>
    </section>
  )
}

export default VanSupport