import React from 'react'
import CTAButton from './CTAButton';
import { Col, Form, Row } from 'react-bootstrap';
import FormOverlay from './FormOverlay';

function FormField({label, id, info,note,type, name,ctaBtnTxt,ctaBtnLink,onChange,value,upperCase=false}) {

    let ctaBtn = null;

    if(ctaBtnTxt){
        ctaBtn=<CTAButton btnText={ctaBtnTxt} btnLink={ctaBtnLink} />
    }

    return (
        <>
            <Form.Group as={Row} className="mb-3" controlId={id}>
                <Form.Label column sm="" >
                    {label}
                    <FormOverlay info={info} />
                    
                    <p style={{ fontSize: '0.7rem', fontWeight: 'normal' }}>{note}</p>
                </Form.Label>
                <Col sm="8" >
                    <Form.Control name={name}  required={true} size='lg' defaultValue={value} onChange={onChange} className='rounded-pill fw-bold fs-6' type={type}  style={upperCase?{textTransform:"uppercase"}:null} /> 
                    {ctaBtn}                 
                </Col>
            </Form.Group>
        </>
    )
}

export default FormField