import React from 'react'
import { Container } from 'react-bootstrap'



function DashboardImgBg({title,bgImg ,bgColor}) {

  let containerStyle = null;
  if(bgImg){
     containerStyle = {
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${bgImg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        
        
        
    }
  }

  if(bgColor){
      containerStyle = { backgroundColor:`${bgColor}`}
  }

  return (
    <>
    <section style={containerStyle}>
        <Container className='py-5'>
            <h1 className='text-white'>{title}</h1>
        </Container>
        <div className='p-3' style={{backgroundColor:'#004C9A'}}> 

        </div>
    </section>
    </>
  )
}

export default DashboardImgBg