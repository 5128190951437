// src/features/detailsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const userDetails = localStorage.getItem('userDetails')? JSON.parse(localStorage.getItem('userDetails')): ''

//     firstName: '',
//     lastName: '',
//     email: '',
//     phoneNumber:'',
//     postCode:'',
//     addressLine1:'',
//     addressLine2:'',
//     city:'',
//     country:'',


const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState:{ userDetails },
  reducers: {
    setUserDetails: (state, action ) =>{
      console.log({state, action})
      state.userDetails = action.payload;
      localStorage.setItem('userDetails',JSON.stringify(action.payload))
    },
    unSetUserDetails: (state, action)=>{
      state.userDetails = null;
      localStorage.removeItem('userDetails')
    }
  },
});

export const { setUserDetails,unSetUserDetails } = userDetailsSlice.actions;
export default userDetailsSlice.reducer;
