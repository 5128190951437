import React from 'react'
import { Container, Row } from 'react-bootstrap'
import PlanPackage from '../components/PlanPackage'
import Title from '../components/Title'

function PlanLevelsSec() {

  const containerStyle={
    backgroundColor:'#D1D5DB4C',
    borderTopLeftRadius:'80px',
    borderTopRightRadius:'80px',
}

  return (
    <section style={containerStyle} className='mt-5'>
        <Container>
            <Row>
                    <Title
                        title='Your cover level is personal'
                        description='Once your ideal plan has been selected based on the age of your vehicle, make and mileage, you can choose additional elements to personalise your cover to suit your needs and budget.'
                        descColor='#004C9A'
                    />
            </Row>
            <Row>
                <PlanPackage/>
            </Row>
        </Container>
    </section>
  )
}

export default PlanLevelsSec