import React, { useState } from 'react'
import RegTemplate from './RegTemplate'
import LoginTitle from '../../Template/components/LoginComponents/LoginTitle'
import { Button, Form, Row } from 'react-bootstrap'
import LoginFormField from '../../Template/components/LoginComponents/LoginFormField'
import { useDispatch, useSelector } from 'react-redux'
import ScrollToTop from '../../Template/components/ScrollToTop'
import { hideLoading, showLoading } from '../../Features/alertSlice'
import instance from '../../Utils/axios'
import { BsChevronCompactLeft } from 'react-icons/bs'
import { toast } from 'react-hot-toast'
import { setUserToken } from '../../Features/authSlice'
import { setUserData } from '../../Features/userDataSlice'
import { useNavigate } from 'react-router-dom'

function DetailMissing() {

    const {userDetails} = useSelector((state)=>state.userDetails);

    const [firstName, setFirstName] = useState(userDetails?.firstName);
    const [lastName, setLastName] = useState(userDetails?.lastName);
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [postcode, setPostcode] = useState('');
    const [city, setCity] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleRegistration = async(event)=>{
        event.preventDefault();
        try {
            dispatch(showLoading());

            const response = await instance.post('/api/user/register',{

                addr:{
                    address: address,
                    city:city,
                    postcode:postcode,
                   },
                    mobile_no:phone,
                    email:userDetails?.email,
                    firstname:firstName,
                    lastname:lastName,
                 
                    googleRegistration: true

            })

            dispatch(hideLoading());

            if (response?.status === 200) {
                const token = response?.data?.token;
                const user = response?.data?.user;
      
                dispatch(setUserToken(token));
                dispatch(setUserData(user));
                toast.success(response?.data?.message, { duration: 5000 });
                navigate("/purchase/details");
              } 

            console.log(response);
            
        } catch (error) {
            dispatch(hideLoading());
            console.log(error)
            toast.error(error?.response?.data?.error?.message || error?.message);

        }

    }

    return (
        <RegTemplate>
            <ScrollToTop/>
            <LoginTitle title='Enter Your Email Id' />
           
            <Form onSubmit={handleRegistration}>
            <Row>
            <LoginFormField
                    label='Email'
                    placeholder='Email'
                    type='email'
                    value={userDetails?.email}
                    disabled={true}
                />

            <h4 className='mt-4' style={{color:'#004C9A'}}>Oops! Your Details Are Missing</h4>
            <p className='text-muted'>Provide Us With Your Details To Serve You Better</p>
           
                <LoginFormField
                    label='First Name'
                    placeholder='Enter your first name'
                    type='text'
                    value={firstName}
                    onChange={(e)=>setFirstName(e.target.value)}
                />
                <LoginFormField
                    label='Last Name'
                    placeholder='Enter your last name'
                    type='text'
                    value={lastName}
                    onChange={(e)=>setLastName(e.target.value)}
                />
                
                <LoginFormField
                    label='Phone Number'
                    placeholder='Enter your number'
                    type='number'
                    value={phone}
                    onChange={(e)=>setPhone(e.target.value)}
                />
                <LoginFormField
                    label='Address'
                    placeholder='Enter your address'
                    type='text'
                    value={address}
                    onChange={(e)=>setAddress(e.target.value)}
                />
                <LoginFormField
                    label='Postcode'
                    placeholder='Postcode'
                    type='text'
                    value={postcode}
                    onChange={(e)=>setPostcode(e.target.value)}
                />
                <LoginFormField
                    label='City'
                    placeholder='City'
                    type='text'
                    value={city}
                    onChange={(e)=>setCity(e.target.value)}
                />
               

                <Button type='submit' className='text-white w-100 rounded-pill'>Continue</Button>




            
            </Row>
            </Form>
        </RegTemplate>
    )
}

export default DetailMissing