import React from 'react'
import { Row } from 'react-bootstrap'

function LoginTitle({title,text}) {
  return (
    <Row style={{width:'100%'}}>
       <h1 style={{color:'#004C9A'}}>{title}</h1>
       <p className='text-muted' style={{fontSize:'0.9rem'}}>{text}</p>
    </Row>
  )
}

export default LoginTitle