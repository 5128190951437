import React from 'react';
import { Modal, Button, Card, Row, Col } from 'react-bootstrap';
import {format} from 'date-fns'

function PurchaseDetailsModal({title, purchase, onClose }) {
  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton className='border-0'>
        <Modal.Title >{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className=''>

         <Card className='p-3'>
                <Row>
                    <Col md={6}><h6>DATE</h6><p>{format(new Date(purchase?.createdAt), 'dd/MM/yy')}</p></Col>    
                    <Col md={6}><h6>WARRANTY PLAN</h6><p>{purchase?.plan?.level?.level}</p></Col>    
                </Row>   
                <Row>
                    <Col md={6}><h6>CAR MAKE</h6><p>{purchase?.vehicleDetails?.make}</p></Col>    
                    <Col md={6}><h6>CAR MODEL</h6><p>{purchase?.vehicleDetails?.model}</p></Col>    
                </Row>
                <hr/>   
                <Row>
                    <Col md={6}><h6>CUSTOMER NAME</h6><p>{purchase?.user?.firstname} {purchase?.user?.lastname}</p></Col>    
                    <Col md={6}><h6>EMAIL</h6><p>{purchase?.user?.email}</p></Col>    
                </Row>   
                <Row>
                    <Col md={6}><h6>PHONE</h6><p>{purchase?.user?.mobile_no}</p></Col>    
                    <Col md={6}><h6>STATUS</h6><p>{purchase?.status?.value}</p></Col>    
                </Row>   
                
         </Card>   

    {/*     <Card className='p-3 my-3'>
              <Row>
                    <Col md={6}><h6>PAYMENT METHOD</h6><p></p></Col>    
                    <Col md={6}><p>TOTAL AMOUNT <span className='fs-4' style={{color:'#FF6600'}}>£{purchase.price}</span></p></Col>    
                </Row> 
         </Card>

  */}    

<Row className='my-3'>

    <Col md={6}>
    <Button variant="transparent" className='text-muted border rounded-pill px-5 py-2 w-100' onClick={onClose}>
          Back To List
        </Button>
    </Col>
    <Col  >
    <Button href={purchase?.document?.url} disabled={purchase?.document?.url?false:true} variant="primary" className='text-white border rounded-pill px-5 py-2 w-100' >
           View Certificate
        </Button>
    </Col>


</Row>

         
             
      </Modal.Body>
      

        
      
    </Modal>
  );
}

export default PurchaseDetailsModal;
