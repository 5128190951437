import React, { useState } from 'react'
import { Col, Container, Nav, NavDropdown, Row, Tab } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import NavbarContactUs from './NavbarContactUs'

function NavdropOurWarranties() {

  const [showDropdown,setShowDropdown]= useState(false);

  const handleSelect =()=>{
    setShowDropdown(false);
  };

  const handleDropdownToggle = ()=>{
    setShowDropdown(!showDropdown)
  };


  return (
    <NavDropdown show={showDropdown} onSelect={handleSelect} onToggle={handleDropdownToggle} className='px-2  main-dropdown' title="Our Warranties " drop='drop-centered'  >

              <Container style={{ overflowY: 'auto', height: '50vh', WebkitOverflowScrolling: 'touch' }}>
                <Row className='my-md-5 '>
                  <Col md={5} className='p-md-4 p-2'>

                   
                    
                    <Tab.Container  className=' ' id="useful-link-menu" defaultActiveKey="first">
                      
                      <Row className='mt-1'>
                        <Col  className=''>
                        <h5 style={{ color: '#FFA500' }} className='text-nowrap'>Our Warranties</h5>
                          <Nav id='' variant="pills" className="flex-column">
                            <Nav.Item>
                              <Nav.Link eventKey="first">Car warranty</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="second" >Van warranty</Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>
                        <Col className=' useful-link-title'>
                        <h5 style={{ color: '#FFA500' }}>Useful Links</h5>
                          <Tab.Content>
                            <Tab.Pane eventKey="first" className='my-2'><Link onClick={handleSelect} style={{textDecoration:'none'}} to='/car-warranty'>Car warranty </Link></Tab.Pane>
                            <Tab.Pane eventKey="first" ><Link onClick={handleSelect} style={{textDecoration:'none'}} to='/car-extended-warranty'>Car warranty explained</Link></Tab.Pane>
                            <Tab.Pane eventKey="first" className='my-2'><Link onClick={handleSelect} style={{textDecoration:'none'}} to='/car-cover-levels'>Car warranty cover levels</Link></Tab.Pane>
                            <Tab.Pane eventKey="first" ><Link onClick={handleSelect} style={{textDecoration:'none'}} to='/faq'>Warranty FAQ's</Link></Tab.Pane>
                            <Tab.Pane eventKey="second" className='my-2'><Link onClick={handleSelect} style={{textDecoration:'none'}} to='/van-warranty'>Van warranty </Link></Tab.Pane>
                            <Tab.Pane eventKey="second" ><Link onClick={handleSelect} style={{textDecoration:'none'}} to='/van-extended-warranty'>Van warranty explained</Link></Tab.Pane>
                            <Tab.Pane eventKey="second" className='my-2'><Link onClick={handleSelect} style={{textDecoration:'none'}} to='/van-cover-levels'>Van warranty cover levels</Link></Tab.Pane>
                            <Tab.Pane eventKey="second" ><Link onClick={handleSelect} style={{textDecoration:'none'}} to='/faq'>Warranty FAQ's</Link></Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>

                   {/* { <br />
                    <br />
                                       
                    <div >
                    <hr />
                      <h5 className='my-3 mt-5' style={{ color: '#FFA500' }}>Not Bought Your Car yet</h5>
                      <p>Book a vehicle inspection</p>
                    </div>} */}
                  </Col>
                  <Col className="p-md-4 p-2 border-start">
                    <NavbarContactUs />
                  </Col>
                </Row>

              </Container>

            </NavDropdown>
  )
}

export default NavdropOurWarranties