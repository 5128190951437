import React from 'react'
import CookiePolicyTitle from '../Template/sections/CookiePolicyTitle'
import CookiePolicySec from '../Template/sections/CookiePolicySec'
import ScrollToTop from '../Template/components/ScrollToTop'

function CokkiePolicy() {
  return (
    <>
    <ScrollToTop/>
    <CookiePolicyTitle/>
    <CookiePolicySec/>
    
    </>
  )
}

export default CokkiePolicy