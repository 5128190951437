import React from 'react'
import { Container } from 'react-bootstrap'
import BlueQA from '../components/BlueQA'
import Title from '../components/Title'

function FailedPaymentSec() {
    return (
        <section>
            <Container className='px-md-5 py-5'>

                <Title
                    title='What You Need to Know'
                    description="At Super Warranty, we understand that life can sometimes throw unexpected challenges our way, including payment issues. If you've encountered a failed payment for your warranty plan, here's what you should know and what steps you can take to resolve the situation."
                    textAlign='text-left'

/>
               
<div className='ps-4'>
                <BlueQA
                    title='1. Communication is Key'
                />
                <p>If you've received a notification about a failed payment, don't worry. It's essential to communicate with us to understand the issue. Reach out to our customer support team immediately to discuss the situation. They can provide insights into the reason for the failure and guide you through the next steps.</p>

                <BlueQA
                    title='2. Common Reasons for Failed Payments'

                />
                <p>Failed payments can occur for various reasons, such as:</p>
                <p>Insufficient funds in your account</p>
                <p>Expired or outdated payment information</p>
                <p>Technical issues during the payment process</p>

                <BlueQA

                    title='3. Verify Payment Information'
                />
                <p>If the failed payment is due to outdated or incorrect payment information, make sure to update your details as soon as possible. This will ensure that future payments are processed smoothly. You can usually update your payment information through your online account or by contacting our customer support team.</p>

                <BlueQA
                    title='4. Address Insufficient Funds'

                />
                <p>If the failed payment is a result of insufficient funds, we recommend resolving this with your financial institution. Once the issue is resolved, you can update your payment information and proceed with the payment process.</p>

                <BlueQA
                    title='5. Rescheduling Payments'

                />
                <p>In some cases, we may be able to assist you in rescheduling a payment. Reach out to our customer support team to discuss the possibility of adjusting the payment schedule to better align with your financial situation.</p>


                <BlueQA
                    title='6. Avoid Lapsed Coverage'

                />

                <p>A failed payment can potentially result in a lapse of coverage, leaving your vehicle unprotected. It's crucial to address the issue promptly to avoid any gaps in your warranty coverage. Our customer support team can guide you on the best course of action to ensure continuous protection.</p>

                <BlueQA
                    title='7. Prevention is Key'

                />
                <p>To prevent future failed payments, consider setting up automatic payments if that option is available. This can help ensure that your payments are processed on time and that your coverage remains uninterrupted.</p>

</div>
            </Container>
        </section>
    )
}

export default FailedPaymentSec