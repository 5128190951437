import React from 'react'
import { Col, Row } from 'react-bootstrap'

function FormTitle({ title, description, alertMsg }) {
    return (
        <Row>
            <Col>

                <h3 className=' fw-bold my-3' style={{ color: '#004C9A' }}>{title}</h3>
                <p style={{ color: 'grey',fontWeight:'normal' }}>{description}</p>
                <p style={{ color: 'red', fontSize: '0.8rem' }}>{alertMsg}</p>


            </Col>

        </Row>
    )
}

export default FormTitle