import React, { useEffect, useState } from "react";
import RegTemplate from "./RegTemplate";
import LoginTitle from "../../Template/components/LoginComponents/LoginTitle";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import LoginFormField from "../../Template/components/LoginComponents/LoginFormField";
import { hideLoading, showLoading } from "../../Features/alertSlice";
import { setUserToken } from "../../Features/authSlice";
import { setUserData } from "../../Features/userDataSlice";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import instance from "../../Utils/axios";
import { toast } from "react-hot-toast";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { setUserDetails } from "../../Features/userDetailsSlice";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setuser] = useState([]);

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      dispatch(showLoading());
      const response = await instance.post("/api/user/login", {
        email: email,
        password: password,
      });
      dispatch(hideLoading());
      const token = response?.data?.token;
      const user = response?.data?.user;
      dispatch(setUserToken(token));
      dispatch(setUserData(user));

      console.log(response.status);

      if (response.status === 200) {
        if (location.pathname === "/login") {
          navigate("/dashboard/user-dashboard");
        } else if (
          location.pathname === "/auth/login-as-guest" ||
          location.pathname === "/auth/login"
        ) {
          navigate("/purchase/details");
        }
      }

      console.log(response);
      console.log(token);
    } catch (error) {
      dispatch(hideLoading());
      toast.error(error?.response?.data?.error?.message);
      console.error("login error:", error);
    }
  };

  

  const handleGoogleLoginFailure = (error) => {
    console.log("Login Failed:", error);
    toast.error(error?.response?.data?.error?.message);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => handleGoogleLogin(codeResponse),
    onError: (error) => handleGoogleLoginFailure(error),
  });

  const handleGoogleLogin = async (user) => {
    try {
      // Fetch Google user information
      const googleUserInfoResponse = await axios.get(
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${user?.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
            Accept: `application/json`,
          },
        }
      );

      if (googleUserInfoResponse.status === 200) {
        const { given_name, family_name, email } = googleUserInfoResponse.data;

        dispatch(
          setUserDetails({
            firstName: given_name,
            lastName: family_name,
            email: email,
          })
        );

        try {
          dispatch(showLoading())
          const loginResponse = await instance.post("/api/user/login", {
            email: email,
            googleLogin: true,
          });
          dispatch(hideLoading());
          console.log(loginResponse);
          if (loginResponse?.status === 200) {
            const token = loginResponse?.data?.token;
            const user = loginResponse?.data?.user;

            dispatch(setUserToken(token));
            dispatch(setUserData(user));

            navigate("/dashboard/user-dashboard");
          } 



        } catch (loginError) {
          dispatch(hideLoading())
          console.error("Error during login:", loginError);
          toast.error(
            loginError?.response?.data?.error?.message ||
              "An error occurred during login."
          );
        }
      } 
    } catch (error) {
      console.error("Error during Google login:", error);
      toast.error(
        error?.response?.data?.error || "An error occurred during Google login."
      );
    }
  };

  const title =
    location.pathname === "/auth/login-as-guest" ? "Login as Guest" : "Log In ";

  return (
    <RegTemplate>
      <div style={{ width: "350px" }} className="px-5 px-md-0" data-aos='zoom-in' data-aos-delay='300' data-aos-duration='1000'>
        <LoginTitle title={title} text="We are very happy to see you back!" />

        <Row className="">
          <Form  onSubmit={handleLogin}>
            <LoginFormField
              label="Email"
              placeholder="email@example.com"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />

            <LoginFormField
              label="Password"
              placeholder="**********"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />

            <p
              
              className=" text-end"
            >
            <Link style={{textDecoration:'none'}} className="text-muted" to='/auth/forgot-password-mail'> Forgot Password? </Link>
            </p>
            <Row>
              <Button
                type="submit"
                className="my-3 rounded-pill text-white w-100"
              >
                {location.pathname.includes("/login-as-guest")
                  ? "Login as Guest"
                  : "Login"}
              </Button>
            </Row>

            {location.pathname === "/login" ||
            location.pathname === "/auth/login" ? (
              <>
                <Row className="d-flex justify-content-center align-items-center">
                  <Col>
                    <hr />
                  </Col>
                  <Col md={2} className="text-center">OR</Col>
                  <Col>
                    <hr />
                  </Col>
                </Row>
                 
                <Row>
                  <Button
                    onClick={googleLogin}
                    variant="transparent"
                    type=""
                    className=" rounded-pill border my-2"
                  >
                    <Image src="/images/google.png"  /> Login with Google
                  </Button>
                </Row>
              </>
            ) : null}
          </Form>
        </Row>
      </div>
    </RegTemplate>
  );
}

export default Login;
