import React from 'react'

import WarrantyTipsTitle from '../Template/sections/WarrantyTipsTitle'


import TipsSec from '../Template/sections/TipsSec'
import ScrollToTop from '../Template/components/ScrollToTop'

function WarrantyTips() {
  return (
    <>
    <ScrollToTop/>
    <WarrantyTipsTitle/>
    <TipsSec />
    
    
    </>
  )
}

export default WarrantyTips