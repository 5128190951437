import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Image, Row } from "react-bootstrap";
import carBg from "../images/CARbg.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaStar, FaWhatsapp } from "react-icons/fa6";
import { FaStarHalfAlt } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";

function HomeTitle() {
  const navigate = useNavigate();
  const location = useLocation();

  // const isMobileOrTablet = useMediaQuery({ query: '(max-width: 991px)' });

  const [isFixed, setIsFixed] = useState(false);

  const handleCallButtonClick = () => {
    const canMakeCall = !!navigator.userAgent.match(/iPhone|iPad|iPod|Android/i);

    if (canMakeCall) {
      window.location.href = 'tel:+447889052301';
    } else {
      navigate("/contact-us");
    }
  };

  useEffect(() => {
     
      const handleScroll = () => {
        const offset = window.scrollY;
        setIsFixed(offset > 50); 
      };

      handleScroll();

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    
  }, []);

  const containerStyle = {
    height: "100vh",
    
    backgroundImage: ` url('/images/mainbg.jpg')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right 20% center ",
   // backgroundAttachment: "fixed",
  };

  const handleWhatsappClick = () => {
    const url = `https://api.whatsapp.com/send/?phone=%2B447889052301&text&type=phone_number&app_absent=0`;
    window.open(url, '_blank');
  };

  return (
    <>
    
    <section style={containerStyle} className=" d-flex align-items-center">
       
      <Container className="px-4">
        
        <Row>
          <Col

             md={8}
            lg={6}
            sm={12}
            className=""
            data-aos="fade-right"
            data-aos-delay="800"
            data-aos-duration="1000"
          >
            <div
              className="px-4 mt-4"
              style={{ borderLeft: "4px solid #FFA500" }}
            >
              <h1 className="display-3 text-white fw-bold">
                {location.pathname == "/car-warranty" ? (
                  <span>
                    Find The Perfect{" "}
                    <span
                      style={{ color: "#008BFF", background: "#FFA500" }}
                      className="px-2"
                    >
                      Warranty
                    </span>{" "}
                    For Your Car.
                  </span>
                ) : location.pathname == "/van-warranty" ? (
                  <span>
                    Find The Perfect{" "}
                    <span
                      style={{ color: "#008BFF", background: "#FFA500" }}
                      className="px-2"
                    >
                      Warranty
                    </span>{" "}
                    For Your Van.
                  </span>
                ) : (
                  <span>
                    Discover The Ideal{" "}
                    <span
                      style={{ color: "#008BFF", background: "#FFA500" }}
                      className="px-2"
                    >
                      Warranty
                    </span>{" "}
                    For Your Vehicle.
                  </span>
                )}
              </h1>
              <p className="text-white">
                Protect Your Ride, Drive With Confidence
              </p>
            </div>
            <p className="text-white ">
              Our warranty undergoes regular reviews to ensure it remains the
              market's finest, offering unbeatable coverage and value.
            </p>

            <Row className="p-xs-2">

            <Col>
              <Button
                onClick={() => navigate("/purchase/vehicle-details")}
                variant="primary"
                className="border-white fw-bold border-1 rounded-pill w-100 text-white text-nowrap  m-1 "
                style={{ backgroundColor: "#008BFF" }}
              >
                Find Your Warranty
              </Button>
              
              </Col> 
          
              
            </Row>
            <Row 
            // className={isMobileOrTablet ? (isFixed ? 'fixed-bottom px-4' : '') : 'p-2'}
            className={(isFixed ? 'fixed-bottom px-4' : '') }
            >
            
              <Col >
              <Button
               onClick={handleCallButtonClick}
                variant="dark"
                className="border-white fw-bold border-1 rounded-pill w-100 text-white text-nowrap  m-1 "
                
              >
                Speak to an expert
              </Button>
              </Col>

              <Col >
            <Button
                onClick={handleWhatsappClick}
                variant="success"
                className=" border-white border-1 fw-bold rounded-pill w-100 text-nowrap px-4 m-1  speak-to-exp-btn "
              >
                <FaWhatsapp size={19}/> Chat on WhatsApp
              </Button>
            </Col>
            </Row>
          


            <p className="text-white">
              <p className="fs-6 mt-2">
                
                Our customers say
                <span className=" ms-2 fw-bold" style={{ fontSize: "1.1rem",letterSpacing:'1px' }}>
                  Excellent
                </span>
                <span
                  className=" mx-2 "
                  style={{ color: "gold", position: "relative", top: "-2px" }}
                >
                  <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStarHalfAlt />
                </span>
                4.4 out of 5 based on 6,074 reviews.
              </p>
            </p>

            
          </Col>
          
        </Row>
      </Container>
    </section>
    </>
  );
}

export default HomeTitle;
