import React from 'react'
import { Container } from 'react-bootstrap'
import Title from '../components/Title'

function AboutUsSec() {
  return (
   <section>
    <Container className='py-5 px-md-5'>
        <Title
            textAlign='text-left'
            title='About Super Warranty'
            description="Welcome to Super Warranty, your trusted partner in securing peace of mind for your valued investments. As a premier warranty provider, we take pride in offering a range of comprehensive protection plans designed to suit your individual needs. Our three distinct cover levels - Safe, Secure, and Supreme - reflect our commitment to addressing the unique requirements of every vehicle owner. Whether your vehicle has aged gracefully or is just beginning its journey, our tailored plans ensure that you can drive with confidence, knowing that unexpected repair costs won't disrupt your stride. From the limitless coverage of Safe to the focused protection of Secure, and the enhanced security of Supreme, we are here to ensure your vehicle's longevity."
        />

        <Title
            textAlign='text-left'
            title='Our Mission'
            description="At Super Warranty, our mission is to redefine the warranty experience by delivering not just coverage, but a sense of empowerment to our customers. We believe that a warranty should be more than a contract; it should be a shield that enables you to embrace life's adventures without worry. Our aim is to provide transparent, flexible, and reliable coverage that adapts to your vehicle's age and mileage, ensuring that you receive the protection you deserve. With a dedication to exceptional customer service, honesty, and integrity, we strive to create lasting relationships with our customers, built on trust and reliability. Join us in our mission to drive with confidence, and choose Super Warranty as your partner on the road ahead."
        />
    </Container>
   </section>
  )
}

export default AboutUsSec