

import HomeTitleBgImg from '../Template/sections/HomeTitleBgImg'
import HomeInfoSec from '../Template/sections/HomeInfoSec'
import CarCover from '../Template/sections/CarCover'
import HowItWorksSec from '../Template/sections/HowItWorksSec'
import SupportSec from '../Template/sections/SupportSec'
import ScrollToTop from '../Template/components/ScrollToTop'

function CarWarranty() {

  
  return (
    <>
        <ScrollToTop/>
        <HomeTitleBgImg />
        <HomeInfoSec />
        <CarCover />
        <HowItWorksSec />
        <SupportSec />
          
    </>
  )
}

export default CarWarranty