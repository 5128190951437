import React, { useState } from 'react';
import { Accordion,  Form,  Table } from 'react-bootstrap';

function PlanAccordionItem({ title, eventKey, planData ,setPlan, customClass,plan ,index}) {
    const [smallestPrice, setSmallestPrice] = useState(null);

    
    

    const indexToDataDelay = {
        0: "300",
        1: "500",
        2: "700",
      };

 const dataDelay = indexToDataDelay[index] || "300";

    const updateSmallestPrice = (price) => {
        if (smallestPrice === null || price < smallestPrice) {
            setSmallestPrice(price);
        }
    };

    return (
        <Accordion.Item className={`my-3 ${customClass}`} eventKey={eventKey} data-aos='flip-up' data-aos-duration="1000" data-aos-delay={dataDelay}>
            <Accordion.Header className=''>
                <div className='d-flex  justify-content-between ' style={{ width: '90%' }}>
                    <span id='plan-title' className='fw-bold fs-5 text-white' style={{textTransform:'uppercase'}}>{title}</span>
                    <div className='' ><span style={{ opacity:'50%',fontWeight:'bold' }}>Starting from </span> <span id='starting-from' style={{ color: 'white', fontWeight: 'bold', fontSize: '1.4rem' }}>{smallestPrice !== null ? `£${smallestPrice}` : 'N/A'}</span></div>
                </div>
            </Accordion.Header>
            <Accordion.Body>
                <Table responsive borderless>
                   
                    <tbody>
                        {planData.map((claimData, claimIndex) => (
                            <tr key={claimIndex} className='align-items-center'>
                                <td style={{ color: '#004C9A' }}>
                                    <p className='fs-4 fw-bold mb-0'>&pound;{claimData.claim}</p>
                                    <p className='mt-0'>Claim Limit</p>
                                </td>

                                {claimData.plans.map((plan, index) => {
                                    updateSmallestPrice(plan.price); // smallest price update
                                    return (
                                        <td>
                                            <div style={{ display: 'inline-block' }} className='' key={plan._id}>
                                                <Form.Check
                                                    inline
                                                    label={`£${plan.price}`}
                                                    value={plan._id}
                                                    name='plan'
                                                    type='radio'
                                                    id={`inline-radio-${plan._id}`}
                                                    className='fs-4 fw-bold mx-3'
                                                   
                                                    onChange={() => setPlan({...plan,cover:title, claimLimit: claimData.claim})}
                                                   
                                                />
                                                <p className='ms-5 ' style={{ fontSize: '0.9rem' }}>
                                                    {plan.month} months
                                                </p>
                                            </div>
                                        </td>
                                    );
                                })}

                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Accordion.Body>
        </Accordion.Item>
    );
}

export default PlanAccordionItem;
