import axios from "axios";

const instance = axios.create({
  //baseURL: "http://localhost:5000",
  baseURL: "https://carwarranty-backend.onrender.com/",
 // baseURL: "https://carwarranty-backend.adaptable.app/"//sid hosted
});
// const instance = axios.create({ baseURL: "http://localhost:4000" });

export default instance;
