import React from 'react'
import { Col, Container, Image, Row, Table } from 'react-bootstrap'
import FormTitle from '../../Template/components/LoginComponents/FormTitle';
import './WarrantyTable.css'

import {LuLaptop2, LuSnowflake, LuVolume2} from 'react-icons/lu'
import {FaCarBattery, FaGear, FaOilCan} from 'react-icons/fa6'
import { BiInjection } from 'react-icons/bi'
import { MdOutlineCarRepair, MdSensors } from 'react-icons/md'
import { ImCheckmark, ImKey2 } from 'react-icons/im'
import { BsClipboardCheck, BsShieldCheck } from 'react-icons/bs'

import clutch from './images/clutch.png'
import cpuChip  from './images/cpuChip.png'
import drop from './images/drop.png'
import engine from './images/engine.png'
import engineBelt from './images/engineBelt.png'
import evFront from './images/evFront.png'
import haldexSystem from './images/haldexSystem.png'
import key from './images/Key.png'
import steeringWheel from './images/steeringWheel.png'
import suspension from './images/suspension.png' 
import tools from './images/tools.png'


 function CoverComponents({icon,name}){
  
    return(
   <Col md={3} className='my-2'>
        <Image style={{maxWidth:'24px'}} src={icon} /><span className='mx-2' style={{color:'gray'}}>{name}</span>
   </Col>
    )
}

 function WarrantyTable() {

    const headData = [
            {title:'Age', col1:'Any Age',col2:'10 Years',col3:'7 Years'},
            {title:'Mileage', col1:'Any Mileage',col2:'100,000 Miles',col3:'70,000 Miles'},
            {title:'Component Coverage', col1:'Listed Parts Agreements',col2:'Mechanical & Electrical',col3:'Mechanical & Electrical'},

    ];

    const data = [
        { icon: <LuSnowflake size={25}/>, name: 'Air Conditioning Re-Grassing', col2: <ImCheckmark size={25} color='#0DADF4'/>, col3: <ImCheckmark size={25} color='#0DADF4'/> },
        { icon: <FaCarBattery size={25}/>, name: 'Battery', col3: 'Up To 6 Months (Exc. Hybrid)' },
        { icon: <BsClipboardCheck size={25}/>, name: 'Diagnosis', col2: 'Up To 1 Hour', col3: 'Up To 2 Hours' },
        { icon: <FaGear size={25}/>, name: 'Flywheel', col2: '£500', col3: '£1000' },
        { icon: <LuVolume2 size={25}/>, name: 'In Car Entertainment/Sat Nav/Tel', col2: '£500', col3: <ImCheckmark size={25} color='#0DADF4'/> },
        { icon: <BiInjection size={25}/>, name: 'Injectors',  col3: <ImCheckmark size={25} color='#0DADF4'/> },
        { icon: <FaOilCan size={25}/>, name: 'Oil Seals',  col3: <span>Engine, Differential,<br/> Gearbox, Turbocharger</span> },
        { icon: <MdOutlineCarRepair size={25}/>, name: 'Parts Replaced In Repairs',  col3: <span>Coil Springs,<br /> Shock Absorbers</span> },
        { icon: <ImKey2 size={25}/>, name: 'Remote Key Fobs/Key Cards',  col3: '£500[2 Key Max]' },
        { icon: <MdSensors size={25}/>, name: 'Sensors', col2:'£500', col3: <ImCheckmark size={25} color='#0DADF4'/> },
        { icon: <LuLaptop2 size={25}/>, name: 'Software And Software Updates',  col3: <ImCheckmark size={25} color='#0DADF4'/> },
        { icon: <BsShieldCheck size={25}/>, name: 'Wear & Tear',  col3: 'Up To 85,000 Miles' },

    ];

    return (
        <Container className='my-5'>

            
            <FormTitle
            title='Warranty Cover Levels'
            description='Super car warranty offers three different levels of cover -safe, secure, and supreme. The following components are covered to varying degrees in all three levels.  Please see Relevant Annex for full details.'
            />

            <Row>
                <CoverComponents icon={engineBelt} name='Camshaft Timing Belt' />
                <CoverComponents icon={key} name='Central Locking' />
                <CoverComponents icon={haldexSystem} name='Haldex Unit' />
                <CoverComponents icon={engine} name='Engine' />
                
            </Row>
            <Row>
                <CoverComponents icon={steeringWheel} name='Steering [Inc. Power Assist]' />
                <CoverComponents icon={evFront} name='Hybrid Vehicles' />
                <CoverComponents icon={cpuChip} name='ECU' />
                <CoverComponents icon={suspension} name='Suspension' />
                
            </Row>
            <Row>
                <CoverComponents icon={tools} name='Working Materials' />
                <CoverComponents icon={drop} name='Cooling Systems' />
                <CoverComponents icon={clutch} name='Clutch' />
                
            </Row>

            <h5 style={{color:'#004C9A'}} className='my-5'>Coverage varies for the components details below.</h5>
            <Table bordered responsive  className='detail-table ' >
                <thead>
                    <tr className='text-center '>
                        <th></th>
                        <th></th>
                        <th className='rounded-top-4 text-white fw-normal fs-5' style={{backgroundColor:'#008BFF'}}>SAFE</th>
                        <th className='rounded-top-4 text-white fw-normal fs-5' style={{backgroundColor:'#8CC63E'}}>SECURE</th>
                        <th className='rounded-top-4 text-white fw-normal fs-5' style={{backgroundColor:'#FFD700'}}>SUPREME</th>

                    </tr>
                </thead>
                <tbody style={{color:'#004C9A'}}>

                    {headData.map((item, index) => (


                        <tr  className='row-odd'   key={index}>
                            <td style={{borderRightColor:'transparent'}}></td>
                            <td  style={{color: '#004C9A',fontWeight:'bold' }}> {item.title}</td>
                            <td  style={{color: '#004C9A',fontWeight:'bold' }} className='text-center'> {item.col1}</td>
                            <td style={{color: '#004C9A',fontWeight:'bold' }} className='text-center'> {item.col2}</td>
                            <td style={{color: '#004C9A',fontWeight:'bold' }} className='text-center'> {item.col3}</td>
                          
                        </tr>


                    ))}


                    
                </tbody>

                <thead>
                    <tr style={{borderTopColor:'transparent',borderBottomColor:'transparent'}} className='cover-title'>
                        <th style={{borderRightColor:'transparent'}}></th>
                        <th className='text-white'>COVER</th>
                        <th></th>
                        <th></th>
                        <th></th>

                    </tr>
                </thead>
                <tbody className='table-body'>

                    {data.map((item, index) => (

                        <tr key={index} className={`row-${(index+1) % 2 ===0? 'even' : 'odd' }`} style={{borderTopColor:'transparent',borderBottomColor:'transparent'}}>
                            <td className={`col-${(index+1)%2===0? 'even':'odd'} text-center text-white`} >{item.icon}</td>
                            <td style={{color: '#004C9A',fontWeight:'bold' }} >{item.name}</td>
                            <td style={{color: '#004C9A' ,fontSize:'0.9rem',fontWeight:'bold' }} className='text-center'> {item.col1}</td>
                            <td style={{color: '#004C9A' ,fontSize:'0.9rem',fontWeight:'bold'}} className='text-center'> {item.col2}</td>
                            <td style={{color: '#004C9A' ,fontSize:'0.9rem',fontWeight:'bold'}} className='text-center'> {item.col3}</td>
                        </tr>




                    ))}

                </tbody>
            </Table>
        </Container>
    )
}

export default WarrantyTable;