import React from 'react'
import HowWeOperateTitle from '../Template/sections/HowWeOperateTitle'
import OperationSec from '../Template/sections/OperationSec'
import ScrollToTop from '../Template/components/ScrollToTop'

function HowWeOperate() {
  return (
    <>
    <ScrollToTop/>
    <HowWeOperateTitle/>
    <OperationSec/>
    
    </>
  )
}

export default HowWeOperate