import React, { useState } from 'react'
import { Card, Col, Container, Row, Modal } from 'react-bootstrap'
import RateCard from '../../Template/components/LoginComponents/RateCard'
import RegProgress from '../../Template/components/LoginComponents/RegProgress'
import toast from 'react-hot-toast';

import { useDispatch, useSelector } from 'react-redux'

import axiosInstance from '../../Utils/axios';
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import { useNavigate, useParams } from 'react-router-dom'
import { hideLoading, showLoading } from '../../Features/alertSlice'
import PaymentConfirm from './PaymentConfirm'
import { unSetUserDetails } from '../../Features/userDetailsSlice'
import { unSetVehicleInformation } from '../../Features/vehicleInformationSlice'
import { unSetSelectedPlan } from '../../Features/selectedPlanSlice'
import { unSetWarrantyDetails } from '../../Features/warrantyDetailsSlice'
import { unSetVehicleDetails } from '../../Features/vehicleDetailsSlice'
import FormTitle from '../../Template/components/LoginComponents/FormTitle'
import ScrollToTop from '../../Template/components/ScrollToTop';

// This value is from the props in the UI
const style = { "layout": "vertical", "shape": "pill" };

// Custom component to wrap the PayPalButtons and show loading spinner
const ButtonWrapper = ({ showSpinner, warrantyID, token,onClose,reRenderFunction }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [{ isPending }] = usePayPalScriptReducer();

  // console.log({ warrantyData })
  const createOrder = async () => {
    console.log({ w: warrantyID })
    try {
      dispatch(showLoading())
      const { data } = await axiosInstance.post(`/api/warranty/create-paypal-order/${warrantyID}`,
        {},
        { headers: { Authorization: token } }
      );
      dispatch(hideLoading())
      console.log({ data });
      return data.orderID;
    } catch (err) {
      dispatch(hideLoading())
      toast.error('Something went Wrong, please try again.')
      console.log(err);
    }
  }

  const onApprove = async (order) => {
    try {
      dispatch(showLoading());
      console.log({ order });
      const { data } = await axiosInstance.post(`/api/warranty/capture-paypal-payment/${warrantyID}`,
        { order },
        { headers: { Authorization: token } }
      );
      dispatch(hideLoading())

      if (data) {
        console.log({ data });

        toast.custom((t) => (
          <PaymentConfirm t={t} />
        )
        );

        

       
         onClose();

         if (typeof reRenderFunction === 'function') {
        reRenderFunction();
      }

      }
    } catch (error) {
      dispatch(hideLoading())
      toast.error('Something went Wrong, please try again.')
      console.log(error);
    }
  }

  return (
    <>
      {(showSpinner && isPending) && <div className="spinner" />}
      <PayPalButtons
        style={style}
        disabled={false}
        forceReRender={[style]}
        fundingSource={undefined}
        createOrder={createOrder}
        onApprove={onApprove}
        onError={(err) => { console.log(err); window.alert(err.message); }}
      />
    </>
  );
};


function PaymentPage2({ id, show, onClose, reRenderFunction }) {
  const { userToken } = useSelector(state => state.auth);

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton className='border-0'>
        <Modal.Title >Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body className=''>
        <Container className='p-3'>
          <Card className='border px-4 py-2 rounded-5'>
            {/* <FormTitle title='Payments' /> */}
            <PayPalScriptProvider options={{ clientId: "AUnQ1e8ZEls0r41WIXnIh91jnfajhHC-6v3-QYezSAlCZRwPiCjwdHTiM4SG7XSXcwci3DdsRW97i_Kq", currency: "EUR", locale: "en_GB" }}>
              <ButtonWrapper showSpinner={true} warrantyID={id} token={userToken} onClose={onClose} reRenderFunction={reRenderFunction}/>
            </PayPalScriptProvider>
          </Card>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default PaymentPage2


