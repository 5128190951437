import React from 'react'
import ScrollToTop from '../../Template/components/ScrollToTop'
import { Col, Container, Row } from 'react-bootstrap'

function LoginMethodTemplate({children}) {
  return (
    
    <section
      style={{
        minHeight: "89.5vh",
        backgroundImage: "url(/images/lmbg1.png)",
        backgroundSize: "cover",
      }}
      className="pt-3 p-2"
    >
      <ScrollToTop />
      <Container
        className="border shadow rounded-5 p-md-5 mt-5"
        style={{
          background: "rgba( 255, 255, 255, 0.1 )",
          boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
          backdropFilter: "blur( 20px )",
          WebkitBackdropFilter: "blur( 20px )",
          borderRadius: "10px",
          
        }}
        data-aos="zoom-in"
        data-aos-delay="300"
        data-aos-duration="500"
      >
        <Row className="d-flex align-items-center justify-content-center">
          <Col md={6} className="p-md-4 text-center">
                {children}
          </Col>
          </Row>
      </Container>
    </section>
  )
}

export default LoginMethodTemplate