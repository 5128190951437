import React from 'react'
import { Accordion, Container } from 'react-bootstrap'


function RepairFaq() {
    const data = [

        {
            que: 'Are repairs covered under all three warranty levels (Safe, Secure, and Supreme)? ',
            ans: 'Yes, all three warranty levels (Safe, Secure, and Supreme) cover repairs for mechanical and electrical failures, depending on the age and mileage restrictions of each level. '

        },
        {
            que: 'What types of repairs are covered under the "Safe" warranty?',
            ans: 'The "Safe" warranty covers a wide range of repairs, including engine, transmission, electrical systems, and other major components that experience sudden mechanical or electrical failures. '

        },
        {
            que: 'Are wear and tear items covered under the warranties?',
            ans: 'No, wear and tear items are not covered under our warranties. These items include brake pads, tires, wiper blades, and other parts that experience normal wear over time. '

        },
        {
            que: 'Does the "Secure" warranty cover repairs for older vehicles?',
            ans: 'Yes, the "Secure" warranty provides coverage for vehicles up to 15 years old or with up to 100,000 miles on the odometer, making it suitable for older vehicles that need extended protection. '

        },
        {
            que: 'Will the "Supreme" warranty cover repairs for high-mileage vehicles?',
            ans: 'Yes, the "Supreme" warranty is designed to cover repairs for vehicles up to 10 years old or with up to 70,000 miles, providing extensive coverage even for high-mileage vehicles. '

        },
        {
            que: 'Do I need to pay for diagnostic fees when making a warranty claim?',
            ans: 'No, diagnostic fees required to identify a covered issue are typically covered under our warranties.'
        },
        {
            que: 'Can I choose any repair shop for warranty service?',
            ans: 'While we encourage using our network of authorized repair shops for quality service, you may have the flexibility to use other authorized repair facilities for warranty repairs. '

        },
        {
            que: 'Are repairs covered if my vehicle breaks down outside of my local area?',
            ans: 'Yes, our warranties cover repairs performed by authorized repair shops, even if you are away from your local area.'
        },
        {
            que: 'What do I do if my vehicle breaks down after regular business hours?',
            ans: ' Our roadside assistance service is available 24/7, so you can get help whenever you need it, even outside regular business hours. '

        },
        {
            que: 'Are rental car expenses covered while my vehicle is being repaired?',
            ans: ' Yes, our warranty packages often include rental car benefits, allowing you to stay mobile while your vehicle is undergoing repairs. '
        },
        {
            que: 'How long does the claims process typically take?',
            ans: 'Our goal is to process warranty claims as quickly as possible, usually within a few business days once all required documentation is submitted. '

        },
        {
            que: 'Can I upgrade my warranty if I initially selected a lower level of coverage?',
            ans: 'Yes, depending on eligibility criteria, you may be able to upgrade your warranty to a higher coverage level at any time.'
        }

    ]
    return (
        <section className='my-5'>
            <Container>
                <h1 className='my-4' style={{ color: '#FC6A00' }}>Repair FAQ’s</h1>

                <Accordion>

                    {data.map((item, index) => (

                        <Accordion.Item className='border-0' key={index} eventKey={`${index}`}>
                            <Accordion.Header><span className='fs-5'>{item.que}</span></Accordion.Header>
                            <Accordion.Body>{item.ans}</Accordion.Body>
                        </Accordion.Item>



                    ))}

                </Accordion>

            </Container>
        </section>
    )
}

export default RepairFaq