
import AboutUsTitle from '../Template/sections/AboutUsTitle'
import AboutUsSec from '../Template/sections/AboutUsSec'
import ScrollToTop from '../Template/components/ScrollToTop'

function AboutUs() {
  

  return (
    <>
    <ScrollToTop/>
    <AboutUsTitle/>
    <AboutUsSec/>
    </>
  )
}

export default AboutUs