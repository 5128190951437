import React from 'react'
import TitleBg from '../components/TitleBg'

import PlanLevelBg from '../images/PlanLevelBg.png'


function CarPlanLevelTitle() {
  return (
    <>
    <TitleBg
    title='Car Levels of Cover'
    description='Here, we’re the experts in getting the right level of extended warranty cover for your used car based on make, age, and mileage. We focus on making sure you have peace of mind motoring every day.'
    bgImg={PlanLevelBg}
    />
    </>
  )
}

export default CarPlanLevelTitle