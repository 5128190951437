import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import InfoBox from '../components/InfoBox'
import Title from '../components/Title'
import BlueQA from '../components/BlueQA'

function TypeOfWarranties() {

    const containerStyle={
        backgroundColor:'#D1D5DB4C',
        borderTopLeftRadius:'80px',
        borderTopRightRadius:'80px',
    }

  return (
    <section style={containerStyle}>
        <Container className='py-5 px-md-5'>
            <Row>
              
                <Title title='Different type of warranties'
                       description="Insured vs Assured (Discretionary) warranty. What's the difference?"

                />     
                

            </Row>
            <BlueQA
                title='Insured Warranty'
                ans='Insured warranty schemes are based on agreements with insurance companies and underwriters. These schemes provide policyholders with indemnification against losses resulting from covered parts in particular situations.
                Repair decisions necessitate underwriter authorization, and our coverage operates with clarity, leaving no room for ambiguity or grey areas.'

            />
            <BlueQA
                title='Non-Insured (Discretionary) Warranty'
                ans="Discretionary warranties depend on the supplier's discretion to determine how to address repair claims or issues. They provide greater flexibility in terms and conditions. In-house authorization of repairs, including costly ones, allows for faster turnaround times. Discretionary warranties can expand coverage to encompass repairs in the 'grey area.' By appointing an independent arbiter, we ensure consumer protection and fair, unbiased resolution of any disputes."

            />
            


        </Container>
    </section>
  )
}

export default TypeOfWarranties