import React, { useState } from 'react'
import { Card, Col, Container, Form, Row } from 'react-bootstrap'
import FormTitle from '../../Template/components/LoginComponents/FormTitle'
import ContinueBtn from '../../Template/components/LoginComponents/ContinueBtn'
import ResetButton from '../../Template/components/LoginComponents/ResetButton'
import FormField from '../../Template/components/LoginComponents/FormField'
import RateCard from '../../Template/components/LoginComponents/RateCard'
import RegHeader from '../../Template/components/LoginComponents/RegHeader'
import RegProgress from '../../Template/components/LoginComponents/RegProgress'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setPlanStartDate, setWarrantyDetails } from '../../Features/warrantyDetailsSlice'
import { setVehicleInformation } from '../../Features/vehicleInformationSlice'
import instance from '../../Utils/axios'
import FormOverlay from '../../Template/components/LoginComponents/FormOverlay'
import ScrollToTop from '../../Template/components/ScrollToTop'
import toast from 'react-hot-toast'

function Cover() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { warrantyDetails } = useSelector(state => state.warrantyDetails);
    const [warrantyData, setWarrantyData] = useState({ ...warrantyDetails });
    const { vehicleInfo } = useSelector((state) => { console.log({ state }); return state.vehicleInfo });
    const [vehicleInfoData, setVehicleInfoData] = useState({ ...vehicleInfo });


    // const userEmail = useSelector((state)=>state.userDetails.email);


    const handleVehicleInfoInput = (e) => {
        setVehicleInfoData({ ...vehicleInfoData, [e.target.name]: e.target.value });

    }

    const handleWarrantyInput = (e) => {
        setWarrantyData({ ...warrantyData, [e.target.name]: e.target.value });
    }



    const handleContinue = async (event) => {

        event.preventDefault();

        try {


            const planStartDate = new Date(warrantyData.planStartDate);
            const purchaseDate = new Date(vehicleInfoData.purchaseDate);
    
            if (planStartDate < purchaseDate) {
                
                toast.error("Plan start date cannot be before the purchase date");
                return; 
            }
            dispatch(setWarrantyDetails(warrantyData));
            dispatch(setVehicleInformation(vehicleInfoData));

            navigate('/purchase/warranty-suggestion');

        } catch (error) {
            console.log(error);

        }


    }


    return (
        <>
            <section>
                <ScrollToTop/>
                <Container className='p-3'>
                    <RegProgress step='step3' />
                    <Row >
                        <Col md={7}>
                            <Card className='border px-4 py-2 rounded-5' data-aos="flip-right" data-aos-delay='300' data-aos-duration='1000'>

                                <Form className='px-md-5 fw-bold' style={{ color: '#004C9A' }} onSubmit={handleContinue}>
                                    <FormTitle
                                        title='Your Warranty Details'
                                    />

                                    <FormField
                                        label='Plan Start Date'
                                        type='date'
                                        info='Start Date'
                                        value={warrantyData?.planStartDate}
                                        name='planStartDate'
                                        onChange={handleWarrantyInput}


                                    />

                                    

                                    <FormField
                                        label='Purchase Date'
                                        type='date'
                                        info='Purchase Date'
                                        value={vehicleInfoData?.purchaseDate}
                                        name='purchaseDate'
                                        onChange={handleVehicleInfoInput}
                                    />
                                   
                                   <Form.Group as={Row}>
                                    <Col>
                                        <Form.Label className='me-md-4'>Service History
                                        <FormOverlay info='Service History' />
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name="serviceHistory"
                                        type='radio'
                                        id={`inline-service-1`}
                                        // value='Yes'
                                        required                                        
                                        checked={vehicleInfoData?.serviceHistory === true}
                                        onChange={() => setVehicleInfoData({...vehicleInfoData, serviceHistory: true})}
                                    />
                                    <Form.Check
                                        inline
                                        label="No"
                                        name="serviceHistory"
                                        type='radio'
                                        // value='No'
                                        id={`inline-service-2`}
                                        required
                                        checked={vehicleInfoData?.serviceHistory === false}
                                        onChange={() => setVehicleInfoData({...vehicleInfoData, serviceHistory: false})}
                                    />
                                    </Col>
                                    </Form.Group>
                                   
                                    
                                    <Row className='my-2'>

                                       
                                            <Col>    
                                            <ContinueBtn />
                                        </Col>
                                    </Row>
                                </Form>



                            </Card>

                        </Col>

                        
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Cover