//store.js
import { configureStore } from '@reduxjs/toolkit';
import vehicleDetailsReducer from './Features/vehicleDetailsSlice';
import userDetailsReducer from './Features/userDetailsSlice';
import selectedPlanReducer from './Features/selectedPlanSlice';
import warrantyDetailsReducer from './Features/warrantyDetailsSlice';
import vehicleInformationReducer from './Features/vehicleInformationSlice';
import authReducer from './Features/authSlice';
import alertsReducer from './Features/alertSlice';
import recentViewedReducer from './Features/recentViewedSlice';
import userDataReducer from './Features/userDataSlice';
 


const store = configureStore({
    reducer: {
        vehicleDetails: vehicleDetailsReducer,
        userDetails: userDetailsReducer,
        selectedPlan: selectedPlanReducer,
        warrantyDetails: warrantyDetailsReducer,
        vehicleInfo: vehicleInformationReducer,
        auth:authReducer,
        alerts: alertsReducer,
        recentViewed: recentViewedReducer,
        userData: userDataReducer,
      },
});

export default store;
