import { createSlice } from '@reduxjs/toolkit';

const userData = localStorage.getItem('userData')? JSON.parse(localStorage.getItem('userData')):'';

const userDataSlice = createSlice({
    name:'userData',
    initialState:{userData},
    reducers:{
        setUserData: (state,action)=>{
            
            state.userData = action.payload;
            localStorage.setItem('userData',JSON.stringify(action.payload));
           
        },
        unSetUserData: (state,action)=>{
            state.userData = null;
            localStorage.removeItem('userData');

        }
    }


})


export const {setUserData, unSetUserData} = userDataSlice.actions;
export default userDataSlice.reducer;