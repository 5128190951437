import React from 'react'
import TitleBg from '../components/TitleBg'
import bg from '../images/ContactUsBg.png';

function PrivacyPolicyTitle() {
  return (
    <TitleBg
        title='Privacy Policy'
        bgImg={bg}


    />
  )
}

export default PrivacyPolicyTitle