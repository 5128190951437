import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import hulk from '../images/hulk.png';

function HomeCustomerSec() {

    const navigate = useNavigate();

  return (
    <section style={{backgroundColor:'#008BFF'}}>
      <Container>
        <Row className="py-5">
          <Col>
            <Image fluid src={hulk} style={{maxHeight:'500px'}}/>
          </Col>
          <Col md={6} className="p-3 px-md-5">
            <h1 className="my-3 text-white mb-4">Customer-Centric Warranty Offerings</h1>
            <p className="text-white" style={{textAlign:'justify'}}>
              At our Warranty Center, our commitment to exceptional customer
              support is unwavering. Our dedicated support team is available
              24/7 to assist you. With an impressive 100% customer satisfaction
              rate, we've successfully resolved over 30,000 inquiries, ensuring
              that our clients experience swift and effective solutions to their
              warranty-related queries. You can trust us to provide you with the
              support you need, whenever you need it.
            </p>
            <Button onClick={()=>navigate('/purchase/vehicle-details')} className=" shadow rounded-pill border-2 px-4 my-4 py-2 text-white" style={{backgroundColor:'#FFA500',borderColor:'white'}}>Get a quote</Button>

          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default HomeCustomerSec;
