import React from 'react'
import { Col, Row } from 'react-bootstrap'

function BlueQA({que,ans,title}) {
  return (
    <Row>
    <Col md={12} className='my-2'>
      
    <h3 style={{color:'#008BFF'}}>{title}</h3>
    <h4 style={{color:'#008BFF'}}>{que}</h4>
    <p>{ans}</p>
    </Col>
    </Row>
  )
}

export default BlueQA