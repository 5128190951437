import React, { useEffect, useState } from "react";
import {
  Carousel,
  Col,
  Container,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";

import ActiveWarranties from "./components/ActiveWarranties";
import { FailCard, PassCard, ReviewCard } from "./components/InspectionCard";
import {
  ActiveOverview,
  ExpiredOverview,
  UpComingOverview,
} from "./components/OverviewCard";
import {
  ActiveViewed,
  ExpiredViewed,
  RecentViewedCard,
} from "./components/RecentViewed";
import { useDispatch, useSelector } from "react-redux";
import "./Dashboard.css";
import { hideLoading, showLoading } from "../../../Features/alertSlice";
import toast from "react-hot-toast";
import instance from "../../../Utils/axios";
function DashboardBody() {
  const [totalActive, setTotalActive] = useState("");
  const [upcoming, setUpcoming] = useState("");
  const [expired, setExpired] = useState("");
  const [warranties, setWarranties] = useState([]);
  const [warrantyData,setWarrantyData] = useState([]);
  const userToken = useSelector((state)=>state.auth.userToken)
  const recentViewed = useSelector((state) => state.recentViewed);
  const dispatch = useDispatch();


  const fetchActiveWarranties = async()=>{

    try {
      dispatch(showLoading());
      const response = await instance.get('api/warranty/my-warranties/?active=true',{
      
        headers:{
         Authorization: `${userToken}`,
        }, 
 });
 dispatch(hideLoading());
 console.log(response);
 const data= response.data

 setTotalActive(data?.activeWarranty?.active);
 setUpcoming(data?.activeWarranty?.upcoming);
 setExpired(data?.activeWarranty?.expired);
 setWarranties(data?.warranties);
 const warrantyArray = data?.activeWarranty?.warranties?.map(warranty => ({

    id:warranty?._id,
    planName : warranty?.plan,
    regNum : warranty?.vehicleDetails?.reg_num,
    expDate : warranty?.expiry_date,
    date: warranty?.createdAt,
   remDays: warranty?.remaining_days,

 })) ||[];


  setWarrantyData(warrantyArray)
  
  console.log(data);
      
    } catch (error) {
      dispatch(hideLoading());
      toast.error(error?.response?.data?.error?.message || error?.message);

      console.log(error);

    }


  }

  useEffect(()=>{

  fetchActiveWarranties();


 },[userToken])



  return (
    <section style={{ backgroundColor: "#D1D5DB4C", minHeight: "100vh" }}>
      <Container>
        <Row className=" pt-5 d-flex justify-content-between">
          <Col md={6} >
            <Row>
              <h5 style={{ color: "#003366" }} className="mb-3 fw-bold">
                Overview
              </h5>

              <ActiveOverview count={totalActive} />

              <ExpiredOverview count={expired} />

              <UpComingOverview count={upcoming} />
            </Row>
            <Row className="mt-md-4" >
              {recentViewed?.length > 0 ? (
                <h5 style={{ color: "#003366" }} className="my-3 fw-bold">
                  Recent Viewed
                </h5>
              ) : null}

              {recentViewed?.map((warranty, index) => (
                <RecentViewedCard
                  title={warranty?.plan?.level?.level}
                  description={warranty?.vehicleDetails?.reg_num}
                  key={warranty?._id}
                />
              ))}

              {/*  <ActiveViewed />
                            <ExpiredViewed /> */}
            </Row>
          </Col>

          <Col md={5}>
            
              {warranties?.length > 0 ? (
                warranties?.length > 1 ? (
                  <Carousel
                    interval={null}
                    variant="dark"
                    style={{height:'100%'}}
                    className=""
                  >
                    {warranties?.map((warranty, index) => (
                      <Carousel.Item key={index} style={{height:'100%'}}>
                        <>
                          {warranty?.status?.value === "inspection-passed" ? (
                            <PassCard id={warranty?._id} reRenderFunction={fetchActiveWarranties} regNum={warranty?.vehicleDetails?.reg_num}/>
                          ) : warranty?.status?.value === "inspection-failed" ? (
                            <FailCard regNum={warranty?.vehicleDetails?.reg_num}/>
                          ) : (
                            <ReviewCard regNum={warranty?.vehicleDetails?.reg_num}/>
                          )}
                        </>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                ) : (
                  warranties?.map((warranty, index) => (
                    <div key={index} style={{height:'100%'}}>
                      {warranty?.status?.value === "inspection-passed" ? (
                        <PassCard id={warranty?._id} reRenderFunction={fetchActiveWarranties} regNum={warranty?.vehicleDetails?.reg_num}/>
                      ) : warranty?.status?.value === "inspection-failed" ? (
                        <FailCard regNum={warranty?.vehicleDetails?.reg_num}/>
                      ) : (
                        <ReviewCard regNum={warranty?.vehicleDetails?.reg_num}/>
                      )}
                    </div>
                  ))
                )
              ) : null}
            
          </Col>
        </Row>

        <Row className="py-5">
          <Col md={12}>
            <ActiveWarranties
                warrantyData={warrantyData}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default DashboardBody;
