
import './App.css';
import CarWarranty from './Pages/CarWarranty';
import Home from './Pages/Home';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import VanWarranty from './Pages/VanWarranty';
import UsedVanWarranty from './Pages/UsedVanWarranty';
import VanPlanLevels from './Pages/VanPlanLevels';
import UsedCarWarranty from './Pages/UsedCarWarranty';
import CarPlanLevels from './Pages/CarPlanLevels';
import ContactUs from './Pages/ContactUs';
import FaqPage from './Pages/FaqPage';
import WarrantyTips from './Pages/WarrantyTips';
import VehicleDetails from './Pages/loginAndRegistration/VehicleDetails';
import FindRegistration from './Pages/loginAndRegistration/FindRegistration';
import Details from './Pages/loginAndRegistration/Details';
import Cover from './Pages/loginAndRegistration/Cover';
import PaymentPage from './Pages/loginAndRegistration/PaymentPage';
import WarrantySuggestion from './Pages/loginAndRegistration/WarrantySuggestion';
import UserDashboard from './Template/components/UserDashboard/UserDashboard';
import Profile from './Template/components/UserDashboard/Profile';
import TransactionHistory from './Template/components/UserDashboard/TransactionHistory';

import PurchaseHistory from './Template/components/UserDashboard/PurchaseHistory';
import { useSelector } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import Footer from './Template/components/Footer';
import Header from './Template/components/Header';
import LoginMethods from './Pages/loginAndRegistration/LoginMethods';
import RegAsGuest from './Pages/loginAndRegistration/RegAsGuest';
import Login from './Pages/loginAndRegistration/Login';
import LoginWithGoogle from './Pages/loginAndRegistration/LoginWithGoogle';
import DetailMissing from './Pages/loginAndRegistration/DetailMissing';
import WarrantyRenewal from './Pages/WarrantyRenewal';
import HowWeOperate from './Pages/HowWeOperate';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import AboutUs from './Pages/AboutUs';
import CokkiePolicy from './Pages/CokkiePolicy';
import FailedPayments from './Pages/FailedPayments';
import TermsOfService from './Pages/TermsOfService';
import Blog from './Pages/Blog';
import NotFound from './Pages/NotFound';
import { GoogleLogin } from '@react-oauth/google';
import ProtectedRoute from './Utils/protectedRoute';
import ForgotPasswordMail from './Pages/loginAndRegistration/ForgotPasswordMail';
import ForgotPasswordNewPassword from './Pages/loginAndRegistration/ForgotPasswordNewPassword';
import HomeNew from './Pages/HomeNew';


function App() {
  const {loading} = useSelector(state => state.alerts);
  const location = useLocation();


  return (


    <>


    {loading &&(
      <div className='spinner-parent'>
      <div className="spinner-border" role="status">
  </div>
  </div>
    )}


     <Toaster position="top-center"  reverseOrder={false} />

      <Header />
      <Routes >
         
       
        
        <Route path='/purchase/vehicle-details' element={<VehicleDetails />} />  
        <Route path='/purchase/find-registration' element={<FindRegistration />} />
        <Route path='/purchase/details' element={<ProtectedRoute><Details /></ProtectedRoute>} />
        <Route path='/purchase/warranty-suggestion' element={<ProtectedRoute><WarrantySuggestion /></ProtectedRoute>} />
        <Route path='/purchase/cover' element={<ProtectedRoute><Cover /></ProtectedRoute>} />
        
        
        <Route path='/auth/login'  element={<Login />} /> 
        <Route path='/auth/forgot-password-mail'  element={<ForgotPasswordMail />} /> 
        <Route path='/password/reset/:token'  element={<ForgotPasswordNewPassword />} /> 
        <Route path='/auth/login-method'  element={<LoginMethods />} /> 
        <Route path='/auth/login-with-google'  element={<LoginWithGoogle />} /> 
        <Route path='/auth/details-missing'  element={<DetailMissing />} /> 
        <Route path='/auth/reg-as-guest'  element={<RegAsGuest />} /> 
        <Route path='/auth/login-as-guest'  element={<Login />} />


        <Route path='/' exact element={<HomeNew />} /> 
        
       
        <Route path='/login' element={<Login />} />
        <Route path='/faq' element={<FaqPage />} />
        <Route path='/warranty-tips' element={<WarrantyTips />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/car-warranty' exact element={<CarWarranty />} />
        <Route path='/van-warranty' exact element={<VanWarranty />} />
        <Route path='/van-extended-warranty' exact element={<UsedVanWarranty />} />
        <Route path='/van-cover-levels' exact element={<VanPlanLevels />} />
        <Route path='/car-cover-levels' exact element={<CarPlanLevels />} />
        <Route path='/car-extended-warranty' exact element={<UsedCarWarranty />} />
        <Route path='/renewal' exact element={<WarrantyRenewal />} />
        <Route path='/how-we-operate' exact element={<HowWeOperate />} />
        <Route path='/privacy-policy' exact element={<PrivacyPolicy />} />
        <Route path='/about-us' exact element={<AboutUs />} />
        <Route path='/blog' exact element={<Blog />} />
        <Route path='/cookie-policy' exact element={<CokkiePolicy />} />
        <Route path='/failed-payments' exact element={<FailedPayments />} />
        <Route path='/terms-of-service' exact element={<TermsOfService />} />
        


        <Route path='/dashboard/user-dashboard' element={<ProtectedRoute><UserDashboard /></ProtectedRoute>} />
        <Route path='/dashboard/profile' element={<ProtectedRoute><Profile /></ProtectedRoute>} />
        <Route path='/dashboard/transaction-history' element={<ProtectedRoute><TransactionHistory /></ProtectedRoute>} />
        <Route path='/dashboard/purchase-history' element={<ProtectedRoute><PurchaseHistory /></ProtectedRoute>} />
        <Route path='/dashboard/payments' element={<ProtectedRoute><PaymentPage /></ProtectedRoute>} />

        <Route path='*' element={<NotFound/>}/>

      </Routes>

      { (location.pathname.includes('dashboard') || location.pathname.includes('auth')) ? null  : <Footer />}
      
      </>
      

  );
}

export default App;
