import React from 'react'
import { Col } from 'react-bootstrap'

function Title({title,description, descColor='grey', textAlign='text-center', text,note}) {



  return (
    <Col className={`p-md-4 ${textAlign}`}>
    <h1 className='display-6 fw-bold my-3' style={{color:'#004C9A'}}>{title}</h1>
    <h5 className='fw-normal' style={{color:descColor}}>{description}</h5>
    <p>{text}</p>
    <p style={{fontSize:'0.7rem'}}>{note}</p>
    </Col>
  )
}

export default Title