import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'

import VWcross from '../images/VWcross.png'

function CarCover() {
    return (
        <section>
            <Container className='p-md-5'>
                <Row>
                    <Col md={6}>
                        <h1 className='display-5 fw-bold' style={{ color: '#004C9A' }}>Drive confidently everywhere, everyday</h1>
                    </Col>
                </Row>
                <Row>
                    <Col data-aos='fade-right' data-aos-delay='300' data-aos-duration='1000' md={6} className='d-flex align-items-center'>
                        <div>
                            <ul>
                                <li>Highest level of cover </li>
                                <li>Unlimited repairs</li>
                                <li>Uk based customer service team</li>
                                <li>All as standard</li>
                            </ul>
                            <p>We offer the highest level of used car warranty cover as standard for most vehicle types, including performance cars and 4 x 4s. We aim to take away the worry of an unexpected breakdown and the costly repairs that follow, that’s why our plans include 24/7 breakdown cover, car hire and onward travel as standard.
                            </p>
                        </div>
                    </Col>
                    <Col data-aos='fade-left' data-aos-delay='300' data-aos-duration='1000'>
                        <Image  fluid src={VWcross} />
                    </Col>
                </Row>
                <Row data-aos='fade-up' data-aos-delay='200' data-aos-duration='800'>
                    <p>Our Final Arbiter, also ensures that all disputes are handled fairly and unbiased. This is what makes us unique.</p>
                    <p>Our UK based customer advice team is just at the end of the phone ready and waiting to help. With over 23 years industry experience we’ll do all the hard work so you can stay in the driver’s seat.</p>
                </Row>
            </Container>
        </section>
    )
}

export default CarCover