import React from 'react'
import { Button, Card, Container, Table } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import instance from '../../../../Utils/axios';
import { format } from 'date-fns';
import { BsArrowUpRight } from 'react-icons/bs'
import { RiDownload2Fill } from 'react-icons/ri'
import PurchaseDetailsModal from './PurchaseDetailsModal';
import { hideLoading, showLoading } from '../../../../Features/alertSlice';
import { toast } from 'react-hot-toast';
import { setRecentViewed } from '../../../../Features/recentViewedSlice';

function PurchaseTable() {

  const userToken = useSelector((state) => state.auth.userToken)
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [selectedPurchase, setSelectedPurchase] = useState(null);

  const [purchaseData, setPurchaseData] = useState([]);


  


  // const fetchedPurchase = [
  //   {
  //     id: 1,
  //     date: '2023-08-14',
  //     plan: 'Basic Plan',
  //     status: 'Completed',
  //     amount: '$50.00',
  //   },

  // ];

  useEffect(() => {

    const fetchPurchase = async()=>{

      try {
        dispatch(showLoading());
        const response = await instance.get('/api/warranty/my-warranties',{
          headers:{
            Authorization: `${userToken}`,
          },
        });
        dispatch(hideLoading());
        const data=response.data

        const purchaseArray = data?.warranties?.map(warranty => ({
          id:warranty?._id,  
          planName : warranty?.plan,
          regNum: warranty?.vehicleDetails?.reg_num,
          expDate : warranty?.expiry_date,
          date: warranty?.createdAt,
          make: warranty?.vehicleDetails?.make,
          model: warranty?.vehicleDetails?.model,
          status: warranty?.status?.value,
          amount: warranty?.amount,
          doc_url: warranty?.document?.url,
  
       })) || [];

       purchaseArray.sort((a,b)=>new Date(b.date)-new Date(a.date));

       setPurchaseData(purchaseArray);


  console.log(data);

      } catch (error) {
        dispatch(hideLoading());
        console.log(error)
        toast.error(error?.response?.data?.error?.message ||error?.message)
      }




    }

    fetchPurchase();
  }, [userToken]);




  
  const handleShowModal = async (purchaseId) => {
    try {
      dispatch(showLoading())
      const response = await instance.get(`api/warranty/my-warranty/${purchaseId}`, {
        headers: {
          Authorization: userToken,
        },
      });
      dispatch(hideLoading());
      console.log(response)
      const selectedPurchaseData = response?.data?.warranty;
      console.log(selectedPurchaseData)
      setSelectedPurchase(selectedPurchaseData);
      setShowModal(true);
      dispatch(setRecentViewed(selectedPurchaseData));
    } catch (error) {
      dispatch(hideLoading())
      console.log(error);
      toast.error(error?.response?.data?.error?.message || error?.message);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPurchase(null);
  };

  return (
    
    <Container className='py-3'>
      <Card className='rounded-4 p-2 my-4'>
      <Table hover responsive  className='my-3 text-center' >
        <thead>
          <tr>
            <th>S.NO</th>
            <th>DATE</th>
            <th>PLAN</th>
            <th>REG No.</th>
            <th>AMOUNT PAID</th>
            <th>STATUS</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {purchaseData.length > 0? (
          purchaseData.map((purchase,index) => {

            return(
            <tr key={index} className='align-middle'>
              <td>{index +1}</td>
              <td className='fw-bold'>{format(new Date(purchase?.date), 'dd/MM/yy')}</td>
              <td className='fw-bold'>{purchase?.planName}</td>
              <td className=''>{purchase?.regNum}</td>
              <td style={{color:'#FF6600'}}>£{purchase?.amount}</td>
              <td>{purchase?.status}</td>
              <td><Button className='bg-white text-black px-md-3 rounded-pill border text-nowrap' onClick={() => handleShowModal(purchase.id)}>View Details <BsArrowUpRight/></Button></td>
              <td><Button   className='px-md-3 rounded-pill text-white border-0 text-nowrap' style={{backgroundColor:'#FFA500'}} href={purchase.doc_url} disabled={purchase.doc_url?false:true}>Download <RiDownload2Fill size={25}/></Button></td>
            </tr>
           );} )
           ):(<tr>
            <td colSpan='8'>No Purchase History</td>
          </tr>)}
        </tbody>
      </Table>
      </Card>
    


{showModal && selectedPurchase && (
  <PurchaseDetailsModal title='ORDER DETAIL' purchase={selectedPurchase} onClose={handleCloseModal} />
)}


</Container>
  )
}

export default PurchaseTable