import React from 'react'
import { Card, Col, Image } from 'react-bootstrap'

import Active from '../images/Active.png'
import Expired from '../images/Expired.png'
import './DashboardCard.css'

export function RecentViewedCard({title,description,titleColor}) {
  return (
    <Col md={4} className='my-2'>
    <Card className='rounded-4 shadow px-lg-4  border-0  dashboard-card ' style={{height:'100%'}}>
        <Card.Body >
            <Image  src={Expired} />
            <h4 style={{color:titleColor}}>{title}</h4>
            <p style={{color:'gray', fontSize:'0.9rem'}} className='fw-bold'>{description}</p>
        </Card.Body>
     </Card> 
     </Col>  
  )
}

export function ActiveViewed(){
    return(
        <>
        <RecentViewedCard
            img={Active}
            title='Active'
            titleColor='green'
            description='Safe Cover Plan'
        />
        </>
    )
}

export function ExpiredViewed(){
    return(
        <>
        <RecentViewedCard
            img={Expired}
            title='Expired'
            titleColor='red'
            description='Supreme Cover Plan'
        />
        </>
    )
}