import React from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import Plan from './Plan'

import {LuLaptop2, LuSnowflake, LuVolume2} from 'react-icons/lu'
import {FaCarBattery, FaGear, FaOilCan} from 'react-icons/fa6'
import { BiInjection } from 'react-icons/bi'
import { MdOutlineCarRepair, MdSensors } from 'react-icons/md'
import { ImCheckmark, ImKey2 } from 'react-icons/im'
import { BsClipboardCheck, BsShieldCheck } from 'react-icons/bs'


function PlanPackage() {

  const items = [
    { icon: <LuSnowflake className='me-3' />, text: "Air Conditioning Re-Grassing" },
    { icon: <FaCarBattery className='me-3' />, text: "Battery" },
    { icon: <BsClipboardCheck className='me-3' />, text: "Diagnosis" },
    { icon: <FaGear className='me-3' />, text: "Flywheel" },
    { icon: <LuVolume2 className='me-3' />, text: "In Car Entertainment/Sat Nav/Tel" },
    { icon: <BiInjection className='me-3' />, text: "Injectors" },
    { icon: <FaOilCan className='me-3' />, text: "Oil Seals" },
    { icon: <MdOutlineCarRepair className='me-3' />, text: "Parts Replaced In Repairs" },
    { icon: <ImKey2 className='me-3' />, text: "Remote Keys Fobs/Key Cards" },
    { icon: <MdSensors className='me-3' />, text: "Sensors" },
    { icon: <LuLaptop2 className='me-3' />, text: "Software And Software Updates" },
    { icon: <BsShieldCheck className='me-3' />, text: "Wear & Tear" },
  ];
  

  

  return (
    <Row className='my-3'>
        <Col data-aos="flip-right" data-aos-duration='1000' data-aos-delay='500'>
                <Plan
                    title='Safe Cover Plan'
                    tag='For Vehicles of any age and any mileage'
                    description="Our plan encompasses all mechanical and electrical components of your vehicle, meticulously tailored to be the UK's premier level of cover for your vehicle's age and mileage."
                    items={items}  
                    bgColor='#008BFF' 
                    
                />
                </Col>
                
                <Col data-aos="flip-right" data-aos-duration='1000' data-aos-delay='700'>
                <Plan
                    title='Secure Cover Plan'
                    tag='Eligibility of Vehicles up to 15 years of age and 70,000 miles'
                    description="Our plan encompasses all mechanical and electrical components of your vehicle, meticulously tailored to be the UK's premier level of cover for your vehicle's age and mileage."
                    items={items}
                    bgColor='#8CC63E'

                />
                </Col>
                <Col data-aos="flip-right" data-aos-duration='1000' data-aos-delay='900'>
                <Plan
                    title='Supreme Cover Plan'
                    tag='Eligibility of vehicles up to 10 Years and 70,000 miles'
                    description="Our plan encompasses all mechanical and electrical components of your vehicle, meticulously tailored to be the UK's premier level of cover for your vehicle's age and mileage."
                    items={items}
                    bgColor='#FFD700'

                />
                </Col>
    </Row>
  )
}

export default PlanPackage