import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import working from '../images/working.svg';
import { useNavigate } from 'react-router-dom';


function HowItWorksSec() {

    const navigate = useNavigate();


  return (
    <section style={{backgroundColor:'#008BFF'}}> 
    <Container  >
        <Row className='pt-4 pb-0 d-flex  justify-content-center align-items-center'>
        <Col md={8}>

            <Col className='text-white text-center'>
            <h1 className='my-3'>How It Works</h1>
            <p className='fs-5'>It all starts with a phone call to us and we’ll go from there.</p>
            </Col>
           
            

           
                <Image className='my-5 how-it-works' style={{filter: 'drop-shadow(0  0.5rem 1rem rgba(0, 0,0,0.5))'}} data-aos='flip-up' data-aos-delay='300' data-aos-duration='1500' fluid src={working}  />
            </Col>
             
        </Row>
        <Row className='text-center'>
            <Col>
            <Button onClick={()=>navigate('/purchase/vehicle-details')} className='rounded-pill px-5 my-5 border-white border-2' style={{backgroundColor:'#FFA500'}}>Find out more</Button>
            </Col>
        </Row>
    </Container>
    </section>
  )
}

export default HowItWorksSec