import React from 'react'
import TitleBg from '../components/TitleBg'
import bg from '../images/ContactUsBg.png';

function CookiePolicyTitle() {
  return (
    <TitleBg
        title='Cookie Policy'
        bgImg={bg}

    />
  )
}

export default CookiePolicyTitle