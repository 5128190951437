import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { unSetUserDetails } from './userDetailsSlice';
import { unSetVehicleInformation } from './vehicleInformationSlice';
import { unSetSelectedPlan } from './selectedPlanSlice';
import { unSetWarrantyDetails } from './warrantyDetailsSlice';
import { unSetVehicleDetails } from './vehicleDetailsSlice';
import { unSetUserData } from './userDataSlice';
import { unSetRecentViewed } from './recentViewedSlice';
import { useDispatch } from 'react-redux';


// async thunk 
export const checkTokenExpiration = createAsyncThunk(
  'auth/checkTokenExpiration',
  async (_, { getState, dispatch }) => {
    const { auth } = getState();

    if (auth.userToken) {
      const expirationTime = localStorage.getItem('tokenExpiration');
      const currentTime = Date.now();

      if (parseInt(expirationTime, 10) < currentTime) {
        // Token has expired, dispatch logout action
        dispatch(logoutAndClearData());
      }
    }
  }
);

const authSlice = createSlice({
  
  name: 'auth',
  initialState: {
    userToken: localStorage.getItem('userToken'),
  },
  reducers: {
    setUserToken: (state, action) => {
      state.userToken = action.payload;
      // Set token expiration time to 1 day from now
      const expirationTime = Date.now() + 24 * 60 * 60 * 1000; // 1 day in milliseconds
      localStorage.setItem('userToken', action.payload);
      localStorage.setItem('tokenExpiration', expirationTime.toString());
    },
    logOut: (state, action) => {
      
      state.userToken = null;
      localStorage.removeItem('userToken');
      localStorage.removeItem('tokenExpiration');
     
    },
  },
});

export const { setUserToken, logOut } = authSlice.actions;


export const logoutAndClearData = () => (dispatch) => {
  dispatch(logOut());
  dispatch(unSetUserDetails());
  dispatch(unSetVehicleInformation());
  dispatch(unSetSelectedPlan());
  dispatch(unSetWarrantyDetails());
  dispatch(unSetVehicleDetails());
  dispatch(unSetUserData());
  dispatch(unSetRecentViewed());
  localStorage.clear();
};

export default authSlice.reducer;
