import React from 'react'
import TitleBg from '../components/TitleBg'
import ExtendedWarrantyBg from '../images/ExtendedWarrantyBg.png';

function UsedVanTitle() {
  return (
    <>
    <TitleBg 
    title='Used Van Warranty explained '
    description="Before we start, let’s get back to basics. What exactly is a used van warranty? We understand that financial decisions like these require some careful consideration so you can make a decision with confidence. Here's everything you need to know about van warranties."
    bgImg={ExtendedWarrantyBg}
    />
    </>
  )
}

export default UsedVanTitle