import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Title from '../components/Title'
import PlanPackage from '../components/PlanPackage'

function Coverlevels() {

    const containerStyle={
        backgroundColor:'#D1D5DB4C',
        borderTopLeftRadius:'80px',
        borderTopRightRadius:'80px',
    }

  return (
    <section style={containerStyle}>
        <Container className='py-5'>
            <Row>
                
                <Title
                 title='What are the different cover levels?'
                 description="Once you're ideal plan has been selected based on your vehicle's age, make and mileage you can choose additional elements to personalize your cover to suit your needs and budget."
                 descColor='#004C9A'
                 />              
                
            </Row>
            <Row>
                <Col>
                 <PlanPackage />
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default Coverlevels