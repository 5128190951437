import React from "react";
import Title from "../components/Title";
import { Col, Container, Image, Row } from "react-bootstrap";
import BlueQA from "../components/BlueQA";

function BlogSec() {
  const blogData = [
    {
      title: "Super Warranty: Your Vehicle's Trusted Guardian",
      img: "/images/blog/1.jpg",
      text: "Your car isn't just a mode of transportation; it's a reliable companion on your life's journey. From daily commutes to unforgettable road trips, your vehicle plays a pivotal role. That's why it's crucial to ensure its longevity and reliability. At Super Warranty, we understand your commitment to your vehicle's well-being, which is why we offer three comprehensive coverage plans: Safe, Secure, and Supreme. Let's delve into these plans, illustrated with images to help you make the right choice for your beloved ride.",
    },
    {
      title: "Safe - The All-Encompassing Shield",
      img: "/images/blog/2.jpg",
      text:"No matter your car's age or mileage, the Safe plan has you covered. It's the perfect choice for those who appreciate simplicity and comprehensive coverage. Whether you're driving a brand-new car or a trusted old friend, this plan offers a safety net against unexpected breakdowns."
    },
    {
      title: "Secure - Tailored for the Seasoned Traveller",
      img: "/images/blog/3.jpg",
      text:"If your vehicle has been with you through many miles and years, the Secure plan might be your ideal choice. With coverage extending up to 15 years and 100,000 miles, it's designed to address the needs of vehicles with a bit more history. This plan understands the wear and tear that comes with age and mileage."
    },
    {
      title: "Supreme - The Ultimate Protection",
      img: "/images/blog/4.jpg",
      text:"For those who demand nothing but the best for their slightly older vehicles, the Supreme plan offers comprehensive coverage. It's designed for cars within 10 years and 70,000 miles, providing a robust safety net against unexpected repair costs. When you choose the Supreme plan, you're choosing the ultimate protection for your trusted companion."
    },
    {
      title: "Making Your Choice – Illustrated",
      img: "/images/blog/5.jpg",
      text:"Selecting the right plan for your vehicle is crucial, and Super Warranty makes it easy. Consider your vehicle's age and mileage to determine which plan aligns with your needs. Review your long-term plans for your vehicle – are you planning to keep it for many more years or just for a while? Also, keep your budget in mind, but don't underestimate the peace of mind that comes with comprehensive coverage."
    },
    {
      title: "",
      img: "/images/blog/6.jpg",
      text:"If you're unsure which plan is right for you, don't hesitate to consult with our knowledgeable customer support team. They can provide expert guidance and help you make an informed decision, ensuring that your vehicle remains protected throughout its journey."
    },
    {
      title: "",
      img: "/images/blog/7.jpg",
      text:"Super Warranty is your vehicle's trusted guardian, offering a range of coverage options to fit every car owner's unique needs. Whether you're driving a new car or an older one, there's a plan tailored to meet your specific requirements. Choose with confidence and drive knowing that Super Warranty has your back. Your vehicle's journey is about to get a whole lot smoother!"
    },
    {
      title: "Why Vehicle Warranties Are a Must-Have",
      img: "/images/blog_2/1.jpg",
      text:"When it comes to safeguarding your automotive investment, a vehicle warranty is a must-have. Your car or van is more than just a mode of transportation; it's an essential part of your daily life. Here's why having a vehicle warranty from Super Warranty Car Company is a wise decision."
    },
    {
      title: "Peace of Mind on the Road",
      img: "/images/blog_2/2.jpg",
      text:"Picture this: You're on a road trip with your family, miles away from home, when suddenly, your car breaks down. Without a warranty, this situation can quickly turn into a financial nightmare. With our vehicle warranty, you can hit the road with confidence, knowing that unexpected repair costs are covered."
    },
    {
      title: "Protection for Your Investment",
      img: "/images/blog_2/3.jpg",
      text:"Buying a car or van is a significant investment. Over time, wear and tear are inevitable, and repairs can be costly. Our warranty plans, including Safe, Secure, and Supreme, ensure that your investment is protected. Whether your vehicle is brand new or experienced, we have a plan to fit your needs."
    },
    {
      title: "Tailored Coverage",
      img: "/images/blog_2/4.jpg",
      text:<span>We understand that every vehicle is unique, which is why we offer three levels of coverage. The "Safe" plan covers vehicles of any age and mileage. "Secure" is designed for vehicles up to 15 years old or with less than 100,000 miles. "Supreme" is for newer vehicles up to 10 years old and under 70,000 miles. You choose the plan that aligns with your vehicle's age and mileage.</span>
    },
    {
      title: "Accessible Repairs",
      img: "/images/blog_2/5.jpg",
      text:"With our warranty, getting your vehicle repaired is easy. We have a network of trusted mechanics and service centres ready to assist you. We cover a wide range of repairs, ensuring that you're back on the road quickly and without hassle."
    },
    {
      title: "Contact Us Today",
      img: "/images/blog_2/6.jpg",
      text:"Don't wait until the unexpected happens. Contact Super Warranty Car Company today at 0333 344 3774 or email us at super.warranty@gmail.com to learn more about our vehicle warranty plans and start protecting your investment."
    },
    {
      title: "Ensuring Business Continuity",
      img: "/images/blog_2/7.jpg",
      text:"Imagine the consequences of a delivery van breaking down in the middle of a critical delivery schedule. With our Van Warranty plans, you can ensure that your business remains operational. We offer coverage for vans of various ages and mileage, so your workhorses are always protected."
    },
    
  ];
  return (
    <>
      <section>
        <Container className=" px-5">
          {blogData.map((blog, index) => (
            <div className="my-5">
              <Row className="d-flex justify-content-center">
                <Col md={10}>
              <BlueQA title={blog.title} />
              </Col>
              </Row>
              <Row className="d-flex justify-content-center">
                <Col md={6}>
                  <Image
                    fluid
                    style={{ width: "100%" }}
                    src={blog.img}
                    alt="img"
                    className="rounded-3"
                  />
                </Col>
              </Row>
              <Row className="my-3 d-flex justify-content-center">
                <Col md={10} className="">
                  <p className="fs-5">{blog.text}</p>
                </Col>
              </Row>
            </div>
          ))}

      
        </Container>
      </section>
    </>
  );
}

export default BlogSec;
