import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

function TitleBg({ title, description, bgImg }) {

    const containerStyle = {
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${bgImg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        
        borderBottomLeftRadius: '50px',
        borderBottomRightRadius: '50px'
        
    }

    return (
        <section style={containerStyle}>
            <Container >
                <Row >
                    <Col md={10} className='my-5 p-5'>
                        <h1 className='display-5 fw-bold my-4 text-white'>{title}</h1>
                        <p className='text-white fs-5' style={{filter:'contrast(0.8)'}}>{description}</p>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default TitleBg