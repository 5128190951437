import React, { useState } from 'react'
import { Col, Container, NavDropdown, Row } from 'react-bootstrap'
import NavbarContactUs from './NavbarContactUs'
import { Link } from 'react-router-dom'

function NavdropExistingCust() {

  const [showDropdown,setShowDropdown]= useState(false);

  const handleSelect =()=>{
    setShowDropdown(false);
  };

  const handleDropdownToggle = ()=>{
    setShowDropdown(!showDropdown)
  };


  return (
    <NavDropdown  show={showDropdown} onSelect={handleSelect} onToggle={handleDropdownToggle} className='px-2  main-dropdown'  title="Existing Customer " drop='drop-centered' >

      <Container style={{ overflowY: 'auto', height: '50vh', WebkitOverflowScrolling: 'touch' }} >
        <Row className='my-md-5 '>
          <Col md={5} className='p-md-4 p-2'>

            <Row className='mt-1'>

              <Col md={6}>
                <h5 style={{ color: '#FFA500' }}>What is a Warranty</h5>
                <p><Link onClick={handleSelect} to={'/renewal'} style={{ textDecoration: 'none' }}>Renew your warranty</Link></p>
                <p><Link onClick={handleSelect} to={'/warranty-tips'} style={{ textDecoration: 'none' }}>Car warranty tips</Link></p>

              </Col>
              <Col>
                <h5 style={{ color: '#FFA500' }}>Useful Links</h5>
                <p><Link onClick={handleSelect} to={'/van-warranty'} style={{ textDecoration: 'none' }}>Van warranty explained</Link></p>
                <p><Link onClick={handleSelect} to={'/van-cover-levels'} style={{ textDecoration: 'none' }}>Van warranty cover levels</Link></p>
                <p><Link onClick={handleSelect} to={'/faq'} style={{ textDecoration: 'none' }}>Warranty FAQ's</Link></p>

              </Col>
            </Row>
            {/* <br />
            <br />
            
            <div>
            <hr />
              <h5 className='my-3 mt-5' style={{ color: '#FFA500' }}>Not Bought Your Car yet</h5>
              <p>Book a vehicle inspection</p>
            </div> */}

          </Col>
          <Col className="p-md-4 p-2 border-start">
            <NavbarContactUs />
          </Col>
        </Row>

      </Container>

    </NavDropdown>
  )
}

export default NavdropExistingCust