import React from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import regBg from './images/regBg.png'
import loginBg from './images/loginBg.png'
import { useLocation } from 'react-router-dom'
import ScrollToTop from '../../Template/components/ScrollToTop';


function RegTemplate({children}) {

  const location = useLocation();

  const bg = location.pathname === '/auth/reg-as-guest'?regBg:loginBg

  return (
    <section style={{ height: '100%', width: '100%',overflowX:'hidden' }}>
      <ScrollToTop/>
    <Row style={{minHeight:'100vh', height: '100%' }}>
        <Col  md={6} className='p-md-5 mx-md-5 p-4  d-flex justify-content-center '>
                

        <Row className=' d-flex justify-content-center '>
                <Col   md={8} >

                 {children}   

               </Col>
               </Row> 
            
        </Col>
        <Col style={{backgroundImage:`url(${bg})`}}>
           
        </Col>
    </Row>

</section>
  )
}

export default RegTemplate