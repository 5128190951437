import React from 'react'
import DashboardImgBg from './DashboardImgBg'

import bgImg from './images/PurchaseBg.png'
import PurchaseTable from './components/PurchaseTable'
import ScrollToTop from '../ScrollToTop'

function PurchaseHistory() {
  return (
    <section style={{backgroundColor:'lightgrey',minHeight:'100vh'}}>
      <ScrollToTop/>
      <DashboardImgBg title="Purchase History" bgImg={bgImg} />
      <PurchaseTable />
    </section>
  )
}

export default PurchaseHistory