import { createSlice } from "@reduxjs/toolkit";

const warrantyDetails = localStorage.getItem('warrantyDetails')?JSON.parse(localStorage.getItem('warrantyDetails')):''

//planStartDate:''

const warrantyDetailsSlice = createSlice({
    name:'warrantyDetails',
    initialState:{ warrantyDetails },
    reducers:{
        setWarrantyDetails:(state,action)=>{
            state.warrantyDetails = action.payload;
            localStorage.setItem('warrantyDetails',JSON.stringify(action.payload));
        },
        unSetWarrantyDetails:(state, action)=>{
            state.warrantyDetails = null;
            localStorage.removeItem('warrantyDetails')
        },
    },
});


export const {setWarrantyDetails, unSetWarrantyDetails} = warrantyDetailsSlice.actions;
export default warrantyDetailsSlice.reducer;