import React from "react";
import { Card, Table } from "react-bootstrap";
import { useSelector } from "react-redux";

function RateCard() {
  const { selectedPlan } = useSelector((state) => state.selectedPlan);
  const { vehicleDetails } = useSelector((state) => state.vehicleDetails);

  console.log({ selectedPlan });
  const { claimLimit, month, price, cover } = selectedPlan;
  

  const planItems = {
    safe: {
      includedItems: [],
      excludedItems: [
        "Air condition",
        "Battery",
        "Diagnosis",
        "Flywheel",
        "In Car Entertainment/Sat Nav/Tel",
        "Injectors",
        "Oil Seals",
        "Parts Replaced In Repairs",
        "Remote Key Fobs/Key Cards",
        "Sensors",
        "Software And Software Updates",
        "Wear & Tear",
      ],
    },
    secure: {
      includedItems: [
        "Air condition",
        "Diagnosis",
        "Flywheel",
        "In Car Entertainment/Sat Nav/Tel",
        "Sensors",
      ],
      excludedItems: [
        "Battery",
        "Injectors",
        "Oil Seals",
        "Parts Replaced In Repairs",
        "Remote Key Fobs/Key Cards",
        "Software And Software Updates",
        "Wear & Tear",
      ],
    },
    supreme: {
      includedItems: [
        "Air condition",
        "Battery",
        "Diagnosis",
        "Flywheel",
        "In Car Entertainment/Sat Nav/Tel",
        "Injectors",
        "Oil Seals",
        "Parts Replaced In Repairs",
        "Remote Key Fobs/Key Cards",
        "Sensors",
        "Software And Software Updates",
        "Wear & Tear",
      ],
      excludedItems: [],
    },
  };

  const includedItems = planItems[cover]?.includedItems || [];
  const excludedItems = planItems[cover]?.excludedItems || [];

  return (
    
      <Card className=" rounded-5 mt-2 p-md-4 p-2 d-flex " data-aos="flip-left" data-aos-delay='300' data-aos-duration='1000' style={{overflowY:'hidden'}}>
        <div>
        <h4 style={{ color: "#004C9A" }} className="ms-1">Order Summary</h4>

        <Table responsive borderless style={{ fontSize: "0.9rem" }} className="my-2">
          <tbody>
            <tr>
              <td style={{ color: "#004C9A" }}>
                <h6 style={{ textTransform: "uppercase" }} className="fw-bold">
                  {cover} COVER PLAN
                </h6>
                <p>{month} Months</p>
              </td>
              <td style={{ color: "#008BFF" }} className="text-end">
                <h6>£ {price}</h6>
              </td>
            </tr>
            {includedItems.map((item, index) => (
              <tr key={index}>
                <td className="">{item}</td>
                <td className="fw-bold text-muted text-end">Included</td>
              </tr>
            ))}
            {excludedItems.map((item, index) => (
              <tr key={index}>
                <td>{item}</td>
                <td className="fw-bold text-muted text-end">Excluded</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td ><h6 className="fw-bold mt-3">Vehicle Details</h6></td>
              <td></td>
            </tr>

            <tr>
              <td >Make</td>
              <td className="fw-bold text-end">{vehicleDetails?.make}</td>
            </tr>
            <tr>
              <td>Model</td>
              <td className="fw-bold text-end">{vehicleDetails?.model}</td>
            </tr>
            <tr>
              <td>Mileage</td>
              <td className="fw-bold text-end">{vehicleDetails?.milage}</td>
            </tr>
          </tfoot>
        </Table>
        </div>
      </Card>
    
  );
}

export default RateCard;
