import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IoCall } from 'react-icons/io5'
import { AiFillMail } from 'react-icons/ai'
function NavbarContactUs() {
  return (
    <>
      <Row>
        <Col>
          <h5 style={{ color: "#FFA500" }} className="">Need Help?</h5>
          <p className="mt-0 mb-5">Our warranty experts are here to help!</p>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <h6 style={{ color: "#FFA500" }}>Sales</h6>
          <p>Mon-Fri: 8:00am-5:00pm</p>
        </Col>
        <Col md={3}>
          <h6 style={{ color: "#FFA500" }}>Repairs</h6>
          <p>Mon-Fri: 9:00am-7:00pm</p>
        </Col>
        <Col>
          <p className="mb-0" style={{ color: "#FFA500" }}>
          <IoCall size={25}/> Call us
          </p>
          <a href="#" className="fs-6">
            0333 344 3774
          </a>
        </Col>
        <Col>
          <p className="mb-0" style={{ color: "#FFA500" }}>
           <AiFillMail size={25}/> E-mail us
          </p>
          <a href="#" className="fs-6">
          superwarrantyuk@gmail.com
          </a>
        </Col>
      </Row>
    </>
  );
}

export default NavbarContactUs;
