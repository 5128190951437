import React from 'react'
import { Button } from 'react-bootstrap'

function ResetButton({onClick}) {
  return (
    <>
    <Button variant='transparent' size='lg' onClick={onClick} type='reset' className='my-2 border-2 rounded-pill' style={{color:'#FF0000',borderColor:'#FF0000',width:'100%'}}>Reset</Button>
    </>
  )
}

export default ResetButton