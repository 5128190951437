import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function CTAButton({btnText,btnLink}) {
  return (
    <>
    <Button  variant='transparent' className='my-2 border-2 rounded-pill' style={{color:'#008BFF',borderColor:'#008BFF',width:'100%'}}><Link style={{textDecoration:'none'}} to={btnLink}>{btnText}</Link></Button>
    </>
  )
}

export default CTAButton