import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import carBg from "../images/CARbg.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaStar } from "react-icons/fa6";
import { FaStarHalfAlt } from "react-icons/fa";

function HomeTitleBgImg() {
  const navigate = useNavigate();
  const location = useLocation();

  const containerStyle = {
    height: "100vh",
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0)), url(${carBg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundAttachment: "fixed",
  };

  return (
    <section style={containerStyle} className=" d-flex align-items-center">
      <Container className="px-4">
        <Row>
          <Col
            md={8}
            lg={6}
            sm={12}
            className=""
            data-aos="fade-right"
            data-aos-delay="800"
            data-aos-duration="1000"
          >
            <div
              className="px-4 my-4"
              style={{ borderLeft: "4px solid #FFA500" }}
            >
              <h1 className="display-3 text-white fw-bold">
                {location.pathname == "/car-warranty" ? (
                  <span>
                    Find The Perfect{" "}
                    <span
                      style={{ color: "#008BFF", background: "#FFA500" }}
                      className="px-2"
                    >
                      Warranty
                    </span>{" "}
                    For Your Car.
                  </span>
                ) : location.pathname == "/van-warranty" ? (
                  <span>
                    Find The Perfect{" "}
                    <span
                      style={{ color: "#008BFF", background: "#FFA500" }}
                      className="px-2"
                    >
                      Warranty
                    </span>{" "}
                    For Your Van.
                  </span>
                ) : (
                  <span>
                    Discover The Ideal{" "}
                    <span
                      style={{ color: "#008BFF", background: "#FFA500" }}
                      className="px-2"
                    >
                      Warranty
                    </span>{" "}
                    For Your Vehicle.
                  </span>
                )}
              </h1>
              <p className="text-white">
                Protect Your Ride, Drive With Confidence
              </p>
            </div>
            <p className="text-white mt-5">
              Our warranty undergoes regular reviews to ensure it remains the
              market's finest, offering unbeatable coverage and value.
            </p>

            <div>
              <Button
                onClick={() => navigate("/contact-us")}
                variant="transparent"
                className="border-white border-1 fw-bold rounded-pill text-white text-nowrap px-5 m-2 mx-md-4 speak-to-exp-btn main-btn"
              >
                Speak to an expert
              </Button>
              <Button
                onClick={() => navigate("/purchase/vehicle-details")}
                variant="primary"
                className="border-white fw-bold border-1 rounded-pill text-white text-nowrap px-5 m-2 mx-md-4 main-btn"
                style={{ backgroundColor: "#008BFF" }}
              >
                Find Your Warranty
              </Button>
            </div>
            <p className="text-white">
              <p className="fs-6 mt-2">
                
                Our customers say
                <span className=" ms-2 fw-bold" style={{ fontSize: "1.1rem",letterSpacing:'1px' }}>
                  Excellent
                </span>
                <span
                  className=" mx-2 "
                  style={{ color: "gold", position: "relative", top: "-2px" }}
                >
                  <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStarHalfAlt />
                </span>
                4.4 out of 5 based on 6,074 reviews.
              </p>
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default HomeTitleBgImg;
