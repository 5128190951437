import React from 'react'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import './Plan.css'
import { useNavigate } from 'react-router-dom';

function Plan({title,tag,description,bgColor, items, readMoreLink='#'}) {


  const navigate = useNavigate();
  // const content = (
  //   <ul className="included-list list-unstyled text-start mb-4">
  //     {items.map((item, index) => (
  //       <li className='my-1' key={index}>
          
  //          <Row >
  //          <Col sm={1}>
  //          {item.icon}
  //          </Col>     
  //           <Col className=''>
  //           {item.text}
  //           </Col>
  //          </Row>         
         
  //       </li>
  //     ))}
  //   </ul>
  // );

  const content = (
          <Table borderless >
            <tbody>
                 {items.map((item,index)=>(

                  <tr ><td style={{backgroundColor:'transparent ',color:'white'}} className='px-0'>{item.icon}</td><td style={{backgroundColor:'transparent ',color:'white'}}>{item.text}</td></tr>
                 ))}
            </tbody>
          </Table>
  ) 

  return (
    <Card  className='p-4 m-3 shadow text-white border-0 rounded-4 plan-card' style={{backgroundColor:bgColor}}>
        <Card.Body>
        <Row>
        <Col md={9}>
        <Card.Title className='fs-1  fw-normal'>{title}</Card.Title>
        <Card.Text className=' my-4 fw-normal '>{tag}</Card.Text>
        </Col>
        </Row>
        
            <Card.Text className='fw-normal' style={{fontSize:'0.9rem'}}>{description}</Card.Text>
            {content}
        </Card.Body>
        <div className='text-center'>
        <Button  onClick={()=>navigate('/purchase/vehicle-details')} variant='transparent' className='px-5 my-3 rounded-pill text-white plan-btn' style={{border:'2px solid white'}}>Start a quote</Button>
        <br />
        {/* <a className='text-white ' style={{textDecoration:'none'}}>Read More</a> */}

        </div>
    </Card>
  )
}

export default Plan