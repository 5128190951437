import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Form, Row } from 'react-bootstrap'
import FormTitle from '../../Template/components/LoginComponents/FormTitle'
import FormField from '../../Template/components/LoginComponents/FormField'
import ContinueBtn from '../../Template/components/LoginComponents/ContinueBtn'
import ResetButton from '../../Template/components/LoginComponents/ResetButton'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { setVehicleDetails, unSetVehicleDetails } from '../../Features/vehicleDetailsSlice';

import RegProgress from '../../Template/components/LoginComponents/RegProgress'
import { hideLoading, showLoading } from '../../Features/alertSlice'

function Details() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { vehicleDetails } = useSelector((state) => state.vehicleDetails);
    const [vehicleData, setVehicleData] = useState({ ...vehicleDetails });

    

    const [error, setError] = useState('');
  



    useEffect(() => {
        window.scrollTo(0,0);
        
        const fetchVehicleData = async () => {
            const apiUrl = `https://uk1.ukvehicledata.co.uk/api/datapackage/VehicleData?v=2&api_nullitems=1&auth_apikey=79879730-5b18-4dcc-98c8-0a8e41045237&user_tag=&key_VRM=${vehicleDetails?.regNumber}`;


            try {
                dispatch(showLoading())
                const response = await axios.get(apiUrl);
                const data = response.data;
                console.log("API response: ", response);
                dispatch(hideLoading())
                if (data.Response.StatusCode === 'Success') {
                    
                    const Dvla = data?.Response?.DataItems?.ClassificationDetails?.Dvla;
                    const SmmtDetails = data?.Response?.DataItems?.SmmtDetails;
                    const Power = data?.Response?.DataItems?.TechnicalDetails?.Performance?.Power
                    const VehicleReg = data?.Response?.DataItems?.VehicleRegistration

                    const originalDate = new Date(VehicleReg.DateFirstRegisteredUk);

                    setVehicleData({
                        ...vehicleData,
                        milage: vehicleData?.milage,
                        make: Dvla?.Make,
                        model: Dvla?.Model,
                        fuelType: SmmtDetails?.FuelType,
                        driveType: SmmtDetails?.DriveType,
                        engineSize: SmmtDetails?.EngineCapacity,
                        bhp: Power?.Bhp,
                        registeredDate: originalDate?.toISOString().split('T')[0]
                    });

                    return;
                }

                setError("We couldn't fetch your vehicle details. Please enter them manually:");

            } catch (error) {
                dispatch(hideLoading());
                console.error('Error fetching vehicle data: ', error);
                setError("We couldn't fetch your vehicle details. Please enter them manually:");
                setVehicleData(null);
            }
        };

        fetchVehicleData();

    }, [dispatch, vehicleDetails?.regNumber]);



    const handleVehicleInput = (e) => {
        setVehicleData({ ...vehicleData, [e.target.name]: e.target.value });
   
    }

   


    const handleContinue = (event) => {
        event.preventDefault();

        console.log(vehicleData);
     

      //  dispatch(setUserDetails(userData));
        dispatch(setVehicleDetails(vehicleData));


        navigate('/purchase/cover')
       
    };

  const handleReset = ()=>{
    dispatch(unSetVehicleDetails());
    navigate('/purchase/vehicle-details');

  }


    return (
        <>
            
            <section>
                <Container className='p-3'>
                    <RegProgress step='step2' />
                    <Row>
                        <Col md={12}>
                            <Card className='border px-4 py-2 rounded-5'>





                                <Form className='px-md-5 fw-bold' style={{ color: '#004C9A' }} onSubmit={handleContinue}>
                                    <FormTitle
                                        title='Vehicle details'
                                        alertMsg={error}
                                    />
                                    <Row>
                                        <Col md={6}>
                                            <FormField
                                                label='Make'
                                                type='text'
                                                info='Make'
                                                value={vehicleData?.make}
                                                name='make'

                                                onChange={handleVehicleInput}
                                            />
                                        </Col>
                                        <Col>
                                            <FormField
                                                label='Fuel Type'
                                                info='Type of fuel'
                                                type='text'
                                                value={vehicleData?.fuelType}
                                                name='fuelType'
                                                onChange={handleVehicleInput}
                                            />
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormField
                                                label='Model'
                                                type='text'
                                                info='Model'
                                                value={vehicleData?.model}
                                                name='model'
                                                onChange={handleVehicleInput}
                                            />
                                        </Col>
                                        <Col>
                                            <FormField
                                                label='Registered Date'
                                                info='Date of 1st registration'
                                                type='date'
                                                value={vehicleData?.registeredDate}
                                                name='registeredDate'
                                                onChange={handleVehicleInput}
                                            />
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormField
                                                label='Engine Size'
                                                type='number'
                                                info='Size of Engine'
                                                note='Approximate'
                                                value={vehicleData?.engineSize}
                                                name='engineSize'
                                                onChange={handleVehicleInput}
                                            />
                                        </Col>
                                        <Col>
                                            <FormField
                                                label='Mileage'
                                                info='Mileage'
                                                type='number'
                                                note='Approximate'
                                                value={vehicleData?.milage}
                                                name='milage'
                                                onChange={handleVehicleInput}
                                            />
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <FormField
                                                label='Drive Type'
                                                type='text'
                                                info='Type of drive'
                                                value={vehicleData?.driveType}
                                                name='driveType'
                                                onChange={handleVehicleInput}
                                            />
                                        </Col>
                                        <Col>
                                            <FormField
                                                label='BHP'
                                                info='Brake Horse Power'
                                                type='number'
                                                note='Approximate'
                                                value={vehicleData?.bhp}
                                                name='bhp'
                                                onChange={handleVehicleInput}
                                            />
                                        </Col>

                                    </Row>


                                    

                                    <Row className='d-flex justify-content-center'>
                                        <Col md={6}>
                                            <ContinueBtn />
                                            <ResetButton onClick={handleReset}/>
                                        </Col>
                                    </Row>


                                </Form>







                            </Card>

                        </Col>
                    </Row>
                </Container>
            </section >
        </>
    )
}

export default Details