import React from 'react';
import Card from 'react-bootstrap/Card';
import './FeaturesCard.css';
import { Button, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function FeaturesCard({ title, desc, bgImg,bgImgBack }) {
    const navigate = useNavigate();
  return (
    <Col md={4} className='p-3'>
    <div className="flip-card ">
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <Card className="text-white shadow feature-card rounded-0" style={{ backgroundImage: `radial-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${bgImg})`, backgroundSize: 'cover', height: '300px'}}>
            <Card.Title className='fs-3 fw-bold text-center' >{title}</Card.Title>
          </Card>
        </div>
        <div className="flip-card-back ">
          <Card className="text-white shadow feature-card rounded-0 p-md-3" style={{ backgroundImage: `radial-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${bgImgBack})`, backgroundSize: 'cover', height: '300px' }}>
            <Card.Text className='fs-5 text-center'>{desc}</Card.Text>
            <Button onClick={()=>navigate('/purchase/vehicle-details')} variant='transparent' className='rounded-pill text-white border-white border-2'>Get a Quote</Button>
          </Card>
        </div>
      </div>
    </div>
    </Col>
  );
}

export default FeaturesCard;
