import React from 'react'

import VanPlanLevelTitle from '../Template/sections/VanPlanLevelTitle'
import PlanLevelsSec from '../Template/sections/PlanLevelsSec'
import ScrollToTop from '../Template/components/ScrollToTop'


function VanPlanLevels() {
  return (
    <>
    <ScrollToTop/>
    <VanPlanLevelTitle/>
    <PlanLevelsSec />
   
    </>
  )
}

export default VanPlanLevels