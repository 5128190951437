import { createSlice } from "@reduxjs/toolkit";

const vehicleInfo = localStorage.getItem('vehicleInfo') ? JSON.parse(localStorage.getItem('vehicleInfo')) : '';

console.log(vehicleInfo)

const vehicleInformationSlice = createSlice({
    name: 'vehicleInfo',
    initialState: { vehicleInfo },
    reducers: {
        setVehicleInformation: (state, action) => {
            console.log({state, action});
            state.vehicleInfo = action.payload;
            localStorage.setItem('vehicleInfo', JSON.stringify(action.payload))
        },
        unSetVehicleInformation: (state, action) => {
            state.vehicleInfo = null;
            localStorage.removeItem('vehicleInfo')
        }
    },
});


export const { setVehicleInformation, unSetVehicleInformation } = vehicleInformationSlice.actions;
export default vehicleInformationSlice.reducer;