import React, { useState } from "react";
import LoginMethodTemplate from "./LoginMethodTemplate";
import LoginFormField from "../../Template/components/LoginComponents/LoginFormField";
import LoginTitle from "../../Template/components/LoginComponents/LoginTitle";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { hideLoading, showLoading } from "../../Features/alertSlice";
import instance from "../../Utils/axios";


function ForgotPasswordMail() {
  const [btnText, setBtnText]= useState('Submit')
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();


  const handleSubmit = async(e)=>{
    e.preventDefault();
    try {
      dispatch(showLoading());
      const response = await instance.post('/api/user/password/forgot',
        {
          email: email,
        },
      )
      dispatch(hideLoading());
      console.log(response);
      if(response?.status === 200){
        toast.success(response?.data?.message);
        setBtnText('Resend');
      }
      
    } catch (error) {
      dispatch(hideLoading());
      console.log(error);
      toast.error(error?.response?.data?.error?.message || error?.message);
    }
  }
  return (
    
     <LoginMethodTemplate>
        <Container className="p-4">
        <LoginTitle title="Enter Your Email" text="We are here to help you!" />

        <Row>
            <Col>
          <Form  onSubmit={handleSubmit}>
            <LoginFormField
              label="Email"
              placeholder="email@example.com"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />

            <Row>
              <Button
                type="submit"
                className="my-3 rounded-pill text-white "
              >
                {btnText}
              </Button>
            </Row>
          </Form>
          </Col>
        </Row>
      </Container>
    </LoginMethodTemplate>
  );
}

export default ForgotPasswordMail;
