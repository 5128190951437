import React from 'react'
import { Container } from 'react-bootstrap'
import Title from '../components/Title'
import BlueQA from '../components/BlueQA'

function TermsOfServSec() {
    return (
        <section>
            <Container className='px-md-5 py-5'>

                <Title
                    description="Welcome to Super Warranty! We are pleased to have you as a valued customer. Before you proceed with our warranty services, it's essential to understand and agree to our Terms of Service. These terms govern your relationship with Super Warranty and outline your rights and responsibilities as a customer. By using our services, you agree to abide by these terms."
                    textAlign='text-left'
                />

                <BlueQA
                    que='1. Warranty Coverage'
                    ans='Super Warranty offers three distinct cover levels: Safe, Secure, and Supreme. The coverage level you select determines the scope of protection for your vehicle. It is essential to review and understand the specific terms and conditions associated with your chosen coverage plan.'

                />

                <BlueQA
                    que='2. Payment and Billing'
                />
                <p><span>a. Payment Information:</span>You agree to provide accurate and complete payment information when enrolling in a Super Warranty plan. This includes valid payment methods, such as credit cards or bank accounts or paypal.</p>  
                <p><span>b. Payment Authorization:</span>By providing payment information, you authorize Super Warranty to charge the agreed-upon fees for your chosen coverage plan at the specified intervals.</p>  
                <p><span>c. Failed Payments:</span>In the event of a failed payment, Super Warranty will make reasonable attempts to notify you. It is your responsibility to address and rectify any payment issues promptly to avoid a lapse in coverage.</p>  
              
                <BlueQA
                    que='3. Coverage Duration'
                    ans='Your Super Warranty coverage begins on the effective date specified in your plan and continues for the duration of the plan, as indicated in the plan details. It is important to note that the coverage duration may vary depending on the plan level (Safe, Secure, or Supreme).'
                />
                <BlueQA
                    que='4. Claims Process'
                    
                />
                 <p><span>a. Claims Submission: </span>If your vehicle experiences a covered breakdown, you must promptly submit a claim to Super Warranty. Claims can typically be submitted through our website, customer portal, or by contacting our customer support team.</p>  
                 <p><span>b. Claim Approval:</span>Super Warranty will assess the claim based on the terms and conditions of your coverage plan. Approved claims will be processed in accordance with our guidelines.</p>  
                 <p><span>c. Repair Facility:</span>You have the flexibility to choose an authorized repair facility for covered repairs, subject to our approval.</p>  


                <BlueQA
                    que='5. Modifications and Cancellations'
                  
                />
              <p><span>a. Modifications: </span>Super Warranty reserves the right to modify, update, or improve our services and terms. We will notify you of any significant changes.</p>  
              <p><span>b. Cancellation: </span>You have the option to cancel your coverage plan. The terms and conditions for cancellations, including any associated fees, are detailed in your plan.</p>  

                <BlueQA
                    que='6. Privacy and Data'
                    ans='We take your privacy seriously. Your personal information is handled in accordance with our Privacy Policy. By using our services, you consent to the collection and use of your data as described in the Privacy Policy.'
                />
                <BlueQA
                    que='7. Dispute Resolution'
                    ans='Any disputes arising from your use of Super Warranty services will be resolved through arbitration or other appropriate legal processes, as outlined in our Dispute Resolution Policy.'
                />
                <BlueQA
                    que='8. Termination'
                    ans='Super Warranty reserves the right to terminate your coverage plan or access to our services in cases of non-compliance with our terms, failure to make payments, or fraudulent activities.'
                />

            </Container>
        </section>
    )
}

export default TermsOfServSec