import React from 'react'
import TitleBg from '../components/TitleBg'
import bg from '../images/ContactUsBg.png';

function AboutUsTitle() {
  return (
    <TitleBg
        title='About Us'
        bgImg={bg}

    />
  )
}

export default AboutUsTitle