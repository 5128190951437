import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Title from "../Template/components/Title";

function NotFound() {

    const desc = <span> We're sorry, but the page you're looking for doesn't exist or may have been moved. <br/>Please check the URL for any typos or use the navigation menu to find your way back to the site's main content.</span>

  return (
    <section style={{height:'50vh'}}>
      <Container style={{height:'100%'}} >
       
            <Title
                title='Not Found'
                description={desc}
                descColor='#008BFF'
            />
          
      </Container>
    </section>
  );
}

export default NotFound;
