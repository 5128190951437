//vehicleDetailsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const vehicleDetails = localStorage.getItem('vehicleDetails') ? JSON.parse(localStorage.getItem('vehicleDetails')) : ''

//{


    // make: '',
    // model: '',
    // fuelType: '',
    // driveType: '',
    // engineSize: '',
    // bhp: '',
    // milage: '',
    // registeredDate: '',
    // regNumber: ''
    //}


const vehicleDetailsSlice = createSlice({
    name: 'vehicleDetails',
    initialState: { vehicleDetails },
    reducers: {
        setVehicleDetails: (state, action) => {
            console.log({ state, action })
            state.vehicleDetails = action.payload;
            localStorage.setItem('vehicleDetails', JSON.stringify(action.payload))

        },
        unSetVehicleDetails: (state, action) => {
            state.vehicleDetails = null;
            localStorage.removeItem('vehicleDetails');
        }
    },
});




export const { setVehicleDetails, unSetVehicleDetails } = vehicleDetailsSlice.actions;
export default vehicleDetailsSlice.reducer;
