import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Title from '../components/Title'
import BlueQA from '../components/BlueQA'

function TipsSec() {

    

  return (
    <section>
        <Container className='py-4 px-md-5'>
            <Row className=''>
                
                    <Title 
                        title='super warranties come with great  responsibilities '
                        text="At Super Warranty, our team of superhero mechanics isn't just about capes and cool gadgets – they're on a mission to source the absolute best warranty deals tailored to each customer's unique needs. We understand that getting the right coverage is crucial, so our experts work tirelessly to ensure you have the perfect shield for your vehicle. But it doesn't stop there – when the unexpected happens, our superheroes kick into action, processing claims and overseeing repairs to guarantee the highest quality results. With Super Warranty by your side, you can drive with confidence, knowing that not only are your expenses covered, but your car is in the hands of true automotive champions. 🛠️🛡️🦸‍♂️"
                        textAlign='text-left'
                    />
                
            </Row>
            <Row>
                
                <Title
                    title='My Collaboration  with super warranty'
                    text="In the dynamic world of the automotive industry, the paramount importance of used car warranties cannot be overstated. These warranties act as a fortress of tranquility, a robust bulwark against the uncertainties of unforeseen repairs. Within this landscape, Super Warranty emerges as a beacon of exceptionalism, offering a discretionary warranty experience that stands unrivaled. What distinguishes us is our scrupulous assessment of every claim, a personalized process that meticulously evaluates the merits to determine the appropriateness of a repair. This, ladies and gentlemen, is merely a glimpse of the multifaceted tapestry that establishes us as unrivaled leaders within our sphere."
                    textAlign='text-left'
                />
                
            </Row>
            <Row className='mb-5'>
                
                <Title
                    title='Verify that your required car warranty coverage and additional elements are in place.'

                    
                    text="You have a 30-day window to make any modifications you may have overlooked during your car warranty registration, provided that no repairs have been carried out in the interim. "
                    textAlign='text-left'
                />
                
            </Row>
                <div className='mx-2'>
                <BlueQA
                    que='Review and confirm that you possess the necessary car warranty coverage and desired additions.'
                    ans='You have a 30-day window to revise any omissions from your initial car warranty sign-up, as long as no repairs have been conducted during this period.'
                />
                <BlueQA
                    que='Your vehicle maintenance is current, carried out at a VAT registered garage.'
                    ans='Ensuring your car undergoes proper servicing allows our car warranty to safeguard your vehicle.'
                />
                <BlueQA
                    que='Our proficient examiners are on hand to diagnose issues.'
                    ans='In cases of uncertain part failure, we may involve an expert engineer to assess your vehicle. This routine procedure helps eliminate confusion and ensures accurate resolution.'
                />
                <BlueQA
                    que='As soon as you suspect an issue with your vehicle, refrain from driving it immediately!'
                    ans='Continuing to drive might exacerbate the issue, potentially voiding any subsequent repair request you submit.'
                />
                <BlueQA
                    que='Please refrain from conducting any vehicle repairs without obtaining our prior authorization.'
                    ans='Before proceeding with any vehicle repairs, kindly inform us of your intentions so that we can record it. Please note that if you proceed without prior notification, we cannot assure coverage for the required repairs.'
                />
                <BlueQA
                    que='Feel free to reach out to us for any advice or guidance you may need.'
                    ans="Should you have any uncertainty regarding your car warranty or require additional guidance, rest assured we're here to assist. Feel free to call us at 03333443774, send a message, or refer to our Repairs Procedure."
                />
                </div>

        </Container>
    </section>
  )
}

export default TipsSec