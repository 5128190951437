import React from 'react'

import HomeTitleBgImg from '../Template/sections/HomeTitleBgImg'
import HomeInfoSec from '../Template/sections/HomeInfoSec'
import VanCover from '../Template/sections/VanCover'
import HowItWorksSec from '../Template/sections/HowItWorksSec'
import VanSupport from '../Template/sections/VanSupport'
import ScrollToTop from '../Template/components/ScrollToTop'

function VanWarranty() {
  return (
    <>
    <ScrollToTop/>
    <HomeTitleBgImg />
    <HomeInfoSec />
    <VanCover />
    <HowItWorksSec />
    <VanSupport />
   
    </>
  )
}

export default VanWarranty