import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import { BsDownload, BsPen, BsPencil } from "react-icons/bs";
import user from "../images/user.png";
import pend from "../images/pending.png";
import { useDispatch, useSelector } from "react-redux";
import instance from "../../../../Utils/axios";
import { hideLoading, showLoading } from "../../../../Features/alertSlice";
import { toast } from "react-hot-toast";
import { setUserData } from "../../../../Features/userDataSlice";

function ProfileSection() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [picToUpload, setPicToUpload]= useState(null)
  const [picPreview,setPicPreview] = useState(null);
  const profilePicInputRef = useRef(null);
  const [alert, setAlert] = useState("");
  const [alertColor, setAlertColor] = useState("red");
  const [showModal, setShowModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.auth.userToken);
  const {userData} = useSelector((state)=>state.userData);

  //  const storedToken = localStorage.getItem('userToken');
  // console.log(storedToken);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        dispatch(showLoading());
        const response = await instance.get("/api/user/profile", {
          headers: {
            Authorization: `${userToken}`,
          },
        });
        dispatch(hideLoading());
        const userData = response.data;

        setEmail(userData.user.email);
        setFirstName(userData.user.firstname);
        setLastName(userData.user.lastname);
        setPhone(userData.user.mobile_no);
        
        console.log(userData);
        // console.log(userToken)
      } catch (error) {
        dispatch(hideLoading());
        console.log(error);
        toast.error(error?.response?.data?.error?.message || error?.message);
      }
    };

    fetchUserData();
  }, []);

  const handleProfileUpdate = async (event) => {
    event.preventDefault();
    try {
      dispatch(showLoading());

      const response = await instance.put(
        "/api/user/update-profile",

        {
          firstname: firstName,
          lastname: lastName,
          email: email,
          mobile_no: phone,
          
        },
        {
          headers: {
            Authorization: `${userToken}`,
          },
        }
      );
      dispatch(hideLoading());

      if (response.data) {
        toast.success("Profile Updated Successfully");
        return;
      }

      console.log(response);
    } catch (error) {
      dispatch(hideLoading());
      console.log(error);
      toast.error(error?.response?.data?.error?.message || error?.message);
    }
  };

  const handleChangePassword = async (event) => {
    event.preventDefault();
    try {
      dispatch(showLoading());
      const response = await instance.put(
        "/api/user/update-password",

        {
          curPassword: currentPassword,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
        },
        {
          headers: {
            Authorization: `${userToken}`,
          },
        }
      );

      dispatch(hideLoading());

      if (response?.status === 200) {
        setAlert(response?.data?.message);
        setAlertColor("green");
      }

      console.log(response?.data?.message);

      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");

      console.log(response?.status);
    } catch (error) {
      dispatch(hideLoading());
      console.log(error);
      
      setAlert(error?.response?.data?.error?.message || error?.message);
      setAlertColor("red");
    }
  };

  const handleModalOpen = (e) => {
    setShowModal(true);
  };

  const handleModalClose = (e) => {
    setShowModal(false);
  };

  const handleImageChange = (e)=>{
     const selectedImg = e?.target?.files[0];
    setPicToUpload(selectedImg)
    setPicPreview(URL.createObjectURL(selectedImg));
   
  }

  
  const handleImgUpload = async() => {

    try {
        if(!picToUpload){
            return;
        }

        const formData = new FormData();
         formData.append("profile_img", picToUpload);

         dispatch(showLoading());

         const response = await instance.put('/api/user/update-profile/image',formData,
         
         {
            headers:{
                Authorization:`${userToken}`,
                "Content-Type":"multipart/form-data",
            }
         }
         
         )

         console.log("update profile:",response)
         dispatch(setUserData(response?.data?.user));
         dispatch(hideLoading());

         if(response.data){
            toast.success('Profile picture updated successfully');
            handleModalClose();
         }


    } catch (error) {
        dispatch(hideLoading());
        console.log(error);
        toast.error(error?.response?.data?.error?.message || error?.message);

    }
   
  };


  /* const selectedImg = event.target.files[0];
    if (selectedImg) {
      const imgUrl = URL.createObjectURL(selectedImg);
      setPic(imgUrl);
    }*/

  

  return (
    
      <section style={{ backgroundColor: "#D1D5DB4C", minHeight: "100vh" }}>
        <Container className="px-5 py-3">
          <Row>
            <Col lg={2}>
              <div style={{ position: "relative", bottom: "10%" }}>
                <div
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%",
                    overflow: "hidden",
                  }}
                >
                  <Image
                    src={userData?.profile_img || user}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    onError={(e) => console.error("Error loading Img: ", e)}
                  />
                </div>
                

                <Button
                  className="rounded-pill text-white px-3 mt-3 ms-2 "
                  variant=""
                  type="submit"
                  onClick={handleModalOpen}
                  style={{ backgroundColor: "#008BFF" }}
                >
                  <BsPencil /> Edit Profile
                </Button>
              </div>
            </Col>
            <Col lg={10}>
              <Row>
                <h6>
                  <span style={{ color: "#004C9A" }}>Name:</span>
                  <span style={{ color: "#008BFF" }}>
                    {" "}
                    {firstName} {lastName}
                  </span>
                </h6>
                <h6>
                  <span style={{ color: "#004C9A" }}>Email:</span>
                  <span style={{ color: "#008BFF" }}> {email}</span>
                </h6>
              </Row>
              <Row className="my-3">
                <h5 style={{ color: "#004C9A" }} className="mt-5 mb-3">
                  PERSONAL INFORMATION
                </h5>
                <Form onSubmit={handleProfileUpdate}>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3  fw-bold" controlId="">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="First Name"
                          className=""
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3 fw-bold" controlId="">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Last Name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3 fw-bold" controlId="">
                        <Form.Label>Email </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3 fw-bold" controlId="">
                        <Form.Label>Contact Number</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="123456789"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button
                    className="rounded-pill text-white px-3"
                    variant=""
                    type="submit"
                    style={{ backgroundColor: "#008BFF" }}
                  >
                    Save Changes <BsDownload />
                  </Button>
                </Form>
              </Row>

              <Row className="my-3">
                <h5 style={{ color: "#004C9A" }} className="mt-5">
                  PASSWORD CHANGE
                </h5>
                <p style={{ color: alertColor }}>{alert}</p>
                <Form onSubmit={handleChangePassword}>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3  fw-bold" controlId="">
                        <Form.Label>Current Password</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Current password"
                          value={currentPassword}
                          onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3 fw-bold" controlId="">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="New password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3 fw-bold" controlId="">
                        <Form.Label>Confirm New Password</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Confirm new password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col></Col>
                  </Row>
                  <Button
                    className="rounded-pill text-white px-3"
                    variant=""
                    type="submit"
                    style={{ backgroundColor: "#008BFF" }}
                  >
                    Change Password <BsPen />
                  </Button>
                </Form>
              </Row>
            </Col>
          </Row>

          <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Profile Picture</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form className="text-center px-md-4">
          
            <Image
              src={picPreview || (userData?.profile_img || user)}
              alt="User Profile"
              style={{
                width: "150px",
                height: "150px",
                objectFit: "cover",
                borderRadius: "50%",
                marginBottom: "10px",
              }}
            /><br/>
            <Form.Control
            type="file"
              id="profilePicture"
              label="Choose a new profile picture"
              accept="image/*"
              onChange={handleImageChange}
            />
          
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleImgUpload}>Save Changes</Button>
        </Modal.Footer>
      </Modal>

        </Container>
      </section>

     
    
  );
}

export default ProfileSection;
