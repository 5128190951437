import React from "react";
import { Button, Card, Container, Table } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../../../Features/alertSlice";
import instance from "../../../../Utils/axios";
import { format } from "date-fns";
import { RiDownload2Fill } from "react-icons/ri";
import { BsArrowUpRight } from "react-icons/bs";
import TransactionDetailsModal from "./TransactionDetailsModal";
import { setRecentViewed } from "../../../../Features/recentViewedSlice";
import { toast } from "react-hot-toast";
import axios from "axios";

function TransactionTable() {
  const dispatch = useDispatch();
  const [transactions, setTransactions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const userToken = useSelector((state) => state.auth.userToken);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        dispatch(showLoading());
        const response = await instance.get(
          "/api/transaction/my-transactions",
          {
            headers: {
              Authorization: `${userToken}`,
            },
          }
        );
        dispatch(hideLoading());

        const data = response?.data;
        console.log(response)

        const transactionArray = data?.transactions?.map((transaction) => ({
          id: transaction?._id,
          planName: transaction?.plan,
          date: transaction?.createdAt,
          amount: transaction?.amount,
          status: transaction?.status,
          srcId: transaction?.source_id,
        })) || [];

        transactionArray.sort((a, b) => new Date(b.date) - new Date(a.date));

        setTransactions(transactionArray);
      } catch (error) {
        dispatch(hideLoading());
        toast.error(error?.response?.data?.error?.message || error?.message);
        console.log(error);
      }
    };

    fetchTransactions();
  }, [userToken]);

  const handleShowModal = async (transactionId) => {
    try {
      dispatch(showLoading());
      const response = await instance.get(
        `/api/transaction/my-transaction/${transactionId}`,
        {
          headers: {
            Authorization: userToken,
          },
        }
      );
      dispatch(hideLoading());
      const selectedTransactionData = response?.data?.transaction;
      console.log(selectedTransactionData);

      setSelectedTransaction(selectedTransactionData);
      setShowModal(true);
      //   dispatch(setRecentViewed(selectedTransactionData));
    } catch (error) {
      dispatch(hideLoading());
      console.log(error);
      toast.error(error?.response?.data?.error?.message || error?.message);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTransaction(null);
  };


  const handleReceiptDownload = async(id)=>{


        try {
          dispatch(showLoading());
          const response = await instance.get(`/api/download-pdf/receipt/${id}`,{
            headers:{
              Authorization: `${userToken}`
            },
            responseType:'blob'
          });
          dispatch(hideLoading());  
          console.log(response);

          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
      
          // Create a hidden anchor link to trigger the download
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = 'receipt.pdf'; // Set the desired filename
          document.body.appendChild(a);
      
          // Trigger the click event on the anchor link
          a.click();
      
          // Clean up: Revoke the object URL and remove the anchor element
          URL.revokeObjectURL(url);
          document.body.removeChild(a);

        } catch (error) {
          dispatch(hideLoading());
          console.log(error);
          toast.error(error?.response?.data?.error?.message || error?.message)
        }
  }
  return (
    <Container className="py-3">
      <Card className="rounded-4 p-2 my-4">
        <Table hover responsive className="my-3 text-center">
          <thead>
            <tr>
              <th>TRANSACTION ID</th>
              <th>DATE</th>
              <th>PLAN</th>
              <th>STATUS</th>
              <th>AMOUNT</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {transactions.length > 0 ? (
              transactions.map((transaction, index) => {
                return (
                  <tr key={index} className="align-middle">
                    <td>{transaction?.id}</td>
                    <td className="fw-bold">
                      {format(new Date(transaction?.date), "dd/MM/yy")}
                    </td>
                    <td>{transaction?.planName}</td>
                    <td className="fw-bold">{transaction?.status}</td>
                    <td style={{ color: "#FF6600" }}>£{transaction?.amount}</td>
                    <td>
                      <Button
                        className="bg-white text-black px-md-3 rounded-pill border text-nowrap"
                        onClick={() => handleShowModal(transaction?.id)}
                      >
                        View Details <BsArrowUpRight />
                      </Button>
                    </td>
                    <td>
                      <Button onClick={()=>handleReceiptDownload(transaction?.id)}
                        className="px-md-3 rounded-pill text-white border-0 text-nowrap"
                        style={{ backgroundColor: "#FFA500" }}
                      >
                        Download <RiDownload2Fill size={25} />
                      </Button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="7">No Transaction History</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card>

      {showModal && selectedTransaction && (
        <TransactionDetailsModal
          transaction={selectedTransaction}
          onClose={handleCloseModal}
          onClick={handleReceiptDownload}
        />
      )}
    </Container>
  );
}

export default TransactionTable;
