import React from 'react'
import { Container } from 'react-bootstrap'
import PlanPackage from '../components/PlanPackage'
import Title from '../components/Title'

function HomePackages() {
  return (
    <section>
        <Container>
            <Title
                title='Discover the Benefits of Our Warranty Plans'
                description='Once your ideal plan has been selected based on the age of your car, make and mileage, you can choose additional elements to personalize your cover to suit your needs and budget.'
            />
            <PlanPackage/>
        </Container>
    </section>
  )
}

export default HomePackages