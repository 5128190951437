import React, { useState } from 'react'
import LoginMethodTemplate from './LoginMethodTemplate'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import LoginTitle from '../../Template/components/LoginComponents/LoginTitle'
import LoginFormField from '../../Template/components/LoginComponents/LoginFormField'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { hideLoading, showLoading } from '../../Features/alertSlice'
import instance from '../../Utils/axios'
import { useNavigate, useParams } from 'react-router-dom'

function ForgotPasswordNewPassword() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {token}= useParams();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    console.log(token);
    const handleResetPassword = async(e)=>{
      e.preventDefault();
      
      try {
        dispatch(showLoading());
          const response = await instance.put(`/api/user/password/reset/${token}`,{
            password: newPassword,
            confirmPassword: confirmPassword
          })
        dispatch(hideLoading());
          console.log(response);

          if(response?.status === 200){
            toast.success('Password updated, Login Again.');
            navigate('/login');
          }
        
      } catch (error) {
        dispatch(hideLoading());
        console.log(error);
        toast.error(error?.response?.data?.error?.message  || error?.message);
      }
    }


  return (
    <LoginMethodTemplate>
        <Container className="p-4">
        <LoginTitle title="Set New Password" text="Set new and strong password for your account" />

        <Row>
            <Col>
          <Form onSubmit={handleResetPassword} >
            <LoginFormField
              label="New Password"
              placeholder="*********"
              type="password"
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <LoginFormField
              label="Confirm Password"
              placeholder="*********"
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />

            <Row>
              <Button
                type="submit"
                className="my-3 rounded-pill text-white "
              >
                Submit
              </Button>
            </Row>
          </Form>
          </Col>
        </Row>
      </Container>
    </LoginMethodTemplate>
  )
}

export default ForgotPasswordNewPassword