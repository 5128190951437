import React, { useState } from 'react'
import RegTemplate from './RegTemplate'
import LoginTitle from '../../Template/components/LoginComponents/LoginTitle'
import LoginFormField from '../../Template/components/LoginComponents/LoginFormField'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { hideLoading, showLoading } from '../../Features/alertSlice'
import { useDispatch } from 'react-redux'
import toast from 'react-hot-toast';
import instance from '../../Utils/axios'
import { setUserToken } from '../../Features/authSlice'
import ScrollToTop from '../../Template/components/ScrollToTop'

function RegAsGuest() {

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [postcode, setPostcode] = useState('');
    const [city, setCity] = useState('');

    const handleContinue = async (event) => {
        event.preventDefault();

        try {

            dispatch(showLoading());
            const response = await instance.post('/api/user/register', {
               
               addr:{
                address: address,
                city:city,
                postcode:postcode,
               },
                mobile_no:phone,
                email:email,
                firstname:firstName,
                lastname:lastName

            })
            dispatch(hideLoading());
            const token = response?.data?.token;
            dispatch(setUserToken(token));

            

            console.log(response.status)

            if (response.status === 200) {
                toast.success(response.data.message, { duration: 5000 });
                navigate('/purchase/details')
            } 

            console.log(response)
            console.log(token)


        } catch (error) {
            dispatch(hideLoading());
            toast.error(error?.response?.data?.error?.message);
            console.error('Registration error:', error);
           
        }
            
        

        
        

       // navigate('/auth/login-as-guest');
    };



    return (
        <RegTemplate>
            <ScrollToTop/>
           
                <LoginTitle title='Enter Your Details' text='Provide Us With Your Details To Serve You Better' />

                
                        <Row>
                    <Form onSubmit={handleContinue} className='' >
                        <LoginFormField
                            label='First Name'
                            placeholder='Enter your first name'
                            type='text'
                            onChange={(e)=>setFirstName(e.target.value)}
                        />
                        <LoginFormField
                            label='Last Name'
                            placeholder='Enter your last name'
                            type='text'
                            onChange={(e)=>setLastName(e.target.value)}
                        />
                        <LoginFormField
                            label='Email'
                            placeholder='Email'
                            type='email'
                            onChange={(e)=>setEmail(e.target.value)}
                        />
                        <LoginFormField
                            label='Phone Number'
                            placeholder='Enter your number'
                            type='number'
                            onChange={(e)=>setPhone(e.target.value)}
                        />
                        <LoginFormField
                            label='Address'
                            placeholder='Enter your address'
                            type='text'
                            onChange={(e)=>setAddress(e.target.value)}
                        />
                        <LoginFormField
                            label='Postcode'
                            placeholder='Postcode'
                            type='text'
                            onChange={(e)=>setPostcode(e.target.value)}
                        />
                        <LoginFormField
                            label='City'
                            placeholder='City'
                            type='text'
                            onChange={(e)=>setCity(e.target.value)}
                        />


                        <Button type='submit' className='text-white w-100 rounded-pill'>Register As Guest</Button>
                        <Row className="d-flex justify-content-center align-items-center">
                  <Col>
                    <hr />
                  </Col>
                  <Col md={2} className="text-center">OR</Col>
                  <Col>
                    <hr />
                  </Col>
                </Row>
                        <p className='my-4' style={{ color: '#004C9A' }}>Already have an account?<a style={{ textDecoration: 'none', color: '#008BFF' }}><Link to={'/auth/login-as-guest'}>Log In</Link></a></p>
                    </Form>
                </Row>
              
        </RegTemplate>
    )
}

export default RegAsGuest