import React from 'react'
import { Container } from 'react-bootstrap'
import BlueQA from '../components/BlueQA'

function OperationSec() {
  return (
    <section>
        <Container className='py-5 px-md-5'>
            <p>At Super Warranty, our operational framework revolves around providing you with unparalleled peace of mind through our comprehensive coverage plans: Safe, Secure, and Supreme. Our commitment to your protection and satisfaction is at the core of everything we do.</p>
        
        <BlueQA
            que='1. Customized Coverage'
            ans="We understand that each vehicle and owner is unique. Our three cover levels cater to this diversity, ensuring that whether your vehicle is brand new or has stood the test of time, you'll find a plan that fits your needs."
        />
        <BlueQA
            que='2. Transparent Pricing'
            ans="Our pricing structure is straightforward and transparent. With clear distinctions based on age and mileage, you'll know exactly what you're paying for. No hidden fees, no surprises."
        />
        <BlueQA
            que='3. Seamless Enrollment'
            ans="Enrolling in a Super Warranty plan is a breeze. You can choose the plan that aligns with your vehicle's age and mileage, and our user-friendly enrollment process ensures you're covered without hassle."
        />
        <BlueQA
            que='4. Expert Assistance'
            ans="Our knowledgeable customer support team is ready to assist you every step of the way. Have questions about our plans or need guidance? We're here to provide the information you need to make an informed decision. "
        />
        <BlueQA
            que='5. Flexible Claims Process'
            ans="In the unfortunate event of a breakdown, our claims process is designed to be as smooth as possible. Reach out to us, and we'll guide you through the process, ensuring you're back on the road in no time. "
        />
        <BlueQA
            que='6. Continuous Protection'
            ans="With our coverage options, you don't need to worry about the uncertainties of vehicle ownership. Our plans offer continuous protection, allowing you to enjoy your vehicle without the fear of unexpected repair costs."
        />
        <BlueQA
            que='7. Adaptability'
            ans="We understand that life changes, and so do your needs. If you want to upgrade your coverage or adjust your plan due to changes in your vehicle's status, our flexible options make it easy to tailor your protection."
        />
        <BlueQA
            que='8. Trust and Reliability'
            ans="Our reputation is built on trust and reliability. When you choose Super Warranty, you're choosing a partner dedicated to delivering on its promises and providing the level of protection you deserve."
        />
        <BlueQA
            que='9. Innovative Technology'
            ans="We leverage technology to streamline your experience. From online enrollment to digital claims processing, we utilize the latest tools to ensure your interactions with us are efficient and convenient. "
        />
        
        <p>At Super Warranty, our operations are fueled by our commitment to delivering value and peace of mind to our customers. We take pride in offering not just warranties, but a shield against uncertainties, allowing you to focus on enjoying the journey ahead. Join us in embracing worry-free vehicle ownership – choose Super Warranty today.</p>
        </Container>
    </section>
  )
}

export default OperationSec