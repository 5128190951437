
import ScrollToTop from '../ScrollToTop';
import DashboardImgBg from './DashboardImgBg';
import TransactionTable from './components/TransactionTable';
import bgImg from './images/TransactionBg.png';


function TransactionHistory() {
  

  return (
    <section style={{backgroundColor:'lightgrey',minHeight:'100vh'}}>
      <ScrollToTop/>
      <DashboardImgBg title="Transaction History" bgImg={bgImg} />
      <TransactionTable />
    </section>
  );
}

export default TransactionHistory;
