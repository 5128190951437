import React from 'react'
import { Button } from 'react-bootstrap'

function ContinueBtn({btnLink,onClick}) {
  return (
    <>
    <Button href={btnLink} size='lg' onClick={onClick} type='submit' className='rounded-pill my-2 border-2 text-white' style={{backgroundColor:'#FFA500',width:'100%', borderColor:'#FFA500'}}>Continue</Button>
    </>
  )
}

export default ContinueBtn