import React from 'react'
import FailedPaymentTitle from '../Template/sections/FailedPaymentTitle'
import FailedPaymentSec from '../Template/sections/FailedPaymentSec'
import ScrollToTop from '../Template/components/ScrollToTop'

function FailedPayments() {
  return (
    <>
    <ScrollToTop/>
    <FailedPaymentTitle/>
    <FailedPaymentSec />
    </>
  )
}

export default FailedPayments