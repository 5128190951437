import React from 'react'
import TitleBg from '../components/TitleBg'

import FaqBg from '../images/ContactUsBg.png'

function FaqTitle() {
  return (
    <>
    <TitleBg 
        title="Warranty FAQ's"
        description="If you're considering purchasing a car warranty and need more clarity, you're in the right place. We understand that car warranties can be overwhelming with various terms and options, which is why we designed this FAQ page in plain English, free of jargon, to simplify the process for you."
        bgImg={FaqBg}
    />
    </>
  )
}

export default FaqTitle