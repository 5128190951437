import React from 'react'

import UsedVanTitle from '../Template/sections/UsedVanTitle'
import ExtendedWarranty from '../Template/sections/ExtendedWarranty'
import TypeOfWarranties from '../Template/sections/TypeOfWarranties'
import HowItWorksSec from '../Template/sections/HowItWorksSec'
import Coverlevels from '../Template/sections/Coverlevels'
import ScrollToTop from '../Template/components/ScrollToTop'

function UsedVanWarranty() {
  return (
    <>
    <ScrollToTop/>
    <UsedVanTitle />
    <ExtendedWarranty />
    <TypeOfWarranties />
    <HowItWorksSec />
    <Coverlevels />
  
    </>
  )
}

export default UsedVanWarranty