import React from 'react'
import DashboardBody from './DashboardBody'
import ScrollToTop from '../ScrollToTop'

function UserDashboard() {
  return (
    <>
    <ScrollToTop/>
    <DashboardBody />
    </>
  )
}

export default UserDashboard