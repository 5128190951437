import React, {  useState } from 'react';
import { Button, Dropdown, Form, Table } from 'react-bootstrap';
import './ActiveWarranties.css';
import instance from '../../../../Utils/axios';
import { useDispatch, useSelector } from 'react-redux';
import {format} from 'date-fns'
import WarrantyDetailsModal from './WarrantyDetailsModal';
import { MdAutorenew } from 'react-icons/md'
import { BsFillCaretRightFill } from 'react-icons/bs'
import { toast } from 'react-hot-toast';
import { hideLoading, showLoading } from '../../../../Features/alertSlice';
import { useNavigate } from 'react-router-dom';
import PurchaseDetailsModal from './PurchaseDetailsModal';
import { setRecentViewed } from '../../../../Features/recentViewedSlice';
import { LuFilter } from 'react-icons/lu';

function ActiveWarranties({warrantyData}) {

const userToken = useSelector((state)=>state.auth.userToken)
const dispatch = useDispatch();
const navigate = useNavigate();
const [showModal, setShowModal] = useState(false);
const [selectedWarranty, setSelectedWarranty] = useState(null);
const [showDropdown, setShowDropDown] = useState(false);
const [selectedPlans, setSelectedPlans]= useState([]);


   

  

   const handleShowModal = async( warrantyId)=>{

    try {
      dispatch(showLoading());
      const response = await instance.get(`api/warranty/my-warranty/${warrantyId}`, {
        headers: {
          Authorization: userToken,
        },
      });
      dispatch(hideLoading())
      const selectedWarranty = response?.data?.warranty;
      console.log(selectedWarranty)
      setSelectedWarranty(selectedWarranty);
      setShowModal(true);
      dispatch(setRecentViewed(selectedWarranty))

    } catch (error) {
      dispatch(hideLoading())
      console.log(error);
      toast.error(error?.response?.data?.error?.message || error?.message);

    }
   
  };
  
  const handleCloseModal =()=>{
    setShowModal(false);
    setSelectedWarranty(null);
  }

  

  const uniquePlanNames = [...new Set(warrantyData.map((warranty) => warranty?.planName))];

  // Filtering 
  const filteredWarrantyData = warrantyData.filter(
    (warranty) =>
      selectedPlans.length === 0 || selectedPlans.includes(warranty?.planName?.toLowerCase())
  );

  // Function to toggle the selected plan names
  const togglePlanSelection = (planName) => {
    if (selectedPlans.includes(planName?.toLowerCase())) {
      setSelectedPlans(selectedPlans.filter((name) => name !== planName?.toLowerCase()));
    } else {
      setSelectedPlans([...selectedPlans, planName?.toLowerCase()]);
    }
  };

  const handleCheckboxClick=(e)=>{
    e.stopPropagation();
  }

  return (
    <>
    <div className="d-flex justify-content-between align-items-center mb-3">
    <h4 style={{ color: "#003366" }} className="fw-bold">
              Active Warranties
            </h4>
            <div>
            <Dropdown show={showDropdown} onToggle={(isOpen)=>setShowDropDown(isOpen)}>
        <Dropdown.Toggle variant="light"  id="planNameFilterDropdown" className='rounded-pill' style={{color:'#004C9A'}}>
         <LuFilter color='#FFA500'  /> Filter by Plan
        </Dropdown.Toggle>
        <Dropdown.Menu onClick={(e)=>e.stopPropagation()}>
          {uniquePlanNames.map((planName) => (
            <Dropdown.Item key={planName}>
              <Form.Check
                type="checkbox"
                label={planName}
                checked={selectedPlans.includes(planName?.toLowerCase())}
                onChange={() => togglePlanSelection(planName)}
                onClick={handleCheckboxClick}
              />
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
        </div>
      </div>
             
    <Table hover borderless responsive className='active-warranty-table' style={{ color: '#004C9A' }}>
      <thead>
        <tr>
          <th>PLAN NAME</th>
          <th>REGISTRATION NO. </th>
          <th>EXPIRY DATE</th>
          {/* <th></th> */}
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
  {filteredWarrantyData.length > 0 ? (
    filteredWarrantyData.map((warranty,index) => {

   {/*   const expiryDate = new Date(warranty.expDate);
      const currentDate = new Date();
       const daysLeft = Math.ceil((expiryDate - currentDate) / (24 * 60 * 60 * 1000)); */}

      return (
        <tr key={index} className='py-5'>
          <td className='fw-bold'>{warranty?.planName}</td>
          <td>{warranty?.regNum}</td>
          <td> {format(new Date(warranty?.expDate), 'dd/MM/yy')}</td>
          {/* <td>
            <Button variant='primary' disabled={warranty?.remDays > 30} className='rounded-pill px-3 text-nowrap' onClick={()=>navigate('/purchase/vehicle-details')}>
              Renew <MdAutorenew/>
            </Button>
          </td> */}
          <td>
            <Button className='bg-white  px-3 rounded-pill border text-nowrap' style={{color:'#004C9A '}} onClick={()=>handleShowModal(warranty.id)}>See Details <BsFillCaretRightFill/> </Button>
          </td>
          <td>
            {warranty?.remDays} {warranty?.remDays === 1 ? 'day' : 'days'} left
          </td>
        </tr>
      );
    })
  ) : (
    <tr>
      <td colSpan='6'>No Active Warranty</td>
    </tr>
  )}
</tbody>

    </Table>

    {showModal && selectedWarranty && (
        <PurchaseDetailsModal
          title='WARRANTY DETAIL'
          purchase={selectedWarranty}
          onClose={handleCloseModal}
        />
      )}

</>

  );
}

export default ActiveWarranties;
