import React from 'react'
import TitleBg from '../components/TitleBg'
import bg from '../images/ContactUsBg.png'


function HowWeOperateTitle() {
  return (
    <TitleBg
        title='How We Operate'
        description='We has been a trusted provider of extended car warranties, bringing protection from unexpected repairs and peace of mind to over 1 million satisfied customers.'
        bgImg={bg}
    />
  )
}

export default HowWeOperateTitle