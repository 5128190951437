import React from 'react'
import {  Container, Row } from 'react-bootstrap'
import Title from '../components/Title'
import BlueQA from '../components/BlueQA'
import { useLocation } from 'react-router-dom'

function ExtendedWarranty() {

  const location = useLocation();

  const isCarextended = location.pathname == '/car-extended-warranty';
  const vehicle = isCarextended?'Car':'Van';


  return (
    <section>
      <Container className='py-5 px-md-5'>
        <Row>

          <Title title={`What is an extended or used ${vehicle} warranty`}
            description={`Think of an extended or used ${vehicle} warranty as a safety net for your wheels. It's a service agreement that gives you extra coverage for unexpected repairs, providing peace of mind beyond the original manufacturer's warranty.`}

          />

        </Row>

        <BlueQA
          que={`What is the benefit of an extended ${vehicle} warranty?`}
          ans={`An extended ${vehicle} warranty offers multiple advantages. It provides financial protection by addressing the expenses of unforeseen repairs and mechanical failures that might arise after the manufacturer's warranty has lapsed. This coverage ensures peace of mind, safeguarding you from costly repair bills. Nevertheless, it is crucial to thoroughly examine the terms, limitations, and exclusions of the warranty plan to comprehend the extent of coverage for your specific vehicle.`}

        />

        <BlueQA
          que="What is the duration of the vehicle warranty?"
          ans="We offer cover plans for 3, 6, 12, 24 or 36 months, each thoughtfully crafted to match your needs. Additionally, there are different levels of coverage available. "
        />
        <BlueQA
          que={`What does an extended ${vehicle}  warranty cover?`}
          ans={`Coverage varies based on your ${vehicle}'s age and mileage. For detailed information, please visit the 'what does my plan cover' page.`}
        />
        <BlueQA
          que={`Do I need an extended ${vehicle}  warranty?`}
          ans={`If your ${vehicle} is over 3 years old, chances are your manufacturer's warranty has expired, leaving you susceptible to costly repair expenses. An extended ${vehicle} warranty can shield you from mechanical and electrical faults, providing you with added peace of mind.`}
        />
        <BlueQA
          que="What are plan additions?"
          ans="Plan additions are tailored to elevate our service and offer you greater flexibility when customizing your warranty coverage. During the quote process, you can effortlessly add or remove these options to personalize your cover according to your needs and budget. "
        />
        <BlueQA
          que="Can I choose my own garage?"
          ans="Indeed, you have the freedom to choose any VAT registered garage for your vehicle repairs. Nevertheless, we highly recommend using one of our Approved Repairers. These facilities undergo meticulous monitoring to ensure top-notch workmanship and service quality. "
        />


      </Container>

    </section>
  )
}

export default ExtendedWarranty