import React from 'react'
import RegTemplate from './RegTemplate'
import LoginTitle from '../../Template/components/LoginComponents/LoginTitle'
import { Button, Form, Row } from 'react-bootstrap'
import LoginFormField from '../../Template/components/LoginComponents/LoginFormField'

function LoginWithGoogle() {
  return (
    <RegTemplate>
        <LoginTitle title='Enter your Email Id' />
        <Form as={Row}>
            <LoginFormField
                label='Email'
                placeholder='email@example.com'
            />
         <Button type='submit' className='rounded-pill text-white w-100 py-2'>Continue</Button>   
        </Form>
    </RegTemplate>
  )
}

export default LoginWithGoogle