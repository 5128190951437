import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'

import Van from '../images/Van.png'

function VanCover() {
    return (
        <section>
            <Container className='p-md-5'>
                <Row>
                    <Col md={6}>
                        <h1 className='display-5 fw-bold' style={{ color: '#004C9A' }}>Drive confidently everywhere, everyday</h1>
                    </Col>
                </Row>
                <Row>
                    <Col data-aos='fade-right' data-aos-delay='300' data-aos-duration='1000' md={6} className='d-flex align-items-center'>
                        <div>
                            <ul>
                                <li>Highest level of cover </li>
                                <li>Unlimited repairs</li>
                                <li>Uk based customer service team</li>
                                <li>All as standard</li>
                            </ul>
                            <p>We know your van is more than just a means of transport. In many cases, it’s your livelihood. That’s why we offer the highest level of cover as standard for most van types.    
                            </p>
                            <p>
                            We aim to take away the worry of an unexpected breakdown and the costly repairs that follow. That’s why our extended van warranty plans include 24/7  breakdown cover and car hire as standard. 
                            </p>
                        </div>
                    </Col>
                    <Col data-aos='fade-left' data-aos-delay='300' data-aos-duration='1000'>
                        <Image fluid src={Van} />
                    </Col>
                </Row>
                <Row data-aos='fade-up' data-aos-delay='200' data-aos-duration='800'>
                    <p>Our Final Arbiter, Edd China, also ensures that all disputes are handled fairly and unbiased. This is what makes Warrantywise unique.</p>
                    <p>With over 23 years of industry experience, we’ll do all the hard work so you can stay in the driver’s seat.</p>
                </Row>
            </Container>
        </section>
    )
}

export default VanCover