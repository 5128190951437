import React from 'react'
import TitleBg from '../components/TitleBg'
import bg from '../images/PlanLevelBg.png'
function BlogTitle() {
  return (
    <TitleBg
            title='Blog'
            bgImg={bg}
    />
  )
}

export default BlogTitle