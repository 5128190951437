import React from 'react'
import { Card, CloseButton, Col, Image, Row } from 'react-bootstrap'
import './PaymentConfirm.css'
import { toast } from 'react-hot-toast'



function PaymentConfirm({t,reload=false}) {

  const handleClose = ()=>{

    toast.dismiss(t?.id);

    if(reload){
      window.location.reload();
    }

  }
  return (
    <>
    <div className='parent-payment-confirm'>
        <Row className='d-flex justify-content-center align-items-center'>
            <Col md={6}>
    <Card className='rounded-5 p-4 m-1 ' >
      <div className='text-end'>
        <CloseButton onClick={handleClose}/>
    </div>
        <Card.Body className='text-center'>
        <Image fluid src='/images/verify.png'  style={{maxWidth:'250px'}}/>
            <h1>Order Confirmation</h1>
            <h5 style={{color:'gray'}}>Thank you for your purchase! Your warranty details will be delivered to your email shortly.</h5>
        </Card.Body>
    </Card>
    </Col>
    </Row>
    </div>
    </>
  )
}

export default PaymentConfirm