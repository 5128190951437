import React from 'react'
import { Container, Row } from 'react-bootstrap'
import BlueBox from '../components/BlueBox'

function RenewalInfoSec() {
  return (
    <section>
        <Container >
        <Row className='py-5'>
            <BlueBox 
                    title='Coverage for Mechanical, Electrical Parts & Labor*'
                    text='Rest assured, our coverage is thorough and comprehensive, leaving no aspect (or component) overlooked.'
            />

            <BlueBox 
                    title='Roadside Aid & Recovery'
                    text='In case of a breakdown requiring assistance, we will promptly reach you for support.'
            />

            <BlueBox 
                    title='Coverage for Car Rental, Onward Travel & Expenses'
                    text="Premature wear and tear on parts are beyond your control. Entrust them to us, and we'll provide the necessary coverage."
            />

            <BlueBox 
                    title='Replacement of Parts in Pairs'
                    text='Our role is to extend the lifespan of your pre-owned vehicle on the road. This is why, when components function in pairs, we ensure they are replaced as pairs.'
            />
        </Row>
        </Container>
    </section>
  )
}

export default RenewalInfoSec