import React, { useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import RateCard from '../../Template/components/LoginComponents/RateCard'
import RegProgress from '../../Template/components/LoginComponents/RegProgress'
import toast from 'react-hot-toast';

import { useDispatch, useSelector } from 'react-redux'

import axiosInstance from '../../Utils/axios';
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import { useNavigate } from 'react-router-dom'
import { hideLoading, showLoading } from '../../Features/alertSlice'
import PaymentConfirm from './PaymentConfirm'
import { unSetUserDetails } from '../../Features/userDetailsSlice'
import { unSetVehicleInformation } from '../../Features/vehicleInformationSlice'
import { unSetSelectedPlan } from '../../Features/selectedPlanSlice'
import { unSetWarrantyDetails } from '../../Features/warrantyDetailsSlice'
import { unSetVehicleDetails } from '../../Features/vehicleDetailsSlice'
import FormTitle from '../../Template/components/LoginComponents/FormTitle'
import ScrollToTop from '../../Template/components/ScrollToTop';

// This value is from the props in the UI
const style = { "layout": "vertical", "shape": "pill" };

// Custom component to wrap the PayPalButtons and show loading spinner
const ButtonWrapper = ({ showSpinner, warrantyData, token }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const [{ isPending }] = usePayPalScriptReducer();

    // console.log({ warrantyData })
    const createOrder = async () => {
        console.log({ w: warrantyData })
        try {
            dispatch(showLoading())
            const { data } = await axiosInstance.post("/api/warranty/create-paypal-order",
                {
                    planID: warrantyData?.plan,
                    bhp: warrantyData?.vehicleDetails?.bhp,
                    eng_size: warrantyData?.vehicleDetails?.size,
                    drive_type: warrantyData?.vehicleDetails?.drive_type
                },
                { headers: { Authorization: token } }
            );
            dispatch(hideLoading())
            console.log({ data });
            return data.orderID;
        } catch (err) {
            dispatch(hideLoading())
            toast.error('Something went Wrong, please try again.')
            console.log(err);
        }
    }

    const onApprove = async (order) => {
        try {
            dispatch(showLoading());
            console.log({ order });
            const { data } = await axiosInstance.post(`/api/warranty/capture-paypal-payment`, { order, warrantyData },
                { headers: { Authorization: token } }
            );
            dispatch(hideLoading())

            if (data) {
                console.log({ data });

                toast.custom((t)=>(
                    <PaymentConfirm t={t} />
                )
                );

                navigate("/dashboard/user-dashboard");

                setTimeout(()=>{
                    dispatch(unSetUserDetails());
                dispatch(unSetVehicleInformation());
                dispatch(unSetSelectedPlan());
                dispatch(unSetWarrantyDetails());
                dispatch(unSetVehicleDetails());

                },5000)
                

               
            }
        } catch (error) {
            dispatch(hideLoading())
            toast.error('Something went Wrong, please try again.')
            console.log(error);
        }
    }

    return (
        <>
            {(showSpinner && isPending) && <div className="spinner" />}
            <PayPalButtons
                style={style}
                disabled={false}
                forceReRender={[style]}
                fundingSource={undefined}
                createOrder={createOrder}
                onApprove={onApprove}
                onError={(err) => { console.log(err); window.alert(err.message); }}
            />
        </>
    );
};

function PaymentPage() {
    const { userToken } = useSelector(state => state.auth);

    const { selectedPlan } = useSelector(state => state.selectedPlan);
    const { vehicleDetails } = useSelector(state => state.vehicleDetails);
    const { warrantyDetails } = useSelector(state => state.warrantyDetails);
    const { vehicleInfo } = useSelector(state => { console.log({ state }); return state.vehicleInfo; });
    
    


    console.log({ vehicleDetails,  vehicleInfo, warrantyDetails, selectedPlan });
    const [warrantyData, setWarrantyData] = useState({
        vehicleDetails: {
            reg_num: vehicleDetails?.regNumber,
            make: vehicleDetails?.make,
            fuel_type: vehicleDetails?.fuelType,
            model: vehicleDetails?.model,
            date_first_reg: vehicleDetails?.registeredDate,
            size: vehicleDetails?.engineSize,
            mileage: vehicleDetails?.milage,
            drive_type: vehicleDetails?.driveType,
            bhp: vehicleDetails?.bhp
        },      
        start_date: warrantyDetails?.planStartDate,
        vehicleInfo: {
            purchase_date: vehicleInfo?.purchaseDate,
            service_history: vehicleInfo?.serviceHistory,
        },
        plan: selectedPlan?._id
    });
    console.log({ warrantyData });
    return (
        <>

            <section>
                <ScrollToTop/>
                <Container className='p-3'>
                    <RegProgress step='step5' />
                    <Row>
                        <Col md={7}>
                            <Card className='border px-4 py-2 mt-2 rounded-5'>
                                <FormTitle
                                      title='Payments'
                                        
                                />
                                <PayPalScriptProvider options={{ clientId: "AffLxCmpuxnfYuEBm4Hu3ZDv8ZmeqGtZEu7VdrVwEuviYLpA7G-tWa_uFpwaLbEeHQdP0JMEgcmfRm2k", currency: "GBP", locale: "en_GB" }}>
                                    <ButtonWrapper showSpinner={true} warrantyData={warrantyData} token={userToken} />
                                </PayPalScriptProvider>
                                {/* <ContinueBtn btnLink='/user-dashboard' /> */}
                            </Card>
                        </Col>

                        <Col>
                            <RateCard />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default PaymentPage