import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import InfoBox from "../components/InfoBox";
import { Link } from "react-router-dom";
import { TiSocialFacebookCircular, TiSocialLinkedinCircular } from "react-icons/ti";

function ContactUsInfoSec() {
  const custSupport = (
    <span>
      Our dedicated customer support team is available to answer your questions,
      guide you through our warranty plans, and assist you in making informed
      decisions. <br /><br/>
     <span className="fw-bold">Phone:</span> 0333 344 3774 <br />
     <span className="fw-bold">Email:</span> superwarrantyuk@gmail.com <br />
     {/* <span className="fw-bold">Live Chat:</span> Available on our website during business hours */}
      
    </span>
  );

  const visitOffice = (
    <span>
      If you prefer a face-to-face interaction, you're welcome to visit our
      office at:
      <br />
      <br/>
      SUPERWARRANTY <br />
      94 MANOR ROAD <br />
      N165BN <br />
      Our office hours are: Mon-Fri(8am - 5pm)
    </span>
  );
  return (
    <section >
      <Container className="my-5">

        <Row className="d-flex justify-content-center">
            
          <Col md={5} className="m-2">
            <InfoBox title="Customer Support" description={custSupport} />
          </Col>
          <Col md={5} className="m-2">
          
            <InfoBox title="Visit our Office" description={visitOffice} />
          </Col>
         
        </Row>
        <br/>
        <br/>
        <Row className="mt-5 d-flex justify-content-center fs-5"><Col md={10}><span className="fw-bold">Stay Connected</span> Follow us on social media to stay updated with the news, offers, and updates from Super Warranty</Col></Row>
        <Row className="my-2  ">
                <Col className="text-center">
                  <Link to="https://web.facebook.com/profile.php?id=61552552242250" target="_blank" ><TiSocialFacebookCircular size={40}/></Link>
                  
                  <Link to="https://www.linkedin.com/in/super-car-warranty-659720298/" target="_blank" ><TiSocialLinkedinCircular size={40} /></Link>
                 
                </Col>
              </Row>
      </Container>
    </section>
  );
}

export default ContactUsInfoSec;
