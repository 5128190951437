import React from 'react'
import { Container } from 'react-bootstrap'
import Title from '../components/Title'

function PrivacyPolicySec() {
  return (
    <section>
        <Container className='py-5 px-md-5'>
            <Title
                title='Super Warranty Privacy Policy'
                description='At Super Warranty, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data. By interacting with our services, you agree to the practices described below.'
            />

            <p><span style={{color:'#008BFF',fontWeight:'bold'}}>Information Collection and Use:</span> We may collect personal information such as your name, contact details, vehicle information, and payment information to provide our warranty services and respond to your inquiries. This information is used to administer your warranty coverage, process claims, and communicate with you effectively.</p>
            <p><span style={{color:'#008BFF',fontWeight:'bold'}}>Information Sharing:</span> We may share your information with trusted third parties to facilitate the warranty services you've chosen, such as processing claims or providing customer support. However, we do not sell or rent your personal information to third parties for marketing purposes.</p>    
            <p><span style={{color:'#008BFF',fontWeight:'bold'}}>Data Security:</span> We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. Your data is stored on secure servers, and we take steps to ensure the ongoing confidentiality and integrity of your information.</p>    
            <p><span style={{color:'#008BFF',fontWeight:'bold'}}>Marketing Communications:</span> We may use your contact information to send you updates, promotions, or important information related to your warranty coverage. You can choose to opt out of receiving marketing communications at any time.</p>    
            <p><span style={{color:'#008BFF',fontWeight:'bold'}}>Cookies and Tracking:</span> Our website may use cookies and similar tracking technologies to enhance your browsing experience and gather information about how you interact with our site. You can adjust your browser settings to manage cookies or be notified when they are used.</p>    
            <p><span style={{color:'#008BFF',fontWeight:'bold'}}>Data Retention:</span> We retain your personal information for as long as necessary to fulfill the purposes outlined in this policy, unless a longer retention period is required by law.</p>    
            <p><span style={{color:'#008BFF',fontWeight:'bold'}}>Your Choices:</span> You have the right to access, correct, update, or delete your personal information. You can also manage your communication preferences and opt out of certain data uses. To exercise these rights, please contact us using the information provided below.</p>    
            <p><span style={{color:'#008BFF',fontWeight:'bold'}}>Children's Privacy:</span> Our services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children. If you are a parent or guardian and believe your child has provided us with their information, please contact us to have it removed.</p>    
            <p><span style={{color:'#008BFF',fontWeight:'bold'}}>Changes to the Privacy Policy:</span> We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. The updated policy will be posted on our website, and we encourage you to review it periodically.</p>    
             <p>If you have any questions about our Privacy Policy or how we handle your personal information, please contact us at  0333 344 3774 . Your privacy and security are of utmost importance to us, and we are here to address any concerns you may have.</p>   
        </Container>
    </section>
  )
}

export default PrivacyPolicySec