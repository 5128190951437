import { createSlice } from "@reduxjs/toolkit";

const selectedPlan = localStorage.getItem('selectedPlan')? JSON.parse(localStorage.getItem('selectedPlan')): ''

const selectedPlanSlice = createSlice({
    name:'selectedPlan',
    initialState:{ selectedPlan },
    reducers:{
        setSelectedPlan:(state,action) => {
            console.log({state, action})
            state.selectedPlan = action.payload;
            localStorage.setItem('selectedPlan', JSON.stringify(action.payload))
        },
        unSetSelectedPlan:(state,action)=>{
            state.selectedPlan = null;
            localStorage.removeItem('selectedPlan');
        }
    },
});

export const {setSelectedPlan, unSetSelectedPlan}= selectedPlanSlice.actions;

export default selectedPlanSlice.reducer;
