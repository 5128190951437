import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import FeaturesCard from "../components/FeaturesCard";
import card1 from "../images/card1.png";
import card2 from "../images/card2.png";
import card3 from "../images/card3.png";
import card1back from "../images/card1back.png";
import card2back from "../images/card2back.png";
import card3back from "../images/card3back.png";
import Title from "../components/Title";

function HomeFeatures() {
  return (
    <section>
      <Container>
         <Title
         title='Our Awesome Core Features'
         description='There are countless reasons why our service is better than the rest, but here you can learn about why we’re different.'
         />
        <Row className="py-5">
          
            <FeaturesCard
              title="Extended Coverage "
              desc="Drive worry-free with extended warranty plans tailored to your needs"
              bgImg={card1}
              bgImgBack={card1back}
            />
        
          
            <FeaturesCard
              title="Great service "
              desc="All of our warranty experts are based in the UK. We're here to help you whenever you need us."
              bgImg={card2}
              bgImgBack={card2back}

            />
     
         
            <FeaturesCard
              title="Warranty Experts "
              desc="Enhancing our UK warranty for comprehensive coverage, ensuring best protection for your vehicle with numerous parts."
              bgImg={card3}
              bgImgBack={card3back}

            />
         
        </Row>
      </Container>
    </section>
  );
}

export default HomeFeatures;
