import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import InfoBox from '../components/InfoBox'


function HomeInfoSec() {
  return (
    <section className='pb-5'>
        
    <Container data-aos='fade-down' data-aos-delay='300' data-aos-duration='1000'  className='py-4 my-4 '>
        <Row >
            <Col className='my-2'>
            <InfoBox 
                title='Extended Coverage'
                description='Experience peace of mind on the road with customized extended warranty plans perfectly tailored to suit your needs.'
                btnText='Cover levels'
                btnLink='/car-cover-levels'
            />
            </Col>
            <Col className='my-2'>
            <InfoBox 
                title='Great Service'
                description="Our team of warranty experts operates from the UK, ready to assist you whenever you require our support."
                btnText='Get in touch'
                btnLink='/contact-us'
            />
            </Col>
            <Col className='my-2'>
            <InfoBox 
                title='Warranty experts'
                description="With thousands of parts working together to keep your vehicle running, mishaps can happen. Rest assured, we continuously assess our warranty to provide the best cover available in the UK."
                btnText='Warranties explained'
                btnLink='/car-extended-warranty'
                
            />
            </Col>
        </Row>
    </Container>
    </section>
  )
}

export default HomeInfoSec