import React from 'react'
import TitleBg from '../components/TitleBg'

import bg from '../images/ContactUsBg.png';

function ContactUsTitle() {
  return (
    <>
    <TitleBg 
     title='Contact us '
     description=''
     bgImg={bg}
    />

    </>
  )
}

export default ContactUsTitle