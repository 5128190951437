import React, { useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import Title from '../components/Title'
import { useDispatch } from 'react-redux'
import { toast } from 'react-hot-toast'
import { hideLoading, showLoading } from '../../Features/alertSlice'
import instance from '../../Utils/axios'

function ContactUsForm() {

    const containerStyle={
        backgroundColor:'#D1D5DB4C',
        borderTopLeftRadius:'80px',
        borderTopRightRadius:'80px',
    }

    const [fullname, setFullname] = useState('')
    const [email, setEmail] = useState('')
  
    const [message, setMessage] = useState('')
    const dispatch = useDispatch();

    const handleSendMessage = async(e)=>{
        e.preventDefault();
        try {
           dispatch(showLoading());

            const response = await instance.post('/api/enquiry',{
                    fullname:fullname,
                    email:email,
                  
                    message:message,

            })
            dispatch(hideLoading());
           
            
            if(response.status === 201){
                toast.success('Message sent Successfully');
                setFullname('');
                setEmail('');
                
                setMessage('');
            }else{
                toast.error('Something went wrong. Please try again.');
            }

        } catch (error) {
            dispatch(hideLoading());
            console.log(error);
            toast.error(error?.response?.data?.error?.message)
        }

    }

    return (
        <section style={containerStyle} className='mt-5'>
            <Container>
                <Row className='d-flex justify-content-center'>
                    <Col md={7}>
                        <Title
                            title='Get in touch using the form below'
                            description='If you wish to make an enquiry or send a comment, please fill in the form and click the submit button or call us on the phone numbers next to the form anytime during office hours:'
                        />
                    </Col>
                </Row>
                <Row className='d-flex justify-content-center'>
                    <Col md={6}>
                        <Form onSubmit={handleSendMessage} className='my-3 p-3' style={{fontSize:'0.8rem'}}>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formFullname">
                                        <Form.Label>Full Name</Form.Label>
                                        <Form.Control required type="text" placeholder="Enter full name" value={fullname} onChange={(e)=>setFullname(e.target.value)}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formEmail">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control required type="email" placeholder="Enter email" value={email} onChange={(e)=>setEmail(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col>
                                <Form.Group className="mb-3" controlId="formMessage">
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control required as="textarea" rows={8} placeholder="Enter message" value={message} onChange={(e)=>setMessage(e.target.value)}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                            <Col md={12}>
                            <Button variant='primary' type='submit' className='my-3 rounded-pill' style={{width:'100%'}}>Send Message</Button>
                            </Col>
                            </Row>
                        </Form>

                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ContactUsForm;