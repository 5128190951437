import React from 'react'
import TitleBg from '../components/TitleBg'

import WarrantyTipsBg from '../images/ContactUsBg.png'

function WarrantyTipsTitle() {
  return (
    <>
    <TitleBg 
        title='our super mechanics have extensively access our warranty offers to ensure you are getting the best deal'
        bgImg={WarrantyTipsBg}
    />
    </>
  )
}

export default WarrantyTipsTitle