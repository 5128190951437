import React from 'react'

import FaqTitle from '../Template/sections/FaqTitle'
import WarrantyFaq from '../Template/sections/WarrantyFaq'
import RepairFaq from '../Template/sections/RepairFaq'
import FaqKnowMore from '../Template/sections/FaqKnowMore'
import ScrollToTop from '../Template/components/ScrollToTop'


function FaqPage() {
  return (
    <>
    <ScrollToTop/>
    <FaqTitle />
   
    <WarrantyFaq />
   
    <RepairFaq />
   
    <FaqKnowMore />
    
    </>
  )
}

export default FaqPage