import React from 'react'
import { Accordion, Container } from 'react-bootstrap'


function WarrantyFaq() {

    const data = [{
        que: 'What types of vehicles are eligible for your warranties?',
        ans: 'Our warranties are available for both cars and vans of various makes and models. '

    },
    {
        que: 'What are the different coverage levels you offer?',
        ans: 'We offer three coverage levels: Safe, Secure, and Supreme. '
    },
    {
        que: 'What is covered under the "Safe" warranty?',
        ans: 'The "Safe" warranty covers vehicles of any age and any mileage, providing comprehensive protection against major mechanical and electrical failures.'
    },
    {
        que: 'What is covered under the "Secure" warranty?',
        ans: 'The "Secure" warranty covers vehicles up to 15 years old or with up to 100,000 miles on the odometer. It provides extended coverage for essential components.'
    },
    {
        que: 'What is covered under the "Supreme" warranty?',
        ans: 'The "Supreme" warranty offers extended protection for vehicles up to 10 years old or with up to 70,000 miles, including more comprehensive coverage than the "Secure" level.'
    },
    {
        que: "Can I purchase a warranty after my vehicle's manufacturer warranty has expired?",
        ans: "Yes, you can purchase our warranties even after your manufacturer's warranty has expired. "
    },
    {
        que: 'Are there any hidden fees or deductibles? ',
        ans: 'No, we are transparent about our pricing, and there are no hidden fees or deductibles.'
    },
    {
        que: 'Can I transfer the warranty if I sell my vehicle?',
        ans: 'Yes, our warranties are transferable to new owners, which can enhance the resale value of your vehicle.'
    },
    {
        que: 'Are regular maintenance services covered under your warranties?',
        ans: 'Our warranties are designed to cover sudden mechanical and electrical failures, not regular maintenance services like oil changes or tire rotations. '

    },
    {
        que: 'Can I choose any repair shop for warranty service? ',
        ans: 'We have a network of authorized repair shops where you can get warranty service, ensuring quality repairs and hassle-free claim processing. '

    },
    {
        que: 'Is roadside assistance included in your warranty packages?',
        ans: 'Yes, all our warranty packages include roadside assistance, providing help in case of breakdowns or emergencies.'

    },
    {
        que: 'How do I make a warranty claim?',
        ans: 'If you encounter a covered issue, simply contact our customer service team, and they will guide you through the claim process.'

    },
    {
        que: "Will I be reimbursed for repairs if I'm away from my local area?",
        ans: "Yes, we provide coverage for repairs performed by authorized repair shops, even if you are away from your local area."
    },
    {
        que: 'Can I cancel the warranty if I change my mind?',
        ans: 'Yes, we offer a cooling-off period during which you can cancel the warranty and receive a full refund if you change your mind.'

    },
    {
        que: 'Do your warranties cover wear and tear items?',
        ans: 'Our warranties cover mechanical and electrical failures due to defects, but they do not cover wear and tear items that naturally degrade over time.'

    },
    {
        que: 'What if my vehicle breaks down outside of business hours? ',
        ans: 'Our roadside assistance is available 24/7, so you can receive help whenever you need it.'
    },
    {
        que: 'Are rental car expenses covered while my vehicle is being repaired? ',
        ans: ' Yes, our warranty packages often include rental car benefits, so you can stay mobile while your vehicle is being repaired. '

    },
    {
        que: 'Can I upgrade my warranty to a higher level after purchase? ',
        ans: 'Yes, depending on the eligibility criteria, you may be able to upgrade your warranty to a higher coverage level.'

    },
    {
        que: 'Are diagnostic fees covered under the warranty?',
        ans: 'Yes, diagnostic fees incurred to identify a covered issue are typically covered under our warranties.'
    },
    {
        que: 'How long does the claims process usually take?',
        ans: 'Our goal is to process warranty claims as quickly as possible, usually within a few business days once all required documentation is submitted.'

    },


    ]

    return (
        <section className='my-5'>
            <Container>
                <h1 className='my-4' style={{ color: '#FC6A00' }}>Warranty FAQ’s</h1>

                <Accordion >

                    {data.map((item, index) => (

                        <Accordion.Item className='border-0' key={index} eventKey={`${index}`}>
                            <Accordion.Header ><span className='fs-5 '>{item.que}</span></Accordion.Header>
                            <Accordion.Body>
                                {item.ans}
                            </Accordion.Body>
                        </Accordion.Item>


                    ))}


                </Accordion>
            </Container>
        </section>
    )
}

export default WarrantyFaq