import React from 'react'
import ContactUsTitle from '../Template/sections/ContactUsTitle'
import ScrollToTop from '../Template/components/ScrollToTop'
import ContactUsForm from '../Template/sections/ContactUsForm'
import ContactUsInfoSec from '../Template/sections/ContactUsInfoSec'

function ContactUs() {
  return (
    <>
    <ScrollToTop/>
    <ContactUsTitle />
    <ContactUsInfoSec/>
    <ContactUsForm/>
   
    </>
  )
}

export default ContactUs