import React from 'react'
import bg from '../images/ContactUsBg.png';
import TitleBg from '../components/TitleBg';

function WarrantyRenewalTitle() {
  return (
    <TitleBg
        title='Warranty Renewal'
        bgImg={bg}
        description="Extend your car warranty cover and keep driving with confidence, knowing that we've still got you covered."

    />
  )
}

export default WarrantyRenewalTitle