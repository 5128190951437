import React from 'react'
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap'

import questionMark from '../../images/question.png'

function FormOverlay({ info }) {
    return (
        <>
            <OverlayTrigger
                placement="right"
                delay={{ show: 300, hide: 400 }}
                overlay={<Tooltip id="button-tooltip">
                    {info}
                </Tooltip>}
            >
                <span style={{ color: 'orange', fontWeight: 'bold' }}> *  <Image src={questionMark} style={{ maxHeight: '15px' }} /></span>
            </OverlayTrigger>
        </>
    )
}

export default FormOverlay