import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Footer.css";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import { RiFacebookCircleLine } from "react-icons/ri";
import {
  TiSocialFacebookCircular,
  TiSocialLinkedinCircular,
} from "react-icons/ti";

function Footer() {
  const containerStyle = {
    backgroundColor: "#004C9A",
  };

  return (
    <div style={containerStyle} className="pb-5">
      <Container className="text-white p-5 ">
        <div className="mb-5" style={{ borderTop: "4px solid #FFA500" }} >
          <Row className="my-3">
            <Col className="d-flex  align-items-center">
              
              <Logo />
              
            </Col>
            <Col>
              <h5 className="footer-title mb-4">Opening Hours</h5>
              <h5 className="my-3">Sales:</h5>
              <p className="mt-4">Mon-Fri: 8:00Am - 5:00Pm</p>

              <p className="my-4">Sat-Sun: Closed</p>

              
            </Col>
            <Col md={6}>
              <h5 className="footer-title mb-4">Useful links</h5>
              <Row>
                <Col>
                  <Link
                    to={"/contact-us"}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Contact us
                  </Link>
                  <br />
                  <br />
                  <Link
                    to={"/about-us"}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    About us
                  </Link>
                  <br />
                  <br />
                  <Link
                    to={"/how-we-operate"}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    How we Operate
                  </Link>
                  <br />
                </Col>

                <Col>
                  <Link
                    to={"/privacy-policy"}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Privacy policy
                  </Link>
                  <br />
                  <br />
                  <Link
                    to={"/cookie-policy"}
                    className="my-3"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Cookie policy
                  </Link>
                  <br />
                  <br />
                  <Link
                    to={"/failed-payments"}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Failed payments
                  </Link>
                  <br />
                </Col>

                <Col>
                  <Link
                    to={"/blog"}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Blog
                  </Link>
                  <br />
                  <br />
                  <Link
                    to={"/terms-of-service"}
                    className="my-3"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Terms of Service
                  </Link>
                  <br />
                  <br />
                  <Link
                    to={"/faq"}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Warranty FAQ's
                  </Link>
                  <br />
                </Col>
              </Row>
              <Row className="my-2 ">
                <Col md={1}>
                  <Link to="https://web.facebook.com/profile.php?id=61552552242250" target="_blank" style={{color:'white'}} ><TiSocialFacebookCircular size={40}/></Link>
                  

                </Col>
                <Col md={1}>
                  <Link to="https://www.linkedin.com/in/super-car-warranty-659720298/" target="_blank" style={{color:'white'}}> <TiSocialLinkedinCircular size={40} /></Link>
                 
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ fontSize: "0.7rem" }} className="">
            Super warranty Company is dedicated to providing comprehensive
            vehicle protection tailored to your needs. Our three-tiered warranty
            plans, known as "Safe," "Secure," and "Supreme," offer flexible
            coverage options based on your preferences and vehicle
            specifications.
            
            Safe Plan offers total peace of mind, with coverage available for
            vehicles of any age and mileage. 
            
            Secure Plan is designed for vehicles up to 15 years old or with less
            than 100,000 miles, providing reliable protection for seasoned
            travelers.
            
             Supreme Plan offers elite coverage for newer vehicles up
            to 10 years old and with under 70,000 miles, ensuring the utmost
            confidence on the road.
            
             We offer warranties for both cars and vans,
            ensuring that all your vehicle protection needs are met. Our
            registered office is located in London at N165BN. You can reach us
            at 0333 344 3774 or contact us via email at superwarrantyuk@gmail.com
            for any inquiries.
            
             Please note that Super warranty Company is
            committed to transparency and providing the best protection for your
            vehicle. Our website is for informational purposes only and does not
            form part of any contract of protection. Any Warranty Plan
            confirmation, including Warranty Plan Documents and Application
            Forms, will be issued upon purchase. For complete details about our
            warranty plans, including terms and conditions, please refer to the
            specific plan terms and conditions provided with your purchase. Your
            satisfaction and peace of mind are our top priorities.
            
             This site is
            protected by reCAPTCHA and the Google Privacy Policy and Terms of
            Service apply.
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default Footer;
