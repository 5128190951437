import React from 'react'
import BlogTitle from '../Template/sections/BlogTitle'
import BlogSec from '../Template/sections/BlogSec'
import ScrollToTop from '../Template/components/ScrollToTop'

function Blog() {
    return (
        <>
            <ScrollToTop />
            <BlogTitle />
            <BlogSec />
        </>
    )
}

export default Blog