import React from 'react'
import Header from '../Template/components/Header'
import HomeTitleBgImg from '../Template/sections/HomeTitleBgImg'
import HomeInfoSec from '../Template/sections/HomeInfoSec'
import SupportSec from '../Template/sections/SupportSec'
import HowItWorksSec from '../Template/sections/HowItWorksSec'
import ScrollToTop from '../Template/components/ScrollToTop'


function Home() {
  return (
    < >
   
    <ScrollToTop/>
    <HomeTitleBgImg />
    <br />
    <HomeInfoSec />
   <br />
   <HowItWorksSec />
   <br/>
   <SupportSec />
    <br />    
   </>
  )
}

export default Home