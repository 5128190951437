import React from 'react'
import { Button, Card } from 'react-bootstrap'
import './InfoBox.css';
import { useNavigate } from 'react-router-dom';


function InfoBox({title,description,btnText,btnLink, note}) {

  const navigate = useNavigate();

    let button=null;
    
    let descColor = 'grey';

    if(btnText){
     button =  <Button variant='transparent' onClick={()=>navigate(btnLink)}  className='border-3 rounded-pill my-3 px-5 info-box-btn fs-5' style={{borderColor:'#008BFF', color:'#008BFF'}}>{btnText}</Button>
     descColor = '#004C9A'
    }

  return (
    
    <Card  className='border-3 rounded-4 p-3 mx-2 my-4 info-box' style={{borderColor:'#FFA500', height:'100%'}}>
        <Card.Title className='text-center fs-3' style={{color:'#FFA500'}} >{title}</Card.Title>
        <Card.Body className='pb-0 '>
        <Card.Text style={{color:descColor,fontSize:'1.2rem'}}>{description}</Card.Text>
        <Card.Text style={{fontSize:'0.7rem'}}>{note}</Card.Text>
        </Card.Body>
        
        <div className='text-center'>
            {button}
        </div>
        
    </Card>
    
  )
}

export default InfoBox