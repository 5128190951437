import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import supportImg from '../images/Support.png';


function SupportSec() {
  return (
    <section  >
    <Container data-aos='fade-down'  data-aos-delay='300' data-aos-duration='1000' className='py-4 '>
        <Row className='px-5'>
            <Col md={5} sm={12} className='d-flex align-items-center '>
            <div className='px-md-5'>
            <h1 className='display-5 fw-bold ' style={{color:'#004C9A'}}>Knowledgeable individuals who are genuinely friendly and well-informed.</h1>
            <h4 className='fw-normal mt-5'>Regardless of your situation, our teams are here to support and guide you in finding the ideal warranty solution for your needs.</h4>
            </div>
            </Col>
            <Col >
            
            <Image  fluid className='rounded p-3 mx-2'  src={supportImg} />
            
            </Col>
        </Row>
    </Container>
    </section>
  )
}

export default SupportSec