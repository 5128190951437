import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'

import KnowMore from '../images/KnowMore.png'

function FaqKnowMore() {
    return (
        <section>

            <Container className='py-4 my-3'>
                <Row className='px-5'>
                    <Col>

                        <Image fluid className='rounded p-3' src={KnowMore} />

                    </Col>

                    <Col md={5} className='d-flex align-items-center'>
                        <div className='px-md-5'>
                            <h1 className='display-5 fw-bold my-3' style={{ color: '#143C4B' }}>Want to know more?</h1>
                            <h4 className='fw-normal' style={{color:'#414448'}}>Speak to one of our friendly warranty experts.</h4>
                        </div>
                    </Col>

                </Row>
            </Container>

        </section>
    )
}

export default FaqKnowMore