import React, { useEffect, useRef } from 'react'
import { Button, Container, Nav, Navbar,} from 'react-bootstrap'
import './Header.css'
import NavdropWhatIsWarranty from './NavbarComponents/NavdropWhatIsWarranty'
import NavdropExistingCust from './NavbarComponents/NavdropExistingCust'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Logo from './Logo'
import NavdropOurWarranties from './NavbarComponents/NavdropOurWarranties'
import { useSelector } from 'react-redux'
import DashboardNavbar from './NavbarComponents/DashboardNavbar'

function Header() {

  const location = useLocation();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.userToken);
  console.log(token);

  const navbarRef = useRef(null);



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef?.current && !navbarRef?.current?.contains(event?.target)) {
       
        const isOpened = document?.querySelector('.navbar-collapse')?.classList?.contains('show');
        if (isOpened) {
          
          document.querySelector('button.navbar-toggler').click();
        }
      }
    };

  
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  const dashboard = location.pathname.includes('dashboard');
  const purchase = location.pathname.includes('purchase');
  const auth = location.pathname.includes('auth');
  const login = location.pathname.includes('login');

  const alignClass = (dashboard || purchase) ? 'justify-content-end' : 'justify-content-start'



  

  return (
    <>
    <Navbar expand="lg"  sticky='top' id='main-navbar' className='pb-0 mb-0' style={{ backgroundColor: '#004C9A' }} data-bs-theme="dark" ref={navbarRef}>
      <Container className='px-1'>
        <Navbar.Brand className='text-center '><Logo /></Navbar.Brand> {/* {(dashboard || purchase || auth || login) ?null:<Link to={'/'} style={{textDecoration:'none',color:'white',fontFamily:'Palanquin Dark'}}><h6>Super Car Warranty</h6></Link>} */}
{/*      <Link to={'/'} style={{textDecoration:'none',color:'white',fontFamily:'Palanquin Dark'}}><h4>Super Car Warranty</h4></Link> 
*/}        
        {(dashboard || purchase || auth || login) && token ? (
  <>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse className={alignClass}>
      {dashboard || purchase || auth || login ? null : (
        <Nav variant='tabs' className="me-auto  mx-md-5 ">
          <NavdropOurWarranties />
          <NavdropWhatIsWarranty />
          <NavdropExistingCust />
        </Nav>
      )}
      {token ? <DashboardNavbar /> : null}
    </Navbar.Collapse>
  </>
) : (
  !(dashboard || purchase || auth || login) ? (
    <>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse className={alignClass}>
        {dashboard || purchase || auth || login ? null : (
          <Nav variant='tabs' className="ms-md-5 ps-md-5 ">
            <NavdropOurWarranties />
            <NavdropWhatIsWarranty />
            <NavdropExistingCust />
          </Nav>
        )}
        {token?<DashboardNavbar/>:<Button onClick={()=>{navigate('/login')}} variant='primary' className='rounded-pill px-3 m-2 ms-3 dropdown-login'>Login</Button>}
      </Navbar.Collapse>
    </>
  ) :null
  
  
  

)}

{token?null:<Button onClick={()=>{navigate('/login')}} variant='primary' className='rounded-pill px-3 m-2 hide-login'>Login</Button>}

      </Container>
    </Navbar>

    { !(dashboard || purchase || auth || login)?
    
    <div style={{backgroundColor:'#B5651D',position:"fixed",zIndex:'100',width:'100vw'}} className="alert-header text-white text-center fw-bold py-1">"Prices are at 20% discounted than the market price" <div className='get-quote-link ' style={{display:'inline-block'}}><Link to='/purchase/vehicle-details' className=' p-1' style={{color:'#00FF00'}}> Get a Quote Now</Link></div> </div>
  
  :null}

    </>
  )
}

export default Header