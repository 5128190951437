import React from 'react'
import {  Dropdown, DropdownButton, Image, Navbar } from 'react-bootstrap'

import userImg from '../UserDashboard/images/user.png'
import './DashboardNavbar.css'
import { BsBoxArrowLeft } from 'react-icons/bs'
import { logoutAndClearData } from '../../../Features/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import {  useLocation, useNavigate } from 'react-router-dom'

function DashboardNavbar() {

  const {userData} = useSelector((state)=>state.userData)

  const title = (<Image style={{ width: '20px',height:'20px' }} className='m-1 rounded-pill' src={userData?.profile_img || userImg} />)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    try {
      
      dispatch(logoutAndClearData());
      navigate('/');
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <Navbar.Collapse className="justify-content-end" id='dashboard-dropdown'>
        <div style={{ display: 'block' }}>
          <DropdownButton title={title} drop='down-centered' id="user-dashboard-dropdown" className=' rounded-pill m-1 ms-3' style={{zIndex:'100'}}>
            <Dropdown.Item eventKey='1' className='mt-4' onClick={()=>navigate('/dashboard/user-dashboard')}> Dashboard</Dropdown.Item>
            <Dropdown.Item eventKey='2' onClick={()=>navigate('/dashboard/transaction-history')}>Transaction History</Dropdown.Item>
            <Dropdown.Item eventKey='3' onClick={()=>navigate('/dashboard/purchase-history')}>Purchase History</Dropdown.Item>
            <Dropdown.Item eventKey='4' onClick={()=>navigate('/dashboard/profile')}>My Profile</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey='5' onClick={handleLogout} >Logout<BsBoxArrowLeft className='mx-2' /></Dropdown.Item>
          </DropdownButton>
        </div>
      </Navbar.Collapse>
    </>
  )
}

export default DashboardNavbar