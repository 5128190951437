import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "../../Template/components/ScrollToTop";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { setUserDetails } from "../../Features/userDetailsSlice";
import { setUserToken } from "../../Features/authSlice";
import { setUserData } from "../../Features/userDataSlice";
import toast from "react-hot-toast";
import { hideLoading, showLoading } from "../../Features/alertSlice";
import instance from "../../Utils/axios";
import LoginMethodTemplate from "./LoginMethodTemplate";

function LoginMethods() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGuestLogin = (event) => {
    event.preventDefault();

    navigate("/auth/reg-as-guest");
  };

  const handleGoogleLoginFailure = (error) => {
    console.log("Login Failed:", error);
    toast.error(error?.response?.data?.error?.message || error?.message);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => handleGoogleLogin(codeResponse),
    onError: (error) => handleGoogleLoginFailure(error),
  });

  const handleGoogleLogin = async (user) => {
    try {
      // Fetch Google user information
      const googleUserInfoResponse = await axios.get(
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${user?.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
            Accept: `application/json`,
          },
        }
      );

      if (googleUserInfoResponse.status === 200) {
        const { given_name, family_name, email } = googleUserInfoResponse?.data;

        dispatch(
          setUserDetails({
            firstName: given_name,
            lastName: family_name,
            email: email,
          })
        );

        try {
          dispatch(showLoading());
          const loginResponse = await instance.post("/api/user/login", {
            email: email,
            googleLogin: true,
          });
          dispatch(hideLoading());
          console.log(loginResponse);
          if (loginResponse?.status === 200) {
            const token = loginResponse?.data?.token;
            const user = loginResponse?.data?.user;

            dispatch(setUserToken(token));
            dispatch(setUserData(user));

            navigate("/purchase/details");
          }
        } catch (loginError) {
          dispatch(hideLoading());
          console.error("Error during login:", loginError);
          toast.error(
            loginError?.response?.data?.error?.message || loginError?.message
          );
          if (loginError?.response?.status === 400) {
            navigate("/auth/details-missing");
          }
        }
      }
    } catch (error) {
      console.error("Error during Google login:", error);
      toast.error(error?.response?.data?.error || error?.message);
    }
  };

  return (
    <LoginMethodTemplate>
            <div className="my-5">
              <h2 style={{ color: "#004C9A", fontWeight: "bold" }}>
                SELECT LOGIN METHOD
              </h2>
              <p style={{ color: "#003366", fontSize: "0.9rem" }}>
                Please Select The Desired Login Method
              </p>
            </div>
            <Button
              onClick={googleLogin}
              variant="light "
              className="px-md-5 py-2 border text-black my-2 mt-5 text-nowrap rounded-pill w-100 text-secondary "
            >
              <Image src="/images/google.png" className="mx-2 mb-1" />
              Continue With Google
            </Button>

            <br />
            <Button
              onClick={handleGuestLogin}
              className="px-5 rounded-pill text-nowrap  text-white py-2 my-2 mb-4 w-100"
            >
              Continue As Guest
            </Button>
            <p style={{ color: "#004C9A" }}>
              Already have an account? 
              <a style={{ textDecoration: "none", color: "#008BFF",fontWeight:'bold' }}>
                <Link to={"/auth/login"}> Log In</Link>
              </a>
            </p>
          </LoginMethodTemplate>
  );
}

export default LoginMethods;
