import React, { useState } from 'react'
import { Button, Card, Image } from 'react-bootstrap'

import Pass from '../images/Pass.png'
import Fail from '../images/Fail.png'
import Review from '../images/Review.png'
import './DashboardCard.css'
import PaymentPage2 from '../../../../Pages/loginAndRegistration/PaymentPage2'

export function InspectionCard({img,title,description,btnText, id,reRenderFunction,regNum}) {

  const [showModal,setShowModal] = useState(false);

  const openModal = ()=>{
    setShowModal(true);
  }

  const closeModal =()=>{
    setShowModal(false);
  }

  let button

  if(btnText)
  {
 button = <Button className='rounded-pill' onClick={openModal}>{btnText}</Button>
  }

  return (
    <>
    <Card className='rounded-4 border-0 shadow  p-3 px-md-5 my-2 dashboard-card' style={{height:'100%'}}>
        <Card.Body className='text-center'>
                <Image className='my-2' src={img}></Image>
                <h3 className='fw-bold' style={{color:'#003366'}}>{title}</h3>
                <p style={{color:'#004C9A',fontSize:'0.9rem'}} className='my-3'>{description}</p>
                <h5>{regNum}</h5>
               {/*{button}*/} 
        </Card.Body>
    </Card>

    <PaymentPage2 show={showModal} onClose={closeModal} id={id} reRenderFunction={reRenderFunction} />
</>
  )
}


export function PassCard({id,reRenderFunction,regNum}) {
  return (
    <>
    <InspectionCard
     img={Pass}
     title='INSPECTION PASS'
     description='Inspection Passed. You Will Receive The Warranty Document Shortly.' 
    /*btnText='Pay Now'*/
     id={id}
     reRenderFunction= {reRenderFunction}
     regNum={regNum}
     />  
    </>
  )
}



export function FailCard({regNum}) {
  return (
    <>
    <InspectionCard
        img={Fail}
        title='INSPECTION FAILED'
        description='Inspection Failed From Admin. You Will Receive A Refund of Complete Amount Within 14 Business Days.'
        regNum={regNum}
    />
    </>
  )
}

export function ReviewCard({regNum}) {
  return (
    <>
    <InspectionCard
        img={Review}
        title='UNDER REVIEW'
        description='We Received Your Payment Successfully. Please Wait For The Admin To Review Your Details And Schedule An Inspection.'
        regNum={regNum}
    />
    </>
  )
}



