import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import DashboardImgBg from './DashboardImgBg'
import ProfileSection from './components/ProfileSection'
import ScrollToTop from '../ScrollToTop'

function Profile() {
  return (
    <>
     <ScrollToTop/>
      <DashboardImgBg bgColor='#003366' title='PROFILE OVERVIEW' />
      <ProfileSection />
    </>
  )
}

export default Profile