import React from 'react';
import { Modal, Button, Card, Row, Col } from 'react-bootstrap';
import {format} from 'date-fns'

function TransactionDetailsModal({ transaction, onClose,onClick }) {
  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton className='border-0'>
        <Modal.Title >TRANSACTION DETAIL</Modal.Title>
      </Modal.Header>
      <Modal.Body className=''>

         <Card className='p-3'>
                <Row>
                    <Col md={6}><h6>DATE</h6><p>{format(new Date(transaction?.createdAt), 'dd/MM/yy')}</p></Col>    
                    <Col md={6}><h6>TRANSACTION ID</h6><p>{transaction?.source_id}</p></Col>    
                </Row>   
                <hr/>
                <Row>
                    <Col md={6}><h6>STATUS</h6><p>{transaction?.status}</p></Col>    
                    <Col md={6}><h6>PLAN</h6><p>{transaction?.plan}</p></Col>    
                </Row>
                <hr/>   
                 
                  
                
         </Card>   

         <Card className='p-3 my-3'>
              <Row>
                    <Col md={6}><h6>PAYMENT METHOD</h6><p>{transaction?.method}</p></Col>    
                    <Col md={6}><p>TOTAL AMOUNT <span className='fs-4' style={{color:'#FF6600'}}>£{transaction?.amount}</span></p></Col>    
                </Row> 
         </Card>

<Row className='my-3'>

    <Col md={6}>
    <Button variant="transparent" className='text-muted border rounded-pill px-5 py-2 w-100' onClick={onClose}>
          Back To List
        </Button>
    </Col>
    <Col md={6} >
    <Button onClick={()=>onClick(transaction?._id)} variant="primary" className='text-white border text-nowrap rounded-pill px-5 py-2 w-100' >
          Download Receipt
        </Button>
    </Col>


</Row>

         
             
      </Modal.Body>
      

        
      
    </Modal>
  );
}

export default TransactionDetailsModal;
