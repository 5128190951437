import React from 'react'
import { Container } from 'react-bootstrap'
import BlueQA from '../components/BlueQA'

function CookiePolicySec() {
    return (
        <section>
            <Container className='px-md-5 py-5'>

                <p>Welcome to Super Warranty's Cookie Policy. This policy explains how we use cookies, tracking technologies, and similar tools on our website to enhance your browsing experience and provide you with relevant information. By using our website, you consent to the use of cookies as described in this policy.</p>

                <BlueQA
                    title='What are Cookies'
                    ans='Cookies are small text files that are stored on your device (computer, tablet, or mobile) when you visit a website. They are commonly used to improve user experience, remember preferences, and provide anonymized tracking data for website owners.'
                />
                <BlueQA
                    title='How We use Cookies'
                    ans='Super Warranty uses cookies for various purposes, including:'

                />
                <p><span style={{ color: '#008BFF' }}>Functionality:</span> Some cookies are essential for the proper functioning of our website. They enable features such as navigating between pages and accessing secure areas.</p>
                <p><span style={{ color: '#008BFF' }}>Performance:</span> We use cookies to gather information about how visitors use our website. This data helps us improve our website's performance, navigation, and content.</p>
                <p><span style={{ color: '#008BFF' }}>Analytics:</span> We utilize third-party analytics services (e.g., Google Analytics) to collect anonymous data about user behavior, such as the pages you visit and the duration of your visit. This data helps us understand user preferences and make informed improvements.</p>
                <p><span style={{ color: '#008BFF' }}>Marketing and Advertising:</span> Cookies enable us to display relevant advertisements to you based on your browsing behavior and interests. These cookies are typically set by third-party advertising networks.</p>

                <h6>Types of Cookies We Use</h6>

                <p><span style={{ color: '#008BFF' }}>Session Cookies:</span> These temporary cookies are erased from your device when you close your browser. They help maintain continuity and improve navigation during your visit.</p>
                <p><span style={{ color: '#008BFF' }}>Persistent Cookies:</span> Persistent cookies remain on your device for a set period, allowing us to recognize you on subsequent visits and remember your preferences.</p>
                <p><span style={{ color: '#008BFF' }}>Third-Party Cookies:</span> Some cookies are placed by third-party services that we use, such as social media platforms and analytics providers. These cookies may collect information for tracking and analytics purposes.</p>

                <h6>Your Cookie Choices</h6>
                <p>You have the option to manage and control cookies through your browser settings. Most browsers allow you to refuse or accept cookies, delete existing cookies, and set preferences for future cookie usage. However, please note that disabling cookies may impact your browsing experience on our website.</p>

                <h6>Updates to Our Cookie Policy</h6>
                <p>We may update our Cookie Policy from time to time to reflect changes in our practices or legal requirements. The latest version will always be available on our website.</p>
            </Container>
        </section>
    )
}

export default CookiePolicySec