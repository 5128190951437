import React from 'react'

import UsedCarTitle from '../Template/sections/UsedCarTitle'
import CarExtendedWarranty from '../Template/sections/CarExtendedWarranty'
import TypeOfWarranties from '../Template/sections/TypeOfWarranties'
import HowItWorksSec from '../Template/sections/HowItWorksSec'
import Coverlevels from '../Template/sections/Coverlevels'
import ExtendedWarranty from '../Template/sections/ExtendedWarranty'
import ScrollToTop from '../Template/components/ScrollToTop'

function UsedCarWarranty() {
  return (
    <>
   <ScrollToTop/>
    <UsedCarTitle />
    <ExtendedWarranty />
    <TypeOfWarranties />
    <HowItWorksSec />
    <Coverlevels />
   
    
    </>
  )
}

export default UsedCarWarranty