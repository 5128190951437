import React from 'react'
import KnowMore from '../images/KnowMore.png'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'

function RenewalSpecialist() {
  return (
    <section>

    <Container className='py-4 my-3'>
        <Row className=''>
            <Col>

                <Image fluid className='rounded p-3' src={KnowMore} />

            </Col>

            <Col md={5} className='d-flex align-items-center py-2'>
                <div className='px-md-5'>
                    <h1 className='display-5 fw-bold my-3' style={{ color: '#004C9A' }}>Enhance your coverage today, consult a warranty specialist.</h1>
                    <h4 className='fw-normal'>Your cover can be extended in less than 8 minutes</h4>
                    <Button className='rounded-pill text-white my-3'>Call: 0333 344 3774</Button>
                </div>
            </Col>

        </Row>
    </Container>

</section>
  )
}

export default RenewalSpecialist