import React from 'react'
import WarrantyRenewalTitle from '../Template/sections/WarrantyRenewalTitle'
import RenewalSpecialist from '../Template/sections/RenewalSpecialist'
import RenewalInfoSec from '../Template/sections/RenewalInfoSec'
import ScrollToTop from '../Template/components/ScrollToTop'

function WarrantyRenewal() {
  return (
    <>
    <ScrollToTop/>
    <WarrantyRenewalTitle/>
    <RenewalSpecialist/>
    <RenewalInfoSec/>
    </>
  )
}

export default WarrantyRenewal