import React, { useEffect } from 'react'
import AOS from 'aos'

function ScrollToTop() {

    useEffect(()=>{
        window.scrollTo(0,0);
        AOS.init();

    },[]);

  return null;
  
}

export default ScrollToTop