import React from 'react'
import { Card, Col } from 'react-bootstrap'

function BlueBox({title,text}) {
  return (
    <Col md={3}>
        <Card style={{backgroundColor:'#004C9A',height:'100%'}} className='text-white p-4'>
                <Card.Title className='my-2'>{title}</Card.Title>
               
                <Card.Text style={{fontSize:'0.9rem'}}>{text}</Card.Text>
                
        </Card>
    </Col>
  )
}

export default BlueBox