import React from 'react'

import PlanLevelsSec from '../Template/sections/PlanLevelsSec'
import CarPlanLevelTitle from '../Template/sections/CarPlanLevelTitle'
import ScrollToTop from '../Template/components/ScrollToTop'

function CarPlanLevels() {
  return (
    <>
     <ScrollToTop/>
     <CarPlanLevelTitle />   
     <PlanLevelsSec />   
       
    </>
  )
}

export default CarPlanLevels