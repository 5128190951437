import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

function LoginFormField({id,label,type,placeholder,value,onChange,disabled=false}) {
    return (
        
        <Form.Group as={Row} className="mb-3" controlId={id}>
            <Form.Label className='fw-bold'>{label} </Form.Label>
            <Form.Control disabled={disabled} type={type} placeholder={placeholder} value={value} onChange={onChange} className='rounded-pill' required />      
        </Form.Group>
       
    )
}

export default LoginFormField