import React from 'react'
import PrivacyPolicyTitle from '../Template/sections/PrivacyPolicyTitle'
import PrivacyPolicySec from '../Template/sections/PrivacyPolicySec'
import ScrollToTop from '../Template/components/ScrollToTop'

function PrivacyPolicy() {
  return (
    <>
     <ScrollToTop/>
        <PrivacyPolicyTitle/>
        <PrivacyPolicySec />
    </>
  )
}

export default PrivacyPolicy