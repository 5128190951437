import { createSlice } from "@reduxjs/toolkit";

const recentViewed = [];

const recentViewedSlice = createSlice({
  name: 'recentViewed',
  initialState: recentViewed, // Initialize directly as an array
  reducers: {
    setRecentViewed: (state, action) => {
      const itemToAdd = action.payload;
      const isItemInList = state.some((item) => item?._id === itemToAdd?._id); // Assuming 'id' is the unique identifier property
      if (!isItemInList) {
        state.unshift(itemToAdd); // Use unshift to add at the beginning
        if (state.length > 3) {
          state.pop();
        }

        localStorage.setItem('recentViewed', JSON.stringify(state));
      }
    },
    unSetRecentViewed: (state, action) => {
      state.length = 0; // Clear the array
      localStorage.removeItem('recentViewed');
    },
  },
});


export const { setRecentViewed , unSetRecentViewed} = recentViewedSlice.actions;
export default recentViewedSlice.reducer;
