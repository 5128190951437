import React from 'react'
import { Card, Col, Image, Row } from 'react-bootstrap'

import Pass from '../images/Pass.png'
import New from '../images/New.png'
import Cancel from '../images/Cancel.png'
import Right from '../images/Right.png'
import './DashboardCard.css'

export function OverviewCard({img,count,title}) {
  return (
    <Col>
    <Card className='rounded-4 border-0 shadow m-0 px-0 dashboard-card' style={{height:'100%'}}>
        <Card.Body className='p-2 m-0'>
            <Row>
                <Col md={3} className='d-flex justify-content center align-items-center px-3'>
                <Image src={img} style={{maxWidth:'35px'}} />
                </Col>
                <Col md={9}>
                <h3 className='fw-bold'>{count}</h3>
                <p className='fw-bold' style={{color:'gray',fontSize:'0.9rem'}}>{title}</p>
                </Col>
            </Row>
        </Card.Body>
    </Card>
    </Col>
    
  )
}

export function ActiveOverview({count=0}) {
    return (
      <>
        <OverviewCard 
            img={Pass}
            count={count}
            title='Active Warranties'
        />
      </>
    )
  }
export function ExpiredOverview({count=0}) {
    return (
      <>
        <OverviewCard 
            img={Cancel}
            count={count}
            title='Expired'
        />
      </>
    )
  }
export function UpComingOverview({count=0}) {
    return (
      <>
        <OverviewCard 
            img={Right}
            count={count}
            title='Up Coming'
        />
      </>
    )
  }

