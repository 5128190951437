import React from 'react';
import { Col, Container, Image, ProgressBar, Row } from 'react-bootstrap';
import { BsCheckCircleFill } from 'react-icons/bs';
import './RegProgressBar.css'
 
import tick from '../images/check.png'
function RegProgress ({step,text1,text2,text3}){

let now1=0;
let now2=0;
let opacity1='50%';
let opacity2='50%';
let opacity3='50%';

let text3Class = 'text3';

text1='Vehicle'
    text2='Details'
    text3='Cover'

switch (step) {
  case 'step1':
    now1 = 25;
    opacity1 = '100%';
    
    break;
  case 'step2':
    now1 = 100;
    opacity1 = '100%';
    opacity2 = '100%';

    break;
  case 'step3':
    now1 = 100;
    opacity1 = '100%';
    now2 = 100;
    opacity2 = '100%';
    opacity3 = '100%';
    break;
  case 'step4':
    now1 = 100;
    opacity1 = '100%';
    
    opacity2 = '100%';
    text1='Cover'
    text2='Quote'
    text3='Payment'
    text3Class='text4'
    break;
  case 'step5':
    now1 = 100;
    opacity1 = '100%';
    now2 = 100;
    opacity2 = '100%';
    opacity3 = '100%';
    text1='Cover'
    text2='Quote'
    text3='Payment'
    text3Class='text4'
    break;
  default:
    // Handle any default case if needed
    break;
}


  return (
    <>
    <Container style={{ backgroundColor: '#008BFF' }} className='pb-4 pt-2 px-md-5  mb-2 mx-md-1  rounded-4'>
      <Row className='d-flex justify-content-center align-items-center '>
      <Col md={8} sm={12} xs={12}>
        <div className="d-flex align-items-center rounded-pill p-2" style={{backgroundColor:'white',transform:'scale(0.85)'}}>
          <Image src={tick} style={{ height: '60px', marginRight: '0px',filter:`opacity(${opacity1})` }} /> <p className='progress-text text1'>{text1}</p>
          <ProgressBar variant="success" className='form-progress-bar' now={now1} style={{ flex: 1, height: '10px' }} />
          <Image src={tick} style={{ height: '60px', marginLeft: '0px', filter:`opacity(${opacity2})` }} /><p className='progress-text text2'>{text2}</p>
          <ProgressBar variant="success" className='form-progress-bar' now={now2} style={{ flex: 1, height: '10px' }} />
          <Image src={tick} style={{ height: '60px', marginLeft: '0px',filter:`opacity(${opacity3})` }} /><p className={`progress-text ${text3Class}`}>{text3}</p>
        </div>
      </Col>
      </Row>
    </Container>
    
    </>
  );
};

export default RegProgress;
