import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import superhero from '../images/superhero.svg'
function HomeWhySection() {

    const navigate  = useNavigate();

  return (
    <section>
      <Container>
        <Row className="py-5"> 
          <Col md={6} className="p-3 px-md-5">
            <h1 style={{ color: "#004C9A" }} className="my-3 mb-4">Why Do You Choose Us?</h1>
            <p style={{textAlign:'justify'}}>
              "Unlock unparalleled peace of mind with our Warranty Center, where
              we've already served over 100,000 satisfied customers. Discover
              the power of protection as we offer warranties for more than
              500,000 products, ensuring that your investments are safeguarded
              against unexpected mishaps. Join the 98% of our clients who have
              reported worry-free ownership and have saved up to 30% on repair
              costs. With a user-friendly interface, we provide a seamless
              experience for you to explore a variety of coverage options
              tailored to your specific needs. Say goodbye to the stress of
              unexpected breakdowns and repair bills and embrace a future of
              worry-free ownership. Don't miss out – secure your purchases
              today!"
            </p>

            <Button onClick={()=>navigate('/purchase/vehicle-details')} className=" shadow rounded-pill border-0 px-4 my-4 py-2 text-white" style={{backgroundColor:'#FFA500'}}>Get a quote</Button>
          </Col>
          <Col>
            <Image className="float-element" src={superhero} fluid style={{maxHeight:'500px',filter: 'drop-shadow(0px 10px 5px gray)'}}/> 
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default HomeWhySection;
