import React, { useEffect, useState } from 'react'
import { Accordion, Col, Container, Form, Row } from 'react-bootstrap'
import PlanAccordionItem from '../../Template/components/LoginComponents/PlanAccordionItem'
import ContinueBtn from '../../Template/components/LoginComponents/ContinueBtn';
import RegProgress from '../../Template/components/LoginComponents/RegProgress';
import instance from '../../Utils/axios';

import './WarrantySuggestion.css'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedPlan } from '../../Features/selectedPlanSlice';
import { hideLoading, showLoading } from '../../Features/alertSlice';
import FormTitle from '../../Template/components/LoginComponents/FormTitle';
import { toast } from 'react-hot-toast';
import WarrantyTable from './WarrantyTable';

function WarrantySuggestion() {
    const [error, setError] = useState('');
    const [planData, setPlanData] = useState([]);
   
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { vehicleDetails } = useSelector((state) => state.vehicleDetails);

    const {selectedPlan} = useSelector((state) => state.selectedPlan)
    const [plan , setPlan] = useState(selectedPlan);

    const userToken = useSelector((state)=>state.auth.userToken)
    console.log(plan);

    const queryParams = {
        engineSize: vehicleDetails.engineSize,
        bhp: vehicleDetails.bhp,
        driveType: vehicleDetails.driveType,
        mileage: vehicleDetails.milage,
        dateFirstReg: vehicleDetails.registeredDate
    }

    console.log('hello')

    useEffect(() => {
        window.scrollTo(0,0);
        
        const fetchVehicleData = async () => {
            try {
                dispatch(showLoading());
                const response = await instance.get('/api/level/plan/', { 
                    params: queryParams ,

                    headers:{
                        Authorization: userToken,
                    },
                
                
                });
                const data = response.data;
                dispatch(hideLoading());
                console.log(data);

                //  setPlanData(data.levelsAndPlans);

                const planDataArray = data.levelsAndPlans.map(level => ({
                    levelId: level._id,
                    title: level.level,
                    claimPlans: level.plansByClaim
                }));

                console.log('bye')
                setPlanData(planDataArray);

            } catch (error) {
                dispatch(hideLoading());
                setError(error?.response?.data?.error?.message || error?.message);
                console.error('Error fetching vehicle data: ', error);
            }
        };

        fetchVehicleData();
    }, []);

    // ...


    const handleContinue = (event) => {
        event.preventDefault();


        console.log({plan})

        if(!plan){
            toast.error('Please select a plan');
            return;
        }
       
        try {

            
            console.log(selectedPlan);
           
            dispatch(setSelectedPlan(plan));

            navigate('/dashboard/payments')
        } catch (error) {

            console.log(error);
        }


    }






    return (
        <>
           
            <section>
                <Container className='p-3'>
                    <RegProgress step='step4' />
                    
                    <FormTitle 
                            title='Warranty Suggestion'
                            alertMsg={error}
                    />
                    <Form onSubmit={handleContinue}>
                        <Row>
                            <Col>
                            <Form.Group>
                                <Accordion defaultActiveKey="0 " className='warranty-packages'>

                                    {planData.map((levelData, index) => (
                                        <PlanAccordionItem key={index} customClass={`accordion-${index}`} eventKey={index.toString()} title={levelData.title}  planData={levelData.claimPlans} setPlan={setPlan} plan={plan} index={index}
                                        />
                                    ))}



                                    {/*<PlanAccordionItem eventKey={1} title={planData.levelsAndPlans.level} planData={planData.levelsAndPlans.level.plansByClaim} /> */}

                                </Accordion>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='d-flex justify-content-end'>
                            <Col md={4}>
                                <ContinueBtn />
                            </Col>
                        </Row>
                    </Form>
                    <WarrantyTable />
                </Container>
            </section>
        </>
    )
}

export default WarrantySuggestion