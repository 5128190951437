import React, { useState } from 'react'
import { Card, Col, Container, Form, Row } from 'react-bootstrap'
import FormTitle from '../../Template/components/LoginComponents/FormTitle'
import FormField from '../../Template/components/LoginComponents/FormField'
import ContinueBtn from '../../Template/components/LoginComponents/ContinueBtn'
import RegHeader from '../../Template/components/LoginComponents/RegHeader'
import RegProgress from '../../Template/components/LoginComponents/RegProgress'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setVehicleDetails } from '../../Features/vehicleDetailsSlice'
import ScrollToTop from '../../Template/components/ScrollToTop'

function FindRegistration() {
        const dispatch = useDispatch();
        const navigate = useNavigate();


        const {vehicleDetails} = useSelector((state)=> state.vehicleDetails);
        const [vehicleData,setVehicleData]= useState(vehicleDetails)


    const handleInput = (e) =>{

        setVehicleData({...vehicleData,[e.target.name]: e.target.value})
    }

    const handleContinue = (event)=>{
        event.preventDefault();

        dispatch(setVehicleDetails(vehicleData))
        navigate('/auth/login-method');
    
    }

    return (
        <>
           
            <section>
                <ScrollToTop/>
                <Container className='p-3'>
                    <RegProgress step='step1' />
                    <Row>
                        <Col md={7}>
                            <Card className='border px-4 py-2 rounded-5' data-aos="flip-right" data-aos-delay='300' data-aos-duration='1000'>





                                <Form className='px-md-5 fw-bold' style={{ color: '#004C9A' }} onSubmit={handleContinue}>

                                    <FormTitle
                                        title='Your Vehicle'
                                        description="We provides the UK's most comprehensive warranty for all vehicle makes and models. By filling out your vehicle details we can ensure you receive the best warranty to suit you."

                                    />
                                    <FormField
                                        label='Make'
                                        type='select'
                                        info='Make'
                                        value={vehicleData?.make}
                                        name='make'
                                        onChange={handleInput}

                                    />

                                    <FormField
                                        label='Model'
                                        info='Model'
                                        type='select'
                                        value={vehicleData?.model}
                                        name='model'
                                        onChange={handleInput}
                                        ctaBtnTxt="I Know My Registration"
                                        ctaBtnLink='/purchase/vehicle-details'
                                    />

                                    <FormField
                                        label='Mileage'
                                        info='Mileage'
                                        note='Approximate'
                                        type='number'
                                        value={vehicleData?.milage}
                                        name='milage'
                                        onChange={handleInput}
                                    />
                                    <ContinueBtn  />
                                </Form>



                            </Card>

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default FindRegistration