import React from 'react'
import ScrollToTop from '../Template/components/ScrollToTop'
import TermsOfServiceTitle from '../Template/sections/TermsOfServiceTitle'
import TermsOfServSec from '../Template/sections/TermsOfServSec'

function TermsOfService() {
  return (
    <>
    <ScrollToTop/>
    <TermsOfServiceTitle/>
    <TermsOfServSec/>
    </>
  )
}

export default TermsOfService