import React from 'react'

import { Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'


function Logo() {
  return (
    
<Link to={'/'} className='text-nowrap' style={{textDecoration:'none',color:'white',fontFamily:'Palanquin Dark'}}><Image style={{ Width: 'auto', height: '50px' }} className='rounded-4 m-1' src='/images/logo/Logo.png' /> <span className='fs-4' style={{textDecoration:'none'}}>Super Warranty</span></Link>
 
  )
}

export default Logo